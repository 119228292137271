import { call, select,all,put, takeEvery } from 'redux-saga/effects';
import matchActions from '../actions/matchActions';
import Api from './api_config';
import loaderActions from '../actions/loader';
import modalActions from '../actions/modal';
 
let User=localStorage.getItem('login_data');
User=JSON.parse(User);
let IMAGE_URL='https://mentorzstorageaccount1.blob.core.windows.net/mentorz/';
function* fetchRecommendedMentors() {
   
   try {
      const Mentors = yield call(Api.match.getRecommendedMentors,User.id);
      const {data}=Mentors;
      // for (const [index, value] of data.recommendedMentors.entries()|| []) {
      //   console.log(index, value);
      //   // var id=index==0?1: index;
      //   // const photoUrl =  yield call (imageURL_dummy ,id);
      //   // debugger
      //   //  value.photoUrl=photoUrl.image;
      // }
      for(let option of data.recommendedMentors || []){
         //const photoUrl =  yield call (imageURL ,option.photoUrl);
         option.photoUrl=yield call (imageURL ,option.photoUrl);
      }
      
      for(let option of data.trendingMentors || []){
        //const photoUrl =  yield call (imageURL ,option.photoUrl);
        option.photoUrl=yield call (imageURL ,option.photoUrl);
        //option.photoUrl=photoUrl;
      }
      
      yield put(matchActions.setRecommendedMentors(data));
      yield put(loaderActions.toggleLoader(false));
   } catch (e) {
    yield put(loaderActions.toggleLoader(false));
   }  
 finally {
   yield put(loaderActions.toggleLoader(false)); 
 }
}
function* fetchAlldMentors(req) {
  try {
     const Mentors = yield call(Api.match.getAllMentors,User.id ,req.payload);
     Mentors.data.pageNumber=req.payload.pageNumber;
     const {data}=Mentors;
     for(let option of data || []){
       option.photoUrl=yield call (imageURL ,option.photoUrl);//   IMAGE_URL+ option.photoUrl;
     }
     yield put(matchActions.setAllMentors(data));
     yield put(loaderActions.toggleLoader(false));
  } catch (e) {
   yield put(loaderActions.toggleLoader(false));
  }  
finally {
  yield put(loaderActions.toggleLoader(false)); 
}
}

 function * fetchManageRequest () {
   try 
   {
      const manageRequest = yield call(Api.match.getManagereq,User.id);
      const {data} =manageRequest;
      for(let option of data || []){
         const photoId =  yield call (imageURL ,option.photoId);
         option.photoId=photoId;
      }

      yield put(matchActions.setManageRequest(data))
  }
  catch (e) {
   //yield put(loaderActions.toggleLoader(false));
  }  
 }


 function* fetchMentorDetail(param){
   try{
      const _metordetail = yield call(Api.match.getMentorDetails,param.payload);
      const {data}=_metordetail;
      
      const photoId =  yield call (imageURL ,data.photoId);
      data.photoId=photoId;
      yield put(matchActions.setMentor_detai(data));
      yield put(loaderActions.toggleLoader(false));
          
   }
   catch(e){
       return e;
   }
   finally{

   }
}

function* CreateMentorRequest(req){
  
  try{
    const request = yield call(Api.match.creteMentorRequest,req ,User.id);
    const {data}=request;
    debugger
   
    yield put(matchActions.setCreateMentor_request(data));
    yield put(modalActions.toggleModal('mentor-send-success-modal'))
    debugger
  }
  catch(e){
      return e;
  }
  finally{

  }
}

export const managRequests = (state) => state.matchReducer
function* UpdateMentorRequest(req){
  debugger
  const status =  yield call(Request_status ,req.payload.status);
  try{
    const request = yield call(Api.match.UpdateMentorRequest,req ,status);
    const {data}=request;
    const {send_requests,recieved_requests} = yield select(managRequests);
    if(data)
    {  
      const Update_sendrequests =send_requests.filter(i => i.id !== req.payload.req.id);
      const updatedList = [...Update_sendrequests||[], ...recieved_requests||[]];
      debugger
      if(status===2){yield put(modalActions.toggleModal('mentor-received-success-modal'))}
      yield put(matchActions.setManageRequest(updatedList));
    }
  }
  catch(e){
      return e;
  }
  finally{

  }
}

 function* imageURL(imageId){
  let image;
   try{
       if(imageId!=="" && imageId!==undefined && imageId!=="string" )
       {
         image=IMAGE_URL+ imageId;
          //  const image = yield call(Api.getFile,imageId);
          //  const {data}=image;
          
       }
       else{
        image='assets/images/User.png';
       }
       return image
   }
   catch(e){
       return e;
   }
   finally{

   }
}

function* Request_status(value){
  
  var responce;
  switch (value) {
    case 'Accept':
      responce=2;
      break;
    case 'Reject':
      responce=3;
      break;
    case 'Cancel':
      responce=3;
      break;
    default:
      break;
  }
 return responce;
}



function* getMatch() {
   yield takeEvery('GET_RECOMMENDED_MENTORS', fetchRecommendedMentors);
   yield takeEvery('GET_ALL_MENTORS', fetchAlldMentors);
   yield takeEvery('GET_MANAGE_REQUEST', fetchManageRequest);
   yield takeEvery('GET_MENTOD_DETAIL', fetchMentorDetail);
   yield takeEvery('CREATE_MENTOR_REQUEST', CreateMentorRequest);
   yield takeEvery('UPDATE_MENTOR_REQUEST', UpdateMentorRequest);
}

export default getMatch;