import type from    '../types';

const journal={
    setjourantTab:function(payload){
        return{
            type:type.SET_JOURNAL_TAB,
            payload
        }
    },
    getMenteeList:function(payload){
        return{
            type:type.GET_MENTEE_LIST,
            
        }
    },
    setMenteeList:function(payload){
        return{
            type:type.SET_MENTEE_LIST,
            payload
        }
    },
    getMentorList:function(payload){
        return{
            type:type.GET_MENTOR_LIST,
            
        }
    },
    setMentorList:function(payload){
        return{
            type:type.SET_MENTOR_LIST,
            payload
        }
    },
   setDetailParam:function(payload){
    return{
        type:type.SET_DETAIL_PARAM,
        payload
    }
    },

    getMenteeDetail:function(payload){
        return{
            type:type.GET_MENTEE_DETAIL,
            payload
        }
    },
    setMenteeDetail:function(payload){
        return{
            type:type.SET_MENTEE_DETAIL,
            payload
        }
    },
    getMentorDetail:function(payload){
        return{
            type:type.GET_MENTOR_DETAIL,
            payload
        }
    },
    setMentorDetail:function(payload){
        return{
            type:type.SET_MENTOR_DETAIL,
            payload
        }
    },
    getAvailability:function(payload){
        return{
            type:type.GET_AVAILABILITY,
            payload
        }
    },
    setAvailability:function(payload){
        return{
            type:type.SET_AVAILABILITY,
            payload
        }
    },
    updateMentorgoal:function(payload){
        return{
            type:type.UPDATE_MENTORE_GOAL,
            payload
        }
    },
    bookMentorSession:function(payload){
        return{
            type:type.BOOK_MENTOR_SESSION,
            payload
        }
    },
    acceptMentorSession:function(payload){
        return{
            type:type.ACCEPT_MENTOR_SESSION,
            payload
        }
    },
    rejectMentorSession:function(payload){
        return{
            type:type.REJECT_MENTOR_SESSION,
            payload
        }
    },
    rescheduleMentorSession:function(payload){
        return{
            type:type.RESCHEDULE_MENTOR_SESSION,
            payload
        }
    },




    setbookMentorSessionresponce:function(payload){
        return{
            type:type.SET_BOOK_MENTOR_SESSION,
            payload
        }
    },
    getSubscribeChannelId:function(payload){
        return{
            type:type.GET_SUBSCRIB_CHANNEL_ID,
            payload
        }
    },
    setSubscribeChannelId:function(payload){
        return{
            type:type.SET_SUBSCRIB_CHANNEL_ID,
            payload
        }
    },
    messageList:function(payload){
        return{
            type:type.MESSAGE_LIST,
            payload
        }
    },
    updateMessageList:function(payload){
        return{
            type:type.UPDATE_MESSAGE_LIST,
            payload
        }
    },
    
}

export default journal;