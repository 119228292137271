import {Navigate} from 'react-router-dom';
import { lazy, Suspense } from 'react';
import TestingPubnub from '../components/TestingPubnub';
import PagenotFound from '../components/PagenotFound'; 
//import {Notifications} from '../components/Notifications';
const ProFile = lazy(() => import('../components/ProFile'));
const Home =lazy(()=>import('../components/Home') ) ;
const Notifications =lazy(()=>import('../components/Notifications') ) ;
const Match=lazy(()=>import('../components/Match') ) ;
const MentorProfile=lazy(()=>import('../container/Match/MentorProfile'));
const Journal=lazy(()=>import('../components/Journal') ) ;
const Schedule=lazy(()=>import('../components/Schedule'));
const Rings=lazy(()=>import('../components/Rings'));
const Chatwrapper = lazy(() => import('../components/chatWrapper'));
const About = lazy(() => import('../components/about'));
const Contact = lazy(() => import('../components/contact'));
const Privacypolicy = lazy(() => import('../components/privacypolicy'));
const ForgotPassword = lazy(() => import('../container/Password/ForgotPassword'));
const ChangePassword = lazy(() => import('../container/Password/ForgotPassword'));



const AppRoutes = [
  {
    path: '/ProFile',
    element:<Suspense fallback={<>...</>}> <ProFile /></Suspense>
    },
    {
        path: '/About',
        element: <Suspense fallback={<>...</>}> <About /></Suspense>
    },
    {
        path: '/ForgotPassword',
        element: <Suspense fallback={<>...</>}> <ForgotPassword /></Suspense>
    },
    {
        path: '/ChangePassword',
        element: <Suspense fallback={<>...</>}> <ChangePassword /></Suspense>
    },
    {
        path: '/Contact',
        element: <Suspense fallback={<>...</>}> <Contact /></Suspense>
    },
    {
        path: '/Privacypolicy',
        element: <Suspense fallback={<>...</>}> <Privacypolicy /></Suspense>
    },
  {
    path: '/Home',
    element: <Suspense fallback={<>...</>}> <Home/></Suspense> 
  },
  {
    path: '/Notifications',
    element: <Suspense fallback={<>...</>}> <Notifications/></Suspense> 
  },
  {
    path: '/Chatwrapper',
    element: <Suspense fallback={<>...</>}> <Chatwrapper/></Suspense> 
  },
  {
    path: '/Schedule',
    element: <Suspense fallback={<>...</>}> <Schedule/></Suspense> 
  },
  {
    path: '/Match',
    element: <Suspense fallback={<>...</>}> <Match/></Suspense> 
  },
  {
    path: '/Match/Profile/:id', 
    element: <Suspense fallback={<>...</>}> <MentorProfile/></Suspense> 
  },
  {
    path: '/*',
    element:<Navigate to='/ProFile' replace /> 
  },
  {
    path: '/Journal',
    element: <Suspense fallback={<>...</>}> <Journal/></Suspense> 
  },
  {
    path: '/TestingPubnub',
    element: <TestingPubnub/>
  },
  
  
 
];

export default AppRoutes;
