
import type from    '../types';


const homeactions={
    
    getMentorsDetail:function(){
        return{
            type:type.GET_MENTORDETAIL
        }
    },
    setMentorsDetail:function(payload){
        return{
            type:type.SET_MENTORDETAIL,
            payload
        }
    },
    setMentorsPhoto:function(payload){
        return{
            type:type.SET_MENTORDETAIL_PHOTO,
            payload
        }
    },
    getuserMentor:function(payload){
        return{
            type:type.GET_USERMENTORDETAIL,
            pageSize:payload.size,
            pageNumber:payload.num
        }
    },
    setuserMentor:function(payload){
        return{
            type:type.SET_USERMENTORDETAIL,
            payload
        }
    },
    getImageURL:function(payload){
        return{
            type:type.GET_IMAGEURL,
            imageID:payload
        }
    },
   
    uploadFile:function(payload) {
        return { 
          type: type.UPLOAD_FILE,
          payload
        }
      },
      
    publistPost:function(payload){
        return{
            type:type.PUSBLISH_POST,
            payload
        }
    },
    likePost:function(payload){
        return{
            type:type.LIKE_POST,
            payload
        }
    },
    updateUserPosts:function(payload){
        return{
            type:type.UPDATE_USERMENTORDETAIL,
            payload
        }
    },

    addComments:function(payload){
        return{
            type:type.ADD_COMMENTES,
            payload
        }
    },

    getCommentsList:function(payload){
        return{
            type:type.GET_COMMENTE_LIST,
            payload
        }
    },
    setCommentsList:function(payload){
        return{
            type:type.SET_COMMENTE_LIST,
            payload
        }
    },

}

export default homeactions;