import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom'

export class Layer2 extends Component {
  
render() {
    return (
        <div className="section-learn layer2bg">
         <div className="container">
          <div className="row">
            <div className="col-12">
             <div className='unlocklabelname'>Unlock Opportunities and Grow with <span class="mentorzcolor">Mentorz</span></div>
            </div>
            <div className="col-12">                  
              <div className="tab-content" id="myTabContent">
                <div className="row mt-5">
                  <div className="col-sm  " >
                  <div className={`container mentdegin`}>
                  <img src="assets/images/small/mentee.jpg"   className="" alt="" />
                  <p className='mentheader1 ml-4'>Realize Your Full Potential</p>
                  <p className='mentdescription ml-4'>Whether you're starting your journey or looking to enhance your skills, our platform offers the mentorship you need to achieve your professional goals.</p>
                  </div>
                  </div>
                  <div className="col-sm" >
                    <div className={`container mentdegin `}  >
                    <img src="assets/images/small/mentor.jpg"   className="" alt="" />
                    <p className='mentheader1 ml-4'>Monetize Your Expertise</p>
                    <p className='mentdescription ml-4'>Mentorz enables you to make a meaningful impact on others' lives while harnessing your expertise to its full potential.</p>
                    </div>
                  </div>
                  <div className="col-sm">
                    <div className={`container mentdegin `} >
                    <img src="assets/images/small/Business.jpg"   className="" alt="" />
                    <p className='mentheader1 ml-4'>Democratize Smart Mentorship</p>
                    <p className='mentdescription ml-4'>Create a culture of learning and growth. Enhance your employees' engagement, retention, and productivity, ultimately driving your organization's success.</p>
                    </div>
                  </div>
               </div>     
                {/* <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                  <div className="row">
                    <div className="col-lg-4 col-md-12">
                      <div className="for-img">
                        <img className="img-fluid" src="assets/images/homepage/individual_1.png" alt="individual_1" />
                      </div>
                      <div className="for-title">Match</div>
                      <div className="for-text">
                        Find mentors and get chosen as a mentor based on expertise, interests and values.
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="for-img">
                        <img className="img-fluid" src="assets/images/homepage/individual_2.png" alt="individual_1" />
                      </div>
                      <div className="for-title">Learn</div>
                      <div className="for-text">
                        Learn from experts, advise, discover yourself and measure your progress.
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="for-img">
                        <img className="img-fluid" src="assets/images/homepage/individual_3.png" alt="individual_1" />
                      </div>
                      <div className="for-title">Grow</div>
                      <div className="for-text">
                        Reflect on mentorship insights, achievements and define new goals. 
                      </div>
                    </div>
                    <div className="col-12 text-center mt-5">
                      <NavLink tag={Link} to="/sign-up" className="btn view_mymentee_prfl signupbtn offset-5">Sign Up</NavLink>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
                  <div className="row">
                    <div className="col-lg-4 col-md-12">
                      <div className="for-img">
                        <img className="img-fluid" src="assets/images/homepage/business_1.png" alt="business_1" />
                      </div>
                      <div className="for-title">Match</div>
                      <div className="for-text">
                        Build custom programs, manage matching process among employees and groups, customize with your branding.
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="for-img">
                        <img className="img-fluid" src="assets/images/homepage/business_2.png" alt="business_2" />
                      </div>
                      <div className="for-title">Learn</div>
                      <div className="for-text">
                        Scale with mentorship journey templates, measure employee progress along journey stages, invite external VIP mentors.​
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="for-img">
                        <img className="img-fluid" src="assets/images/homepage/business_3.png" alt="business_3" />
                      </div>
                      <div className="for-title">Grow</div>
                      <div className="for-text">
                        Measure mentorship achievements in dashboards, connect insights (AI) to rewards and development discussions.
                      </div>
                    </div>
                    <div className="col-12 text-center mt-5">
                      <a href="#!" className="btn btn-primary">Learn more</a>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
    </div>
 
    );
}
}
