import { call, put,select, takeEvery } from 'redux-saga/effects';
import authActions from '../actions/authActions';
import loaderActions from '../actions/loader';
import ToastMesage from '../../Services/Toast';
import type from '../types';
import Api from './api_config';
import {  toast } from 'react-toastify';
import ProfileActions from '../actions/proFileActons';
import matchActions from '../actions/matchActions';
import homeactions from '../actions/homeActions';
import modalActions from '../actions/modal';
let IMAGE_URL='https://mentorzstorageaccount1.blob.core.windows.net/mentorz/';




function* fetchSignInAuth(action) {
   try {
       const users = yield call(Api.authn.getSignInAuth, action.fields);
       const {data}=users;
       if(data.statusTypeId!=='200'){
        yield put(modalActions.toggleModal('verify_emailadress-modal'));
       }
       else{
       // toast.success(data.message);
       }
     // yield put({type: 'GET_USERS_SUCCESS', users: users});
     // yield put(actions.setUsers(data));
   } catch (e) {
   }
}

function* fetchloginAuth(action) {
   try {
      yield put(loaderActions.toggleLoader(true));
      const LoginAuthUser = yield call(Api.authn.getLoginAuth,action.fields);
       const { data, status } = LoginAuthUser.isAxiosError ? LoginAuthUser.response : LoginAuthUser;
       
       if(status!==200){
         yield put(authActions.setloginStatus(data.message));
       }
       else{

         if(action.fields.stripeCustomerId===""){
          yield put(authActions.getStripeCustomerId(action.fields));
         }

        // toast.success("'Login Successfull! Redirecting...'");
         var UserImage=yield imageURL(data.photoId);
         data.UserImage=UserImage;
         yield put(authActions.setloginStatus(''));
         localStorage.setItem("LogInStatus", "1");
         localStorage.setItem("login_data", JSON.stringify(data));
         if(action.navigate==='Onboarding'){ window.location.href = '/ProFile';}
         if(action.navigate==='Home'){ window.location.href = '/Home';}
        
       }
         yield put(loaderActions.toggleLoader(false));

   } catch (e) {
    yield put(loaderActions.toggleLoader(false));
   }
}

function* imageURL(imageId){
  let image;
  try{
      if(imageId!=="" && imageId!==undefined && imageId!=="string" )
      {
        image=IMAGE_URL+ imageId;
         
      }
      else{
       image='assets/images/User.png';
      }
      return image
  }
  catch(e){
      return e;
  }
}


export const userProfile_detail=(state=>state.proFileReducers.userProfile_detail);
function* uploadUserImage(req){
  const _detail=yield select(userProfile_detail);
  try{
    const image = yield call(Api.fileUpload.uploadFile,req);
    const {data}=image;
    if(data){
       yield put (ProfileActions.setProfileDetail({'photoId':req.payload.name}));
       yield put (ProfileActions.updateUserProfile(_detail));
       debugger
       yield put(homeactions.setMentorsPhoto(req.payload.name))
       yield put (loaderActions.toggleLoader(false))
       var UserImage=yield imageURL(req.payload.name);
        let usrdetail=localStorage.getItem('login_data');
        usrdetail=JSON.parse(usrdetail);
          var up={...usrdetail,photoId:req.payload.name,UserImage:UserImage}
         localStorage.setItem("login_data", JSON.stringify(up));
      }
  }
  catch(e){
      return e;
  }
}

function* fetchStripeCustomerId(req){
  try{
    const customerId = yield call(Api.authn.getStripeCustomerId,req.payload);
    const {data}=customerId;
    if(data.status===200){
      req.payload.stripeCustomerId=data.response;
      yield put(authActions.updateSigninUser(req.payload));
    }
    
  }
  catch(e){
      return e;
  }
}

function* updateSignInUser(req){
  try{
    const image = yield call(Api.authn.updateSignInUser,req.payload);
    const {data}=image;
  }
  catch(e){
      return e;
  }
}

function* verifyEmailAdress(req){
  try{
    const verify = yield call(Api.authn.verifyEmailAdress,req.payload);
    const {data}=verify;
  }
  catch(e){
      return e;
  }
}

function* validateEmailAdress(req){
  try{
    const validate = yield call(Api.authn.validateEmailAdress,req.payload);
    const {data}=validate;
    if(data.response===""){
      yield put(authActions.getSigninUser(req.payload));
   }
    yield put(authActions.setValidateEmailResp(data.response));

  }
  catch(e){
      return e;
  }
}

function* getAuthUser() {
   yield takeEvery('GET_SIGNINUSER', fetchSignInAuth);
   yield takeEvery('UPDATE_SIGNINUSER', updateSignInUser);
   yield takeEvery('VERIFY_EMAIL', verifyEmailAdress);
   yield takeEvery('VALIDATE_EMAIL', validateEmailAdress);
   yield takeEvery('GET_LOGINUSER', fetchloginAuth);
   yield takeEvery('GET_CUSTOMER_LOGINUSER', fetchloginAuth);
   yield takeEvery('UPLOAD_USER_IMAGE',uploadUserImage);
   yield takeEvery('GET_STRIPECUSTOMER_ID',fetchStripeCustomerId);

}

export default getAuthUser;