import { Route, Routes ,Navigate} from 'react-router-dom';
import LandingPage from "../components/langingPage";
import About from "../components/about";
import Contact from "../components/contact";
import PrivacyPolicy from "../components/privacypolicy";
import Login from "../components/login";
import  SignUp  from "../components/signin";
import PublicHeader from '../components/Public-Header';
import {LinkedIn} from '../components/LinkedIn';
import  BusinessLogin  from "../container/login/business_login";
import  CustomerLogin   from "../container/login/customer_login";
import { BusinessSignIn } from "../container/signin/business_signin";
import CustomerSignIn from "../container/signin/customer_signin";
import ChooseSubscription from '../container/signin/choose_subscription';
import { MobileLoginRequest } from '../container/login/MobileLoginRequest';
import { ForgotPassword } from '../container/Password/ForgotPassword';
import { ChangePassword } from '../container/Password/ChangePassword';

 const ProtectedRoutes = () => {
    return (
      <>
       <PublicHeader/>
       <Routes>
            <Route path='/login' element={<Login />} />
                <Route path='/about' element={<About />} />
                <Route path='/contact-us' element={<Contact />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/BusinessLogin' element={ <BusinessLogin /> } />
          <Route path='/IndividualLogin' element={ <CustomerLogin /> } />
          <Route path= '/sign-up' element= {<SignUp />}  />
          <Route path= '/BusinessSignIn' element= {<BusinessSignIn />}  />
          <Route path= '/IndividualSignIn' element= {<CustomerSignIn />}  />
          <Route path= '/ChooseSubscription' element= {<ChooseSubscription />}  />
          <Route path= '/LandingPage' element= {<LandingPage />}  />
                <Route path='/LinkedIn' element={<LinkedIn />} />
                <Route path='/ForgotPassword' element={<ForgotPassword />} />
                <Route path='/ChangePassword/:email' element={<ChangePassword />} />
            <Route path='/*' element={<Navigate to='/LandingPage' replace />} />
                <Route path='/MobileLoginRequest/:requestId' element={<MobileLoginRequest />} />
               
                
      </Routes>
     </>
    )
  }

  export default ProtectedRoutes;