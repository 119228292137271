
const initialState = {
    mentor_list:[],
    mentor_detail:[],
    mentee_list:[],
    mentee_detail:[],
    journal_tab:'list',
    detail_param:'',
    availability:[],
    bookSeession_responce:'',
    subscribed_ChannelId:'',
    message_list:[],
    messages_grupuByDate:[]
  }

const journalReducer=(state =initialState ,action )=>{

    const  getFormattedDate=(today)=>
    {
        
        var week =['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'] ;
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
        var month= monthNames[today.getMonth()]
        var day  = week[today.getDay()];
        var dd   = today.getDate();
        var yyyy = today.getFullYear();
        return month+' ' + dd + ' , '+ yyyy + '('+day+') ' ;
    }

    const  timeFormat=(today)=>
    {
        var hours = today.getHours();
        var minutes = today.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+ minutes : minutes;
        var timeformat = hours + ':' + minutes + ' ' + ampm;
        return timeformat;
    }
    const messageListFormate=()=>{
        
    }
  

    switch (action.type) {
        case 'SET_JOURNAL_TAB':
            return {
                ...state,
                journal_tab: action.payload,
            }
        case 'SET_DETAIL_PARAM':
            return {
                ...state,
                detail_param: action.payload,
            }
        case 'SET_MENTEE_LIST':
            return {
                ...state,
                mentee_list: action.payload,
            }
        case 'SET_MENTOR_LIST':
            return{
                ...state,
                mentor_list:action.payload
             }   
        case 'SET_MENTEE_DETAIL':
          return{
            ...state,
            mentee_detail:action.payload
            }
        case 'SET_MENTOR_DETAIL':
            return{
                ...state,
                mentor_detail:action.payload
            }  
        case 'SET_AVAILABILITY':
        action.payload.map((el)=>{
            var date = new Date(el.startDateTime);
            el.formateDate = getFormattedDate(date);
            var Sdate = new Date(el.startDateTime);
            var Edate = new Date(el.endDateTime);
            el.formateTime=timeFormat(Sdate)+' - '+ timeFormat(Edate);
            return el
        })

        return{
            ...state,
            availability:action.payload
            }  
        case 'SET_BOOK_MENTOR_SESSION':
        return{
            ...state,
            bookSeession_responce:action.payload
        }  
        case 'SET_SUBSCRIB_CHANNEL_ID':
        return{
            ...state,
            subscribed_ChannelId:action.payload
        }  
        case 'MESSAGE_LIST':
            return{
                ...state,
                message_list:action.payload,
                messages_grupuByDate:action.payload
            } 
        case 'UPDATE_MESSAGE_LIST':
            
            const res=[...state.message_list,...action.payload];
            const final=res.sort((a,b) => a.timetoken - b.timetoken);

            const groupBy = final.reduce((acc, currentValue) => {
                let groupKey = currentValue.groupbyDate;
                if (!acc[groupKey]) {
                  acc[groupKey] = [];
                }
                acc[groupKey].push(currentValue);
                return acc;
              }, {});
            return{
                ...state,
                message_list:final,
                messages_grupuByDate:groupBy
            } 
        default:
            return state;
    }
}

export default journalReducer;