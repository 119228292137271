import React, { useEffect, useState,forwardRef, useRef, useImperativeHandle} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import {  toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import Skeleton ,{ SkeletonTheme }  from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'; 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import modalActions from '../redux/actions/modal';
import matchActions from '../redux/actions/matchActions';
import homeactions from '../redux/actions/homeActions';
import authActions from '../redux/actions/authActions';
import journal from '../redux/actions/journalActions';
import CommentsLoader from '../SkeletonLoader/CommentsLoader';
import AvailabilityLoader from '../SkeletonLoader/Availability_Loader';

import loaderActions from '../redux/actions/loader';

import Calendar1 from 'react-calendar';
import 'react-calendar/dist/Calendar.css'
import DatePicker ,{ Calendar} from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import "react-multi-date-picker/styles/colors/red.css";
import Loader from '../container/loader';
const USERDATA=JSON.parse(localStorage.getItem("login_data"));

const USERDETAIL=JSON.parse(localStorage.getItem("user_detail"));


const CustomeModal = forwardRef((props, ref) => {
  debugger
  const dispatch=useDispatch(); 
  const navigate = useNavigate();
  const el = useRef(null);
  const inputRef =useRef(null);
  const [dragActive, setDragActive] = React.useState(false);// drag state
  const comment_list=useSelector(state=>state.homeReducer.Comment_list);
  const loading=useSelector(state=>state.loader.loading);
  const availabilityLoading=useSelector(state=>state.loader.availability_loader);
  
  const mentor=useSelector(state=>state.journalReducer.mentor_detail);
  const mentee=useSelector(state=>state.journalReducer.mentee_detail);
  
  const [sessiondate, setSessionDate] = useState (props);


  useImperativeHandle(
    ref,
    () => ({
      getCommentList(data) {
        dispatch(loaderActions.toggleLoader(true))
          var param={
            "postId":  data.postId,
            "pageNumber": 0,
            "pageSize": 10
          }
          dispatch(homeactions.getCommentsList(param))
        },
      
    }),
)

  
  const filePicekerRef = useRef(null);
  const [goal, setgoal] = useState('');
  
  const [message, setmessage] = useState('');
  const [wordslength, setwordslength] = useState(0);
  const [selectedFile, setSelectedFile] = useState('');
  const [profileselectedFile, setProfileSelectedFile] = useState('');
  const [imagePreview, setImagePreview] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [expertiseID, setexpertiseID] = useState(''); 
  const modal=useSelector(state => state.modal);
  const mentor_detail=useSelector(state => state.matchReducer.mentor_detail); 
  const Journalmentor_detail=useSelector(state=>state.journalReducer.mentor_detail);

  const availability=useSelector(state=>state.journalReducer.availability);
  
  
  const [value, setvalue] = useState();
    const handleCalenderChange=(event)=>{
      
      const day = event.target.dataset.day

    }
  const [updateGoal , setupdateGoal] = useState(Journalmentor_detail.goal);
  const [user, setUser] = useState({ name: Journalmentor_detail.goal,age: 25,});
  const [availabilityMonth , setavailabilityMonth] = useState({id:1,name:'Jan'});
  
  const [inputValues, setInputValue] = useState({comment: '', });
  const Months=[{id:1,name:'Jan'},{id:2,name:'Feb'},{id:3,name:'Mar'},
               {id:4,name:'Apr'},  {id:5,name:'May'},   {id:6,name:'Jun'},
               {id:7,name:'Jul'},   {id:8,name:'Aug'},{id:9,name:'Sep'},
               {id:10,name:'Oct'},{id:11,name:'Nov'},{id:12,name:'Dec'},]

    useEffect(() => {
      setSessionDate(props)
      //setInputValue({comment: '', })
    //el.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
},[props]);

const scrollToBottom = () => {
  el.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
}

  const closeModal=()=>{
    dispatch(modalActions.toggleModal(''));
    setexpertiseID('');
    setgoal('');
    setVideoPreview(null);
    setImagePreview(null);
    setmessage('');
    setwordslength(0);
    setSelectedFile('')
    setInputValue({comment: '', })
    setupdateGoal(Journalmentor_detail.goal)
  }

  ////modal_name==='mentor-received-success-modal'////////////////////////////////////
  const gotoGernalpage=()=>{
    navigate(`/Journal`); 
    dispatch(modalActions.toggleModal(''))
  }

 

   //////modal_name==='mentor-send-request-modal'//////////////////////////////////
   const sendRequest=()=>
   {  
       if(expertiseID===""){toast.warning('Please pick one expertise to focus');}
       else{
        var param={
          "mentorId": mentor_detail.id,
          "status": 1, 
          "expertiseId":expertiseID,
          "goal": goal
        }
        dispatch(matchActions.createMentor_request(param));
         
         }
   }
   const getExpID=(id)=>{
    setexpertiseID(id); 
    Journalmentor_detail.expertiseId=id;
   }


   ///////modal_name==='mentor-profile-upload-modal'///////////////////////////////

   const profile_upload={
     // handle drag events
   handleDrag : function(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  },
  
  // triggers when file is dropped
   handleDrop : function(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      var filename=e.dataTransfer.files[0].name.split(".");
      var extenssion=filename[1];
      var File_newName=profile_upload.generate_uuidv4()+'.'+extenssion;
      var file = e.dataTransfer.files[0];
      var blob = file.slice(0, file.size, 'image/png'); 
      var newFile = new File([blob], File_newName, {type: 'image/png'});
      setProfileSelectedFile(newFile)
      setSelectedFile(URL.createObjectURL(newFile));
     
    }
  },
  
  // triggers when file is selected with click
   handleChange :function(e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      var filename=e.target.files[0].name.split(".");
      var extenssion=filename[1];
      var File_newName=profile_upload.generate_uuidv4()+'.'+extenssion;
      var file = e.target.files[0];
      var blob = file.slice(0, file.size, 'image/png'); 
      var newFile = new File([blob], File_newName, {type: 'image/png'});
      setProfileSelectedFile(newFile)
      setSelectedFile(URL.createObjectURL(newFile));
    }
  },
  
// triggers the input when the button is clicked
   onButtonClick:function() {
    inputRef.current.click();
  },
  generate_uuidv4:function() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
    function(c) {
       var uuid = Math.random() * 16 | 0, v = c == 'x' ? uuid : (uuid & 0x3 | 0x8);
       return uuid.toString(16);
    });
  },

  uploadPhoto(){
    dispatch(loaderActions.toggleLoader(true));
    dispatch(authActions.uploadUserImage(profileselectedFile));
  }
   }

  const handleFileChange2=(e)=>{
    setupdateGoal(e.target.value)
  }


  const commentBox={
     handleSubmit(e) { 
      const {comment}=inputValues;
      let usrdetail=localStorage.getItem('login_data');
      usrdetail=JSON.parse(usrdetail);
      e.preventDefault();
      dispatch(loaderActions.toggleLoader(true))
      var param={
        "postId": props.cmtbox.postId,
        "commenterId": usrdetail.id,
        "commentText": comment
      }
      dispatch(homeactions.addComments(param));
      setInputValue({comment: '', })
     },
      //handle submit updates
       handleChange(event) {
       const { name, value } = event.target;
       setInputValue({ ...inputValues, [name]: value });
     }

     
  }
  const addPost=
  {
    publishFile:  function () {
      var param={
        "postText": message,
        "postImageId": imagePreview==null? 'string':selectedFile.name,
        "postVideoId": videoPreview==null? 'string':selectedFile.name,
        "selectedFile":selectedFile,
      }
      dispatch(homeactions.uploadFile(param))
     },

    onchangeTextarea:function (event) {
        setmessage(event.target.value);
        setwordslength(event.target.value.length );
    },

    previewFile: function(e) 
    {
      // Reading New File (open file Picker Box)
      const reader = new FileReader();
      // Gettting Selected File (user can select multiple but we are choosing only one)
      //const selectedFile = e.target.files[0];
      const unique_id = uuid();
      const small_id = unique_id.slice(0,8)
      var image = e.target.files[0];
      var blob = image.slice(0, image.size);
      const selectedFile = new File([blob],unique_id, { type: `${image.type}` });
     
      if (selectedFile) {
        reader.readAsDataURL(selectedFile);
      }
      // As the File loaded then set the stage as per the file type
      reader.onload = (readerEvent) => {
        if (selectedFile.type.includes("image")) {
          
          setImagePreview(readerEvent.target.result);
          setSelectedFile(selectedFile)
          setVideoPreview(null);
        } else if (selectedFile.type.includes("video")) {
          setVideoPreview(readerEvent.target.result);
          setImagePreview(null);
          setSelectedFile(selectedFile)
        }
      };
    }
   }

   const goalupdatemodal={
    update:function () {
      var param={
        "id":Journalmentor_detail.mentorRequestId, 
        "userId": USERDATA.id,
        "expertiseId": Journalmentor_detail.expertiseId,
        "goal": updateGoal
      }
      dispatch(journal.updateMentorgoal(param));
      dispatch(modalActions.toggleModal(''));
    },
   }

   const verifyEmail={
      cancel:function () {
       dispatch(authActions.setSignupStage('signup_completed'));
       dispatch(modalActions.toggleModal(''));
      },
      resendEmail:function(){
        alert('resendEmail')
        dispatch(modalActions.toggleModal(''));
      }
   }
   

  return (
    <>
    <Modal show={modal.modal_name==='mentor-received-success-modal'} 
      className='mt-5'  dialogClassName={"CSRModal"} style={{ display: 'block' }}>
     <Modal.Body >
       <div className ="card-body  ">
          <h5 className =" text-center chatheader_title mt-2">Congratulations!</h5>
          <p className ="text-center chat_tittle mt-4">You have connected with {props.data?.req?.firstName} </p>
          <p className ="text-center chat_tittle ">{props.data?.req?.lastName}! You may manage your </p>
          <p className ="text-center  chat_tittle  mb-4">mentorship on journal now. </p>
        </div>
      </Modal.Body>
        <Modal.Footer >
          <div className='row' onClick={gotoGernalpage}> 
               <p className='text-center chatheader_title' style={{color:"#FF4339"}}>Go to Journal </p>
          </div>
          <div className='row border-top' onClick={closeModal}> 
               <p className='text-center chatheader_title' style={{color:"#FF4339"}}>Close</p>
          </div>
        </Modal.Footer>
    </Modal>     

    <Modal show={modal.modal_name==='mentor-send-request-modal'}   >
      <Modal.Header closeButton  onClick={closeModal}>
        <Modal.Title className='chatheader_title ms-5 ps-5 '>My Mentorship Goal is:</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card notification_box ">
          <div className="card-body ">
          <textarea cols="52" rows="7"  maxlength="400" value={goal}  onChange={event => setgoal(event.target.value)}
          placeholder="Describe your mentorship goal to your potential mentor. Be as clear and specific as possible.">
          </textarea>
          </div>
      </div>
        <p className=' chatheader_title text-center mt-4 '>Pick one expertise to focus:</p>
          <div className='row'>
          <div className='row gy-2'>
              {
                mentor_detail && mentor_detail.expertiseList?.map((exp)=>{
                return(
                <div key={exp.id} className="col-4" onClick={()=>getExpID(exp.id)}>
                  <div className={`p-2 profile-capsul  ${expertiseID===exp.id? "bgcolors" : ""}`} key={exp.id}>{exp.name}</div> 
                </div>
                )
              })
            } 
          </div>
          </div>  
      </Modal.Body>
      <Modal.Footer className='mt-4 cursor-pointer' onClick={sendRequest}>
        <div className='row'><p className='text-center chatheader_title' style={{color:"#FF4339"}} >Send Request</p></div>
      </Modal.Footer>
    </Modal> 

    <Modal show={modal.modal_name==='mentor-send-success-modal'} className='mt-5 pt-5'  dialogClassName={"CSRModal"} >
        <Modal.Body>
                <h5 className =" text-center chatheader_title mt-2">Request Sent!</h5>
                <div className='row'><i className="fa fa-check-circle fa-3x mt-4 msg_btn text-center"></i></div>
        </Modal.Body>
        <Modal.Footer className='cursor-pointer'>
            <div className='row' onClick={closeModal}>
            <p className='text-center chatheader_title' style={{color:"#FF4339"}}>Close</p></div>
        </Modal.Footer>
     </Modal>  
    
    <Modal show={modal.modal_name==='mentor-profile-upload-modal'}   className={`${selectedFile ? "imgup1" : "imgup"}`} style={{ height:'500px'  }}>
          <div  >
          <Modal.Header closeButton  onClick={closeModal}  style={{boder:"none !important"}}>
          </Modal.Header>
           <Modal.Body >
            {
            <div className='box imgupload_box '>
            <form id="form-file-upload" onDragEnter={profile_upload.handleDrag} onSubmit={(e) => e.preventDefault()}>
              <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={profile_upload.handleChange} />
              <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
              {selectedFile ?
                <div>
                   {
                    loading?
                    <div style={{marginTop:'110px'}}>
                      <Skeleton circle height={240} width={240}/>
                      <div className='ms-2  mt-3'><Skeleton height={40} borderRadius={50}/></div>
                      <button type="button" className="btn  btnwcss "  > 
                        <Skeleton height={40} width={140} borderRadius={50}/> 
                      </button>
                     </div>
                    :
                    <>
                    <div className="imagesection ">
                      <img src={selectedFile} className="profile_upload" alt=""/>
                    </div>
                    <div className='ms-2  mt-4'> Drag and drop or</div>
                    <span  className=" mt-5 mb-4 " style={{color:"#4958E0",textDecoration:"underline"}} onClick={profile_upload.onButtonClick}>  upload photo </span>
                    </>
                  }
                 </div>
                :
                <div>
                  <div className="imagesection mt-5 mb-2 ml-4">
                     <img src="assets/images/small/VectorDwld.png" className="vector-image" alt=""/>
                 </div>
                  <div className='ms-2 mb-1'> Drag and drop or </div>
                  <span  className=" mt-4 mb-4 " onClick={profile_upload.onButtonClick} style={{color:"#4958E0",textDecoration:"underline"}} >  upload photo </span>
                </div> 
              }
              </label>
              { dragActive && <div id="drag-file-element" onDragEnter={profile_upload.handleDrag} onDragLeave={profile_upload.handleDrag} onDragOver={profile_upload.handleDrag} onDrop={profile_upload.handleDrop}></div> }
            </form>
            <br/>
               {
                selectedFile && !loading && <button type="button" className="btn view_mymentee_prfl  mt-5 ms-5" onClick={profile_upload.uploadPhoto} > Save </button>
               }
            </div>
               }
             </Modal.Body> 
              <Modal.Footer  style={{justifyContent:"center",border:"none"}}>
           </Modal.Footer>
        </div>
    </Modal> 
 
 
    <Modal show={modal.modal_name==='mentor-social-post-modal'}   dialogClassName="postModal"  style={{ display: 'block' }}>
          <div className='postdilouge_box'>
             <Modal.Header  closeButton onClick={closeModal} >
                  <Modal.Title className='modelboxheader_1 ' style={{marginLeft:"239px"}}>ADD POST </Modal.Title>
                  <p className='posttittle' onClick={addPost.publishFile}>Publish</p>
              </Modal.Header>
             <Modal.Body >
                  <div className="box  postbox mt-2 ">
                    <div className="card-body ">
                      <textarea cols="52" rows="7"  maxlength="140" value={message}  
                         onChange={addPost.onchangeTextarea}
                         placeholder="Enter your message.">
                      </textarea>
                    </div>
                  </div>
                    <p className='mt-2 ml-3'>{wordslength}/140</p>
                      {
                        (imagePreview==null && videoPreview==null)?
                        <div style={{height: '150px'}}></div>
                        :
                        <div className="preview">
                          {imagePreview != null && <img src={imagePreview} alt="" />}
                          {videoPreview != null && <video controls src={videoPreview}></video>}
                      </div>
                      }
                <div className='row horizontal_line'>
                  <label  for="file-input" className=' cursor-pointer mt-3'>
                    <p className='text-center posttittle'  onClick={() => filePicekerRef.current.click()}> Upload Image
                      <input ref={filePicekerRef} accept="image/*, video/*" onChange={addPost.previewFile}  type="file" hidden />
                    </p>
                  </label>
                </div>
             </Modal.Body> 
            <Modal.Footer  style={{justifyContent:"center"}}>
            <div className='row'>
                    <label  for="file-input" className=' cursor-pointer'>
                      <p className='text-center posttittle'  onClick={() => filePicekerRef.current.click()}> Upload Live Video
                      <input  ref={filePicekerRef} accept="image/*, video/*" onChange={addPost.previewFile} type="file" hidden />
                      </p>
                    </label>
                </div>
            </Modal.Footer>
        </div>
    </Modal> 

    <Modal show={modal.modal_name==='mentor-post-event-modal'} dialogClassName="reportmodel" style={{ display: 'block',  }}>
    <Modal.Header  closeButton onClick={closeModal} >
                 
      </Modal.Header>
      <div className='reportalertpopup'>
        <Modal.Body>
          <div className='col-2'>
             <i className="bi bi-bookmark reporticons">
              <span className='modelboxheader_1 ml-3 mt-1'> Save post </span>
             </i>
            </div>
            <div >
              <i className="bi bi-x-square  reporticons">
              <span className='modelboxheader_1 ml-3 mt-1'> Hide post </span></i>
            </div>
            <div>
               <i className="bi bi-exclamation-circle reporticons">
               <span className='modelboxheader_1 ml-3 '> Report post </span></i>
            </div>
        </Modal.Body>
        </div>
     </Modal> 

     <Modal show={modal.modal_name==='mentor-comment-box-modal'}    dialogClassName="postModal"  >
          <div className='postdilouge_box dilougeboxwidth  '>
             <Modal.Header  closeButton  onClick={closeModal} className='commnet_box_header' >
                  
                <Modal.Title className='modelboxheader_1 ' style={{marginLeft:"204px"}}>
                 <h4 className='mt-1'>  {props.cmtbox?.posterFirstName} {props.cmtbox?.posterLastName}'s Post</h4>
                </Modal.Title>
                  
              </Modal.Header>
             <Modal.Body style={{height:"480px"}}>
             <div className='row cmtoverflow' onLoad={scrollToBottom}>
                   {
                      props.cmtbox?.mediaType==='Video'?
                      <video className='fill_image' width="500" height="500" controls >
                        <source src={props.cmtbox?.postVideoId} type="video/mp4"/>
                        </video>
                    :
                    <img 
                        src={props.cmtbox?.postImageId} alt='' className='fill_image'
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; 
                          currentTarget.src="assets/images/no-post-icon.jpg";
                        }}
                        />
                    }
                    {
                      loading?
                      <CommentsLoader/>
                      :
                      <div className=' row mt-4'>
                      {
                        ( comment_list.length>0)?
                        <div className=" mb-4 ">
                        {
                          comment_list && comment_list.map((el)=>{
                            return(
                            <div className="list-group list-group-numbered d-flex justify-content-between " role="alert" >
                            <div className=" d-flex justify-content-between align-items-start p-2 m-1 ">
                            <div className=''>
                               <div className='cornerimage  '> 
                               <img src={el.commenterPhotoId} className='rounded-circle cmtimg mt-1' alt='' onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src="assets/images/no-post-icon.jpg";
                                  }}
                                  />
                                </div>
                              <div className="alert alert-secondary  ml-5  commentbox ">
                                <div className=" me-auto mt-3 ml-2">
                                  <p className="img_align">{el.commenterFirstName} {el.commenterLastName}</p>
                                  <p>{el.commentText}</p>
                                </div>
                              </div>
                                <p className='commentdate mb-2'>{el.commentDate}</p>
                            </div>
                          </div>
                          </div>
                        )
                          })
                        }
                        </div> 
                        :
                        <div className='text-center mb-5'>
                          <i className="bi bi-chat-square-text fs-3"></i>
                          <p style={{color:'black'}}>No comments yet</p>
                          <p>Be the first to comment</p>
                        </div>
                        }    
                      </div>
                    }
                 <div id={'el'} ref={el}> </div>
            </div>
             </Modal.Body> 
            <Modal.Footer  style={{justifyContent:"center"}} className='commnet_box_footer'>
            <form className='row ' onSubmit={e => { commentBox.handleSubmit(e) }}>
             <div className='col-1 mt-1'><img src={USERDATA?.UserImage} alt="profile" className='commnet_box_footer_imag' /></div>
                    <div className='col-10'>
                    <input
                      placeholder='Write a comment...'
                      type='string'
                      id='comment'
                      name='comment'
                      className='input-field w-100   input_msgbox'
                      style={{height:"44px"}}
                      onChange={(e) => commentBox.handleChange(e)}
                      value={inputValues.comment}
                    />
                    </div>
                    <div className='col-1 mt-1'>
                        <button type="submit" className='sendmsgbtn mt-1 ' >
                           <i className="bi bi-send "style={{fontSize:"21px"}}></i>
                        </button>
                    </div>
                </form>
            </Modal.Footer>
        </div>
    </Modal> 

    <Modal show={modal.modal_name==='mentor-goal-update-modal'}   >
      <Modal.Header closeButton  onClick={closeModal}>
        <Modal.Title className='chatheader_title ms-5 ps-5 '>My  Goal </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="card-body ">
          <input 
            type="text" 
            name="description" 
            id="inputID"
            placeholder="Enter your quick Descriptionn" 
            value={updateGoal}
            className="form-control form-control-border-btm"
            onChange={handleFileChange2}
            />
          </div>
        <p className=' chatheader_title text-center mt-4 '>Pick one expertise to focus:</p>
          <div className='row'>
          <div className='row gy-2'>
              {
                Journalmentor_detail && Journalmentor_detail.expertiseList?.map((exp)=>{
                return(
                <div key={exp.id} className="col-4" onClick={()=>getExpID(exp.expertiseId)}>
                  <div className={`p-2 profile-capsul  ${Journalmentor_detail.expertiseId===exp.expertiseId? "bgcolors" : ""}`} key={exp.expertiseId}>{exp.expertiseName}</div> 
                </div>
                )
              })
            } 
          </div>
          </div>  
      </Modal.Body>
      <Modal.Footer className='mt-4 cursor-pointer' onClick={goalupdatemodal.update}>
        <div className='row'><p className='text-center chatheader_title' style={{color:"#FF4339"}} >Update</p></div>
      </Modal.Footer>
    </Modal> 

   
     <Modal show={modal.modal_name==='update-schedule-modals'}   dialogClassName="dateupadteModal"  style={{ display: 'block' }}>
          <div className='postdilouge_box'>
             <Modal.Header  closeButton onClick={closeModal} style={{border:"none",marginBottom:"15px"}} >
                  <Modal.Title className='modelboxheader_1  ' style={{marginLeft:"134px",fontSize:"24px"}}>Reschedule Call </Modal.Title>
              </Modal.Header>
             <Modal.Body >
     <div className='dateupadte'>
    
     <Calendar1 className="custom-calendar " 
          value={new Date()}
          multiple
          minDate={new Date()}
          onChange={handleCalenderChange}
          plugins={[
         
          ]}
        />
        <hr className="availline" />
         </div>
        <div className='row mt-4 mt-3'>
          <div className='col-1 ml-4'>
          <i className="bi bi-alarm " style={{fontSize:"25px"}}></i>
          </div>
          <div className='col mb-2'>
             <p className="calender_tittle ml-2 mt-1">Select a start time </p>
           </div>
        </div>
        <Calendar  className=" red " style={{marginLeft:"72px"}}
          disableDayPicker
          format="HH:mm A"
          value={new Date()}
          plugins={[
            <TimePicker hideSeconds   />
          ]}
        />
        <div className='row mt-4  mt-3'>
          <div className='col-1 ml-4'>
          <i className="bi bi-alarm" style={{fontSize:"25px"}}></i>
          </div>
          <div className='col mb-2'>
             <p className="calender_tittle mt-1">Select a end time</p>
           </div>
        </div>
        <Calendar  className=" red " style={{marginLeft:"72px"}}
          disableDayPicker
          format="HH:mm A"
          value={new Date()}
          plugins={[
            <TimePicker hideSeconds />
          ]}  
        />
        
             </Modal.Body> 
            <Modal.Footer  style={{justifyContent:"center",border:"none"}}>
              <center>
            <div className='row'>
                    <label  for="file-input" className=' cursor-pointer'>
                    <button type="button" className="btn view_mymentee_prfl mt-3 mb-3 " >Save Changes </button>
                    </label>
                </div>
                </center>
            </Modal.Footer>
        </div>
    </Modal>  

    <Modal show={modal.modal_name==='bank_account_details'}   dialogClassName="dateupadteModal"  style={{ display: 'block' }}>
          <div className='postdilouge_box'>
             <Modal.Header  closeButton onClick={closeModal} style={{border:"none",marginBottom:"15px"}} >
                  <Modal.Title className='modelboxheader_1  ' style={{marginLeft:"-2px",fontSize:"24px",marginTop :"76px !important"}}>Enter bank account details </Modal.Title>
              </Modal.Header>
             <Modal.Body >
             <div className="personal-conatiner ">
    <div className='box mt-5'>
      <form className='pb-4'>
       <div className='col-12 '>
        <label for="routingnumber" className="form-label pb-2 ">Routing Number</label>
         <input type="text" className="form-control form-control-border-btm " placeholder="110000000" style={{width:"335px"}} name="routingnumber"/>
          </div>

     <div className='col-12 '>
       <label for="routingnumber" className="form-label pb-2 mt-3">Account Number</label>
        <input type="text" className="form-control form-control-border-btm " placeholder="000123456789" style={{width:"335px"}} name="routingnumber"/>
         </div>

    <div className='col-12 '>
      <label for="routingnumber" className="form-label pb-2 mt-3">Confirm Account Number</label>
        <input type="text" className="form-control form-control-border-btm  " placeholder="000123456789" style={{width:"335px"}} name="routingnumber"/>
        </div>
     </form>
     </div>
      <div className='row mt-2  'style={{marginLeft:"49px"}}>
          <div className="col-6 profilecancelcss mb-5">
          <button type="button"  className="btn view_mymentee_desc" style={{width:"120px"}}>  Cancel </button>
          </div>
          <div className="col-6 ">
          <button type="button" className="btn view_mymentee_prfl" style={{width:"120px"}} > Save </button>
          </div>
          </div>
        </div> 
             </Modal.Body> 
        </div>
    </Modal>  

    <Modal show={modal.modal_name==='verify_emailadress-modal'} dialogClassName="verify_email_modal"   >
      <Modal.Body>
        <div className='row' style={{width:'100px',margin:'auto'}}>
                      <img src="assets/images/svgimges/Mentorz_logo_red.svg"  alt="Mentorz" />
         
        </div>
        <div className='row'>
         <p className='v_title1 mt-3'>Verify your email adress </p>
         <p className='v_title2 mt-4'>We sent an email to <span style={{textDecoration:'underline'}}>{props?.UserEmail}</span> to make sure that you own it.
          Please check your inbox,then follow the instructions to finish setting up your
          Mentorz account. </p>
        </div>
        
      </Modal.Body>
      <Modal.Footer className='mt-4 cursor-pointer' >
        <div className='row mt-2  '>
          <div className="col-6 profilecancelcss ">
           <button type="button"  className="btn view_mymentee_desc" onClick={()=>verifyEmail.cancel()} style={{width:"120px"}}>  Cancel </button>
          </div>
          <div className="col-6 ">
           <button type="button" className="btn view_mymentee_prfl" onClick={()=>verifyEmail.resendEmail()} style={{width:"120px"}} > Resend Email </button>
          </div>
          </div>
      </Modal.Footer>
    </Modal> 
    </>
    
    )})

export default CustomeModal;
