import React, { Component } from "react";
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
export class BusinessSignIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {}
        }
        this.handleChange = this.handleChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }
    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields });
    }

    submitForm(e) {
        console.log(this.validateForm());
        e.preventDefault();
        if (this.validateForm()) {
            let fields = this.state.fields;
            this.setState({ fields: fields });
            console.log(this.state);
            alert("Form submitted");
        }
    }

    validateForm() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (!fields["FirstName"]) {
            formIsValid = false;
            errors["e_FirstName"] = "*Please enter your username.";
        }

        if (typeof fields["FirstName"] !== "undefined") {
            if (!fields["FirstName"].match(/^[a-zA-Z ]*$/)) {
                formIsValid = false;
                errors["e_FirstName"] = "*Please enter alphabet characters only.";
            }
        }

        if (!fields["BusinessName"]) {
            formIsValid = false;
            errors["e_BusinessName"] = "*Please enter your businessName.";
        }

        if (!fields["Email"]) {
            formIsValid = false;
            errors["e_Email"] = "*Please enter your email-ID.";
        }

        if (typeof fields["Email"] !== "undefined") {
            //regular expression for email validation
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["Email"])) {
                formIsValid = false;
                errors["e_Email"] = "*Please enter valid email-ID.";
            }
        }

        if (!fields["Password"]) {
            formIsValid = false;
            errors["e_Password"] = "*Please enter your password.";
        }

        //if (typeof fields["Password"] !== "undefined") {
        //    if (!fields["Password"].match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
        //        formIsValid = false;
        //        errors["Password"] = "*Please enter secure and strong password.";
        //    }
        //}
        this.setState({
            errors: errors
        });
        return formIsValid;
    }


render() {
const { FirstName, LastName, Email, BusinessName, Password, ConfirmPassword } = this.state.fields;
const { e_FirstName, e_BusinessName,e_LastName, e_Email, e_Password, e_ConfirmPassword } = this.state.errors;
return (
    <div className="section-sign">
    <div className="container">
      <div className="sign-bg">
        <div className="row">
          <div className="col-12">
          <div className="row">
          <div className="col-6">
          </div>
          <div className="col-12 mt-3">
            <div className="loginbtn">
              <div className="custsignStyle">
               <NavLink tag={Link} to="/login" style={{color: "#000000"}}>Login</NavLink>
              </div>
              <div className="actcss"> Already have an account?</div>
            </div>
            </div>
          
          </div>
          </div>
          <form onSubmit={this.submitForm} className="mb-3">
          <div className="offset-md-2 col-md-8 col-12">
            <div className="sign-title-1 mt-4">Sign up</div>
            <div className="mb-2">
              <input type="text" 
              className="form-control form-control-lg" 
              name="FirstName" 
              value={FirstName || ''} 
              onChange={this.handleChange}
              placeholder="First Name" />
            </div>
            <div className="errorMsg">{e_FirstName}</div>
            <div className="mb-2">
            <input type="text"
              className="form-control form-control-lg"
              name="LastName" 
              value={LastName || ''}
              onChange={this.handleChange} 
              placeholder="Last Name" />
            </div>
            <div className="errorMsg">{e_LastName}</div>

            <div className="mb-2">
            <input type="text" 
              className="form-control form-control-lg"
              name="BusinessName"
              value={BusinessName || ''} 
              onChange={this.handleChange} 
              placeholder="Business Name" />
            </div>
            <div className="errorMsg">{e_BusinessName}</div>

            <div className="mb-2">
            <input type="email" 
              className="form-control form-control-lg" 
              name="Email" 
              value={Email || ''} 
              onChange={this.handleChange} 
              placeholder="Email" />
            </div>
            <div className="errorMsg">{e_Email}</div>

            <div className="mb-2">
            <input type="password" 
             className="form-control form-control-lg"
             name="Password" 
             value={Password || ''} 
             onChange={this.handleChange}
             placeholder="Password" />
            </div>
            <div className="errorMsg">{e_Password}</div>

            <div className="mb-2">
            <input type="password"
             className="form-control form-control-lg" 
             name="ConfirmPassword" 
             value={ConfirmPassword || ''} 
             onChange={this.handleChange} 
             placeholder="Confirm Password" />
            </div>
            <div className="errorMsg">{e_ConfirmPassword}</div>

          </div>
          <div className="d-grid gap-2">
                <div className="button-container button-customcss">  <input type="submit" value="Sign Up" style={{width:"30%",height:"49px",borderRadius:"50px"}} />  </div>
            </div>
        </form>
          <div className="col-12 mb-3 mt-3"> <div className="sign-alternate"></div>  </div>
          <div className="row mb-3 offset-md-2 mt-4 ">
            <div className="col-4">
                <button type="button"  className="btn btn-block btn-social btn-facebook" > 
                    <img src="assets/images/svgimges/facebook.svg"  className="vector-image"  alt="" /> 
                    <span className="span_button">Facebook</span>  
                </button>
            </div>
            <div className="col-4">
                <button type="button" className="btn btn-block btn-social btn-linkdein" > 
                    <img src="assets/images/svgimges/linkedin.svg"   className="vector-image" alt="" /> 
                    <span className="span_button">Linkedin</span> 
                </button> 
            </div>

             
         </div>

         <div className="row">
               <div className="offset-md-2 col-md-12 ">
               <div className="forgot-password mt-4">
                  <p> <span className="text_color ml-4">By using  Mentorz you agree with the </span> <span className="textcolor"> terms of service and privacy </span></p>
                </div> 
              </div>             
               </div>

              <div className="row">
               <div className="offset-md-2 col-md-12 ">
               <div className="policy_style mt-2 mb-5"> <span className="textcolor ml-4 ">policy</span></div>
                </div>
                </div>
        </div>
      </div>
    </div>
  </div>
);
}
}