import React, { Component } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "red" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "red" }}
            onClick={onClick}
        />
    );
}
export class Layer5 extends Component { 

  constructor(props) {        
      super(props);
    this.state={  
        currentSlide: 0,
        currentSlideEnter:0,
        TMentorz:[
            {
                id:1,                           
                image:'assets/images/homepage/Match.png',                    
            },
            {
                id:2,             
                image:'assets/images/homepage/Learn.png',
            },
            {
                id:3,                              
                image:'assets/images/homepage/Grow.png',
            },
          ],
        EnterMentorz: [
            {
                id: 1,
                image: 'assets/images/homepage/EMatch.png',
            },
            {
                id: 2,
                image: 'assets/images/homepage/ELearn.png',
            },
            {
                id: 3,
                image: 'assets/images/homepage/EGrow.png',
            },
        ], 
          settings:{
             dots: true,
             infinite: false,
             speed: 500,
             slidesToShow: 1,
             slidesToScroll: 1,             
              centerMode: true,
              beforeChange: this.beforeSlideChange,
              afterChange: this.afterChange,
              nextArrow: <SampleNextArrow />,
              prevArrow: <SamplePrevArrow />

        },
            settingsEnter: {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            beforeChange: this.beforeSlideChange,
            afterChange: this.afterChangeEnter,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />

        }
           

     }            
     
    }
  


    beforeSlideChange = (current, next) => {
     /* this.setState({ currentSlide: next });*/
    };
    afterChange = (current, next) => {
        //alert(this.currentSlide);
        this.setState({ currentSlide: current });
        //this.state.currentSlide = current;
        
    };
    afterChangeEnter = (current, next) => {
       
        this.setState({ currentSlideEnter: current });
    };
    render() {
        return (
            <div className="">
                <div className="section-main container">
                <div className='row'>
                  <div className='col-lg-12 col-sm-12'>
                    <div className='headingformula'>Our formula: <span className='mentorzcolor'>Your Success</span></div>
                  {/*          <div className='textcenter p-20'>*/}
                  {/*<span className='forind'>For individuals</span>*/}
                  {/*<span className='standline'> | </span>*/}
                  {/*<span className='forenterprise'>For Enterprises</span>*/}

                  {/*          </div>*/}

                  </div>
                    </div>
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#individuals" type="button" role="tab" aria-controls="individuals" aria-selected="true">For individuals</button>
                        </li>
                        <li class="standline" style={{ margin:"10px" }}> | </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link forenterprise" id="profile-tab" data-bs-toggle="tab" data-bs-target="#Enterprises" type="button" role="tab" aria-controls="Enterprises" aria-selected="false">For Enterprises</button>
                        </li>
                       
                    </ul>
                    <div class="spacemargin"></div>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="individuals" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                            <div className='row'>
                                <div className='col-lg-4 col-sm-12'>
                                    <Slider {...this.state.settings}>
                                        {this.state.TMentorz.map((result, index) => {
                                            return (
                                                <div key={index}>
                                                    <img src={result.image} className='primarybtn matchimg' alt="mentor_1" />
                                                </div>
                                            )
                                        })}
                                    </Slider>

                                </div>
                                <div className='col-lg-1 col-sm-12'></div>
                                <div className='col-lg-7 col-sm-12'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-sm-12'>
                                            <div id="individuals0" className={this.state.currentSlide === 0 ? 'matchheading activelable' : 'matchheading'}   >
                                                1.   Match
                                            </div>
                                            <div class="matchdesc">
                                                Discover mentors and access mentorship opportunities based on their expertise, shared interests, and values.
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-sm-12'>
                                            <div id="individuals1" className={this.state.currentSlide === 1 ? 'learnheading activelable' : 'learnheading'} >
                                                2.   Learn
                                            </div>
                                            <div class="matchdesc">
                                                Gain insights from industry experts, receive guidance, embark on self-discovery, and track your personal growth.
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-sm-12'>
                                            <div id="individuals2"  className={this.state.currentSlide === 2 ? 'learnheading activelable' : 'learnheading'}>
                                                3.   Grow
                                            </div>
                                            <div class="matchdesc">
                                                Reflect on mentorship insights, celebrate achievements, and set your sights on fresh goals.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="Enterprises" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
                            <div className='row'>
                                <div className='col-lg-4 col-sm-12' style={{ marginTop:"70px" }} >
                                    <Slider {...this.state.settingsEnter}>
                                        {this.state.EnterMentorz.map((result, index) => {
                                            return (
                                                <div key={index}>
                                                    <img src={result.image} className='primarybtn matchimg' style={{ width:"100%" }} alt="mentor_1" />
                                                </div>
                                            )
                                        })}
                                    </Slider>

                                </div>
                                <div className='col-lg-1 col-sm-12'></div>
                                <div className='col-lg-7 col-sm-12'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-sm-12'>
                                            <div className={this.state.currentSlideEnter === 0 ? 'matchheading activelable' : 'matchheading'} >
                                                1.   Match
                                            </div>
                                            <div class="matchdesc">
                                                Build custom programs, manage matching process among employees and groups, and customize your branding.
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-sm-12'>
                                            <div  className={this.state.currentSlideEnter === 1 ? 'learnheading activelable' : 'matchheading'} >
                                                2.   Learn
                                            </div>
                                            <div class="matchdesc">
                                                Scale with mentorship journey templates, measure employee progress along journey stages, and invite external VIP mentors.
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-sm-12'>
                                            <div className={this.state.currentSlideEnter === 2 ? 'learnheading activelable' : 'learnheading'}  >
                                                3.   Grow
                                            </div>
                                            <div class="matchdesc">
                                                Measure mentorship achievements in dashboards, connect insights (AI) to rewards and development discussions.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
               <div className='spacemargin'></div>
               
        </div>
      </div>
        );
    }
}
