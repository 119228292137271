import type from '../types';

let matchActions = {
   setmat_tab:function(tab) {
    return { 
      type: type.SET_MATCH_TAB,
      tab
    }
  },
  getRecommendedMentors:function(){
    return{
      type:type.GET_RECOMMENDED_MENTORS,
    }
   },
  getAllMentors:function(payload){
    return{
      type:type.GET_ALL_MENTORS,
      payload
    }
   },
   setAllMentors:function(payload){
    return{
      type:type.SET_ALL_MENTORS,
      payload
    }
   },
  setRecommendedMentors:function(payload){
    return{
      type:type.SET_RECOMMENDED_MENTORS,
      mentors:payload
    }
   },
   getManageRequest:function(){
    return{
      type:type.GET_MANAGE_REQUEST,
    }
   },
   setManageRequest:function(payload){
    return{
      type:type.SET_MANAGE_REQUEST,
      payload
    }
   },
   getImageURL:function(){
    return{
      type:type.GET_IMAGE_URL,
    }
   },


   //Mentor Details
   getMentor_detail:function(payload){
    return{
      type:type.GET_MENTOD_DETAIL,
      payload
    }
   },
   setMentor_detai:function(payload){
    return{
      type:type.SET_MENTOD_DETAIL,
      payload
    }
   },
   createMentor_request:function(payload){
    return{
      type:type.CREATE_MENTOR_REQUEST,
      payload
    }
   },
   setCreateMentor_request:function(payload){
    return{
      type:type.SET_MENTOR_REQUEST_STATUS,
      payload
    }
   },
   updateMentor_request:function(payload){
    return{
      type:type.UPDATE_MENTOR_REQUEST,
      payload
    }
   },
   setUpdateMentor_request:function(payload){
    return{
      type:type.SET_UPDATE_MENTOR_REQUEST,
      payload
    }
   },
}


export default matchActions;