import { call, put, takeEvery } from 'redux-saga/effects';

import type from '../types';
import Api from './api_config';
import loaderActions from '../actions/loader';
import ScheduleActions from '../actions/scheduleAction';


let usrdetail=localStorage.getItem('login_data');
usrdetail=JSON.parse(usrdetail);

function* fetchScheduleList(req) {
   try {
      const detail = yield call(Api.schedule.getschdulelist ,usrdetail.id,req.params);
      const {data}=detail;
      yield put(ScheduleActions.setScheduleList(data));
     
   } catch (e) {
    yield put(loaderActions.toggleLoader(false));
   }  
}

function* updateAvailibility(req) {
   debugger
   try {
      const detail = yield call(Api.schedule.updateAvailibility ,usrdetail.id,req.params);
      const {data}=detail;
      debugger
      alert(data)
      yield put(ScheduleActions.upadateAvailabilityResult(data[0]));
     
   } catch (e) {
    yield put(loaderActions.toggleLoader(false));
   }  
}



function* getSchedule() {
   yield takeEvery('GET_SCHEDULE_LIST', fetchScheduleList);
   yield takeEvery('UPDATE_AVAILABILITY', updateAvailibility);

  
}

export default getSchedule;