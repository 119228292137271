import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom'
export class Layer7 extends Component {
    


    render() {
        return ( 
           <div className='m-top-50'>
            <div className="container">
                    <div className='row'>
                    <div className='signuparea' style={{ marginTop: "30px", width: "64%" }} >
                    <div className='col-lg-12 col-sm-12 signheader1'>
                    Ready to embark on your mentorship journey? 
                    </div>
                    <div className='col-lg-12 col-sm-12 signheader2'>
                    Sign up today and unlock the path to professional and personal growth with <strong>Mentorz.</strong>
                    </div>
                            <div className='col-lg-12 col-sm-12 textcenter m-top-32'>
                                <Link to="/sign-up" style={{ marginTop:"10px" }} className='btn btn-lg btnsignup'>Sign up now
                        </Link>
                    </div>
                  </div>   
                </div>         
            </div>  
        </div>             
        );
    }
}
