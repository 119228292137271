import React from 'react';

import { Footer } from './Footer';

const Contact = () => {


    return (
        <>
            <div className='signup_background'>

                {/*<div className='signup_header1'>*/}
                {/*    <img src="assets/images/Mentorz_logo_red.png" alt="image" className="signup_img" />*/}
                {/*    <img src="assets/images/Mentorz_word_red.png" alt="image" className="sign_header_word" />*/}
                {/*</div>*/}

                <label id="lblinvalid" style={{ marginLeft: "40px" }} className="text-danger textright" ></label>

                <div className='signup contact'>
                    <div className='temp'>
                        <div className='emailVerify'>
                            <div class="fs-4 font-medium  text-dark" >Contact Us</div>
                            <p>Please, send your questions to   <a class="red" href="mailto:support@mentorz.com?subject=Mentorz%20support">support@mentorz.com</a>.</p>
                          
                        </div>
                    </div>
                </div>
                 <Footer />
            </div>
        </>
    );
}

export default Contact;
