
import { Route, Routes} from 'react-router-dom';
import { Container } from 'reactstrap';
import AppRoutes from './AppRoutes';
import ProtectedHeader from '../components/Protected-Header';
import { Footer } from '../components/Footer';
 const ProtectedRoute = () => {
    return (
      <>
       <ProtectedHeader/>
       <Container >
        <Routes>
            {AppRoutes.map((route, index) => {
                const { element, ...rest } = route;
                return <Route key={index} {...rest} element={element} />;
              })}
        </Routes>
       </Container>
<Footer/>
      </>
    )
  }

  export default ProtectedRoute;