import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom'
export class Layer6 extends Component { 


    render() {
        return (
            <div className="layer6bg">
            <div className="container">
            <div className="row">
            <div className="col-lg-4 col-sm-12">
              <div className="companytitle">Find mentors from the world's leading companies</div>
            </div>
            <div className="col-lg-8 col-sm-12 text-center client-logo-mb">
              <img className="client-logos" src="assets/images/homepage/bank_of_america-logo 1.png" alt="logo_1" />
              <img className="client-logos" src="assets/images/homepage/logos_google.png" alt="logo_2" />
              <img className="client-logos" src="assets/images/homepage/Microsoft.png" alt="logo_3 " />
              <img className="client-logos" src="assets/images/homepage/Apple Logo.png" alt="logo_4" />
              <img className="client-logos" src="assets/images/homepage/expedia-logo 2.png" alt="logo_5" />
            </div>
          </div>
        </div>
      </div>
        );
    }
}
