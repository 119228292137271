import axios from "axios";
import { authHeader } from "../auth-header";

const apiUrl = `https://jsonplaceholder.typicode.com/users`;
//apply base url for axios

const API = process.env.REACT_APP_API;

const Service = {
  authn: {
    getSignInAuth: async function (req) {
      var params = {
        url: API + "/User/Add",
        method: "POST",
        timeout: 0,
        headers: authHeader(),
        data: JSON.stringify({
          userId: null,
          firstName: req.FirstName,
          lastName: req.LastName,
          countryCode: req.countryCode,
          phoneNumber: req.PhoneNumber,
          emailAddress: req.Email,
          password: req.Password,
          age: 0,
          photoId: "",
          facebookLink: "https://www.facebook.com/",
          linkedInLink: "https://www.linkedin.com/in/",
          bioData: "",
          designation: "",
          organization: "",
          isOrganizationUser: false,
          role: 0,
          school: "",
          city: "",
          country: " ",
          yearsOfExperience: 0,
          mentorshipRate: 0,
          timezone: "",
          educationLevel: "",
          degree: "",
          headline: "",
          loginUserId: null,
          userInterestIds: [],
          userValueIds: [],
          userExpertiseIds: [],
          isFacebookUser: false,
          isLinkedInUser: false,
          isAppleUser: false,
          isMicroosoftUser: false,
          stripeCustomerId: "",
          stripeAccountId: "",
          pubNubUserId: "",
          isAdminUser: false,
          socialAuthKey: "",
        }),
      };
      try {
        const response = await axios.request(params);
        return response;
      } catch (error) {
        return error;
      }
    },

    updateSignInUser: async function (req) {
      var params = {
        url: API + "/User/Update",
        method: "POST",
        timeout: 0,
        headers: authHeader(),
        data: JSON.stringify({
          userId: req.userId,
          firstName: req.firstName,
          lastName: req.lastName,
          countryCode: req.countryCode,
          phoneNumber: req.phoneNumber,
          emailAddress: req.emailAddress,
          password: req.password,
          age: req.age,
          photoId: req.photoId,
          facebookLink: req.facebookLink,
          linkedInLink: req.linkedInLink,
          bioData: req.bioData,
          designation: req.designation,
          organization: req.organization,
          isOrganizationUser: req.isOrganizationUser,
          role: req.role,
          school: req.school,
          city: req.city,
          country: req.country,
          yearsOfExperience: req.yearsOfExperience,
          mentorshipRate: req.mentorshipRate,
          timezone: req.timezone,
          educationLevel: req.educationLevel,
          degree: req.degree,
          headline: req.headline,
          loginUserId: req.userId,
          userInterestIds: [],
          userValueIds: [],
          userExpertiseIds: [],
          isFacebookUser: req.isFacebookUser,
          isLinkedInUser: req.isLinkedInUser,
          isAppleUser: req.isAppleUser,
          isMicroosoftUser: req.isMicroosoftUser,
          stripeCustomerId: req.stripeCustomerId,
          stripeAccountId: req.stripeAccountId,
          pubNubUserId: req.pubNubUserId,
          isAdminUser: req.isAdminUser,
        }),
      };
      try {
        const response = await axios.request(params);
        return response;
      } catch (error) {
        return error;
      }
    },

    verifyEmailAdress: async function (req) {
      var settings = {
        url: API + "/User/VerifyEmail?userId=" + req.userId,
        method: "get",
        headers: authHeader(),
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        return error;
      }
    },

    validateEmailAdress: async function (req) {
      var settings = {
        url: API + "/User/Validate?email=" + req.Email,
        method: "get",
        headers: authHeader(),
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        return error;
      }
    },

    getLoginAuth: async function (req) {
      var params = {
        url: API + "/User/Authenticate",
        method: "POST",
        timeout: 0,
        headers: authHeader(),
        data: JSON.stringify({
          username: req.Email,
          password: req.Password,
          isNormalLogin: true,
        }),
      };
      try {
        const response = await axios.request(params);
        return response;
      } catch (error) {
        return error;
      }
    },
    getStripeCustomerId: async function (req) {
      var params = {
        url: API + "/Stripe/Customer/Add",
        method: "POST",
        timeout: 0,
        headers: authHeader(),
        data: JSON.stringify({
          firstName: req.firstName,
          lastName: req.lastName,
          description: "",
          email: req.emailAddress,
        }),
      };
      try {
        const response = await axios.request(params);
        return response;
      } catch (error) {
        return error;
      }
    },
  },
  fileUpload: {
    uploadFile: async function (selectedFile) {
      const formData = new FormData();
      const authUser = JSON.parse(localStorage.getItem("login_data"));
      formData.append("Files", selectedFile.payload);
      formData.append("type", "image/png");
      var settings = {
        url: API + `/File/UploadFiles`,
        method: "post",
        timeout: 0,
        headers: {
          Authorization: "Bearer " + authUser.token,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },
    fileValidate: async function (selectedFile) {
      var settings = {
        url: API + "/File/IsValid?fileName=" + selectedFile.name,
        method: "get",
        headers: authHeader(),
      };
      try {
        const response = await axios.request(settings);

        return response;
      } catch (error) {
        throw error;
      }
    },
  },
  homeApi: {
    getUser_Mentor: async function (id, req) {
      var settings = {
        url: API + "/Home/Post/UserAndMentors/Get",
        method: "post",
        headers: authHeader(),
        data: JSON.stringify({
          userId: id,
          pageNumber: req.pageNumber,
          pageSize: req.pageSize,
        }),
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },
    addPost: async function (id, req) {
      var settings = {
        url: API + "/Home/Post/Add",
        method: "post",
        headers: authHeader(),
        data: JSON.stringify({
          postId: "string",
          posterId: id,
          postText: req.payload.postText,
          postImageId: req.payload.postImageId,
          postVideoId: req.payload.postVideoId,
          postAudioId: "string",
          shares: 0,
          likes: 0,
        }),
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },
    getMentorDetails: async function (id) {
      var settings = {
        url: API + "/Match/MentorDetails",
        method: "post",
        headers: authHeader(),
        data: JSON.stringify({
          userId: id, //"8a967900-1158-416a-a507-fab77f7c451b",
          loggedInUserId: id, // "8a967900-1158-416a-a507-fab77f7c451b"
        }),
      };

      try {
        const response = await axios.request(settings);

        return response;
      } catch (error) {
        throw error;
      }
    },
    likePost: async function (id, req) {
      var settings = {
        url: API + "/Home/Post/Like",
        method: "post",
        headers: authHeader(),
        data: JSON.stringify({
          postId: req.payload.postId,
          userId: id,
        }),
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },
    UndoLikePost: async function (id, req) {
      var settings = {
        url: API + "/Home/Post/UndoLike",
        method: "post",
        headers: authHeader(),
        data: JSON.stringify({
          postId: req.payload.postId,
          userId: id,
        }),
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },
    addCommnet: async function (req) {
      var settings = {
        url: API + "/Home/Post/Comment/Add",
        method: "POST",
        headers: authHeader(),
        data: JSON.stringify({
          postId: req.payload.postId,
          commenterId: req.payload.commenterId,
          commentText: req.payload.commentText,
        }),
      };
      try {
        const response = await axios.request(settings);

        return response;
      } catch (error) {
        throw error;
      }
    },
    getCommentList: async function (req) {
      var settings = {
        url: API + "/Home/Post/Comments/Get",
        method: "POST",
        headers: authHeader(),
        data: JSON.stringify({
          postId: req.payload.postId,
          pageNumber: req.payload.pageNumber,
          pageSize: req.payload.pageSize,
        }),
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },
  },
  match: {
    getRecommendedMentors: async function (id) {
      var settings = {
        url: API + "/Match/MatchDetails",
        method: "POST",
        timeout: 0,
        headers: authHeader(),
        data: JSON.stringify({
          userId: id,
        }),
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },
    getAllMentors: async function (id, req) {
      var settings = {
        url: API + "/Match/AllMentors",
        method: "POST",
        timeout: 0,
        headers: authHeader(),
        data: JSON.stringify({
          userId: id,
          pageNumber: req.pageNumber,
          pageSize: req.page_size,
        }),
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },
    getManagereq: async function (id) {
      var settings = {
        url: API + "/Match/AllMentorRequests?userId=" + id,
        method: "get",
        headers: authHeader(),
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },
    getMentorDetails: async function (id) {
      var settings = {
        url: API + "/Match/MentorDetails",
        method: "post",
        headers: authHeader(),
        data: JSON.stringify({
          userId: id, //"8a967900-1158-416a-a507-fab77f7c451b",
          loggedInUserId: id, // "8a967900-1158-416a-a507-fab77f7c451b"
        }),
      };

      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },
    creteMentorRequest: async function (req, userID) {
      var settings = {
        url: API + "/Match/MentorRequest/Create",
        method: "post",
        headers: authHeader(),
        data: JSON.stringify({
          userId: userID,
          mentorId: req.payload.mentorId,
          status: 1,
          expertiseId: req.payload.expertiseId,
          goal: req.payload.goal,
        }),
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },
    UpdateMentorRequest: async function (req, status) {
      var req = req.payload.req;
      var settings = {
        url: API + "/Match/MentorRequest/UpdateStatus",
        method: "post",
        headers: authHeader(),
        data: JSON.stringify({
          id: req.id,
          userId: req.userId,
          mentorId: req.mentorId,
          status: status,
        }),
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },
  },
  journal: {
    getMentorlist: async function (req) {
      var settings = {
        url: API + "/Journal/Mentors",
        method: "post",
        headers: authHeader(),
        data: JSON.stringify({
          userId: req,
        }),
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },
    getMentorDetail: async function (req, userID) {
      var settings = {
        url: API + "/Journal/MentorDetails",
        method: "post",
        headers: authHeader(),
        data: JSON.stringify({
          userId: userID,
          mentorId: req.payload.mentorId,
          mentorshipId: req.payload.mentorshipId,
        }),
      };
      try {
        const response = await axios.request(settings);

        return response;
      } catch (error) {
        throw error;
      }
    },
    getMenteelist: async function (req) {
      var settings = {
        url: API + "/Journal/Mentees",
        method: "post",
        headers: authHeader(),
        data: JSON.stringify({
          userId: req,
        }),
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },

    getMenteeDetail: async function (req, userID) {
      var settings = {
        url: API + "/Journal/MenteeDetails",
        method: "post",
        headers: authHeader(),
        data: JSON.stringify({
          userId: userID,
          menteeId: req.payload.menteeId,
          mentorshipId: req.payload.mentorshipId,
        }),
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },
    updateMentorGoal: async function (req) {
      var settings = {
        url: API + "/Match/MentorRequest/Update",
        method: "post",
        headers: authHeader(),
        data: JSON.stringify({
          id: req.id,
          userId: req.userId,
          expertiseId: req.expertiseId,
          goal: req.goal,
        }),
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },
    getAvailability: async function (req) {
      var settings = {
        url: API + "/Schedule/User/Availability/Get",
        method: "post",
        headers: authHeader(),
        data: JSON.stringify({
          userId: req.payload.userId,
          month: req.payload.month,
          year: req.payload.year,
        }),
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },

    bookMentorSession: async function (req, userId) {
      var settings = {
        url: API + "/Journal/Session/Book",
        method: "post",
        headers: authHeader(),
        data: JSON.stringify({
          menteeId: userId,
          mentorshipSession: {
            sessionId: "",
            mentorshipId: req.mentorshipId,
            availabilityId: req.availabilityId,
            title: "new connection",
            sessionType: 1,
            startDateTime: req.startDateTime,
            endDateTime: req.endDateTime,
            // "actualStartDateTime": req.startDateTime,
            // "actualEndDateTime": req.endDateTime,
            sessionStatus: 1,
            note: "Hello",
            sessionGoals: "sessionGoals",
          },
        }),
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },

    acceptMentorSession: async function (req, userId) {
      debugger;
      var settings = {
        url: API + "/Journal/Session/Accept",
        method: "post",
        headers: authHeader(),
        data: JSON.stringify({
          sessionId: req.SessionId,
          userId: userId,
        }),
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },
    rejectMentorSession: async function (req, userId) {
      var settings = {
        url: API + "/Journal/Session/Reject",
        method: "post",
        headers: authHeader(),
        data: JSON.stringify({
          sessionId: req.SessionId,
          userId: userId,
        }),
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },
    rescheduleMentorSession: async function (req, userId) {
      var settings = {
        url: API + "/Journal/Session/Reschedule",
        method: "post",
        headers: authHeader(),
        data: JSON.stringify({
          sessionId: "string",
          availabilityId: "string",
          userId: "string",
          title: "string",
          sessionType: 1,
          startDateTime: "2023-08-29T09:01:38.620Z",
          endDateTime: "2023-08-29T09:01:38.620Z",
          actualStartDateTime: "2023-08-29T09:01:38.620Z",
          actualEndDateTime: "2023-08-29T09:01:38.620Z",
          sessionStatus: 1,
          sessionGoals: "string",
        }),
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },
    getSessionSatusbyId: async function (sessionId) {
      var settings = {
        url: API + "/Journal/Session/GetById?sessionId=" + sessionId,
        method: "get",
        headers: authHeader(),
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },
    subscribChannelId: async function (userID, req) {
      var settings = {
        url: API + "/User/Chat/Add",
        method: "post",
        headers: authHeader(),
        data: JSON.stringify({
          firstUserId: userID, //Loggedin user id
          secondUserId: req.secondUserId, //"368acc1d-be6a-405a-8809-e76230611117"
        }),
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },
  },
  profile: {
    getUerProfiledetail: async function (id) {
      var settings = {
        url: API + "/User/GetProfileById?UserId=" + id,
        method: "get",
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },
    getProfileInterests: async function () {
      var settings = {
        url: API + "/Onboarding/InterestMaster/Get",
        method: "get",
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },
    getProfiletrendingInterests: async function () {
      var settings = {
        url: API + "/Onboarding/TrendingInterest/Get",
        method: "get",
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
      },
    getProfileExperties: async function () {
      var settings = {
        url: API + "/Onboarding/ExpertiseMaster/Get",
        method: "get",
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },
    getProfiletrendingExperties: async function () {
      var settings = {
        url: API + "/Onboarding/TrendingExpertise/Get",
        method: "get",
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },
      getProfiletrendingValues: async function () {
          var settings = {
              url: API + "/Onboarding/TrendingValue/Get",
              method: "get",
          };
          try {
              const response = await axios.request(settings);
              return response;
          } catch (error) {
              throw error;
          }
      },

    getProfileValues: async function () {
      var settings = {
        url: API + "/Onboarding/ValueMaster/Get",
        method: "get",
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },
    updateUserProfile: async function (req2, userID) {
      const params = {
        userId: userID,
        firstName: req2.firstName,
        lastName: req2.lastName,
        countryCode: req2.countryCode,
        phoneNumber: req2.phoneNumber,
        emailAddress: req2.emailAddress,
        password: "",
        age: req2.age,
        photoId: req2.photoId,
        facebookLink: req2.facebookLink,
        linkedInLink: req2.linkedInLink,
        bioData: req2.bioData,
        designation: req2.designation,
        organization: req2.organization,
        timezone: req2.timezone, //"(GMT+5:30) Chennai, Kolkata, Mumbai, New Delhi (India Standard Time)",//profile.timezone,
        isOrganizationUser: req2.isOrganizationUser,
        role: req2.role,
        school: req2.school,
        city: req2.city,
        country: req2.country,
        yearsOfExperience: req2.yearsOfExperience,
        mentorshipRate: req2.mentorshipRate,
        educationLevel: req2.educationLevel,
        degree: req2.degree,
        headline: req2.headline,
        loginUserId: userID,
        userInterestIds: req2.userInterestIds.map((el) => {
          return el.id;
        }), //  req2.userInterestIds,
        userValueIds: req2.userValueIds.map((el) => {
          return el.id;
        }),
        userExpertiseIds: req2.userExpertiseIds.map((el) => {
          return el.id;
        }),
        isFacebookUser: req2.isFacebookUser,
        isLinkedInUser: req2.isLinkedInUser,
        isAppleUser: req2.isAppleUser,
        isMicroosoftUser: true,
        stripeCustomerId: req2.stripeCustomerId,
        stripeAccountId: req2.stripeAccountId,
        pubNubUserId: req2.pubNubUserId,
        isAdminUser: false,
        socialAuthKey: "",
        videoId: "",
      };
      var settings = {
        url: API + "/User/Update",
        method: "post",
        headers: authHeader(),
        data: JSON.stringify(params),
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },
    getSelfDiscoverySurvey: async function (userId, mentorshipId) {
      var settings = {
        url:
          API +
          "/Journal/SelfDiscoverySurvey/Get?userId=" +
          userId +
          "&mentorshipId=" +
          mentorshipId,
        method: "get",
        headers: authHeader(),
      };
      try {
        const response = await axios.request(settings);

        return response;
      } catch (error) {
        throw error;
      }
    },

    updateSelfDiscoverySurvey: async function (selfDiscover, userDetails) {
      if (selfDiscover.SelfDiscovertDetail.userId == null) {
        const params = {
          userId: userDetails.userId,
          eI_Value: selfDiscover.SelfDiscovertDetail.eI_Value,
          sN_Value: selfDiscover.SelfDiscovertDetail.sN_Value,
          tF_Value: selfDiscover.SelfDiscovertDetail.tF_Value,
          pJ_Value: selfDiscover.SelfDiscovertDetail.pJ_Value,
          selfDiscoveryText: selfDiscover.SelfDiscovertDetail.selfDiscoveryText,
        };

        var settings = {
          url: API + "/Journal/SelfDiscoverySurvey/Create",
          method: "post",
          headers: authHeader(),
          data: JSON.stringify(params),
        };
      } else {
        const params = {
          selfDiscoverySurveyId:
            selfDiscover.SelfDiscovertDetail.selfDiscoverySurveyId,
          userId: selfDiscover.SelfDiscovertDetail.userId,
          eI_Value: selfDiscover.SelfDiscovertDetail.eI_Value,
          sN_Value: selfDiscover.SelfDiscovertDetail.sN_Value,
          tF_Value: selfDiscover.SelfDiscovertDetail.tF_Value,
          pJ_Value: selfDiscover.SelfDiscovertDetail.pJ_Value,
          selfDiscoveryText: selfDiscover.SelfDiscovertDetail.selfDiscoveryText,
        };

        var settings = {
          url: API + "/Journal/SelfDiscoverySurvey/Update",
          method: "post",
          headers: authHeader(),
          data: JSON.stringify(params),
        };
      }
      try {
        const response = await axios.request(settings);

        return response;
      } catch (error) {
        throw error;
      }
    },
    createAccountLink: async function (req) {
      var settings = {
        url: API + "/Stripe/Customer/Connect/CreateAccountLink",
        method: "post",
        headers: authHeader(),
        data: JSON.stringify({
          stripeCustomerId: req.payload.stripeCustomerId,
          country: "US",
          email: req.payload.emailAddress,
          currency: "usd",
        }),
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },
    getPaymentMenthodList: async function (req) {
      var stripeCustomerId = "cus_OQJk8HadB6d0Vq"; //'cus_OQJk8HadB6d0Vq';//
      var settings = {
        url:
          API +
          "/Stripe/Customer/PaymentMethod/List?stripeCustomerId=" +
          stripeCustomerId,
        method: "get",
        headers: authHeader(),
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },
    addPaymentMenthod: async function (req) {
      debugger;
      var stripeCustomerId = "cus_OQJk8HadB6d0Vq";
      var settings = {
        url: API + "/Stripe/Customer/PaymentMethod/Add",
        method: "post",
        headers: authHeader(),
        data: JSON.stringify({
          stripeCustomerId: stripeCustomerId,
          paymentMethodCardOptions: {
            cvc: req.payload.cvv,
            expMonth: req.payload.expMonth,
            expYear: req.payload.expYear,
            number: req.payload.number,
            token: "",
          },
        }),
      };
      try {
        const response = await axios.request(settings);
        debugger;
        return response;
      } catch (error) {
        throw error;
      }
    },
  },
  schedule: {
    getschdulelist: async function (userID, req) {
      var settings = {
        url: API + "/Schedule/Get",
        method: "post",
        headers: authHeader(),
        data: JSON.stringify({
          userId: userID,
          month: req.month,
          year: req.year,
        }),
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },
    updateAvailibility: async function (userID, req) {
      var settings = {
        url: API + "/Schedule/User/Availability/Create",
        method: "post",
        headers: authHeader(),
        data: JSON.stringify({
          userId: userID,
          availabilities: [
            {
              startDateTime: req.startDateTime,
              endDateTime: req.endDateTime,
            },
          ],
        }),
      };
      try {
        const response = await axios.request(settings);
        return response;
      } catch (error) {
        throw error;
      }
    },
  },

  getFile: async function (id) {
    var settings = {
      url: API + "/File/GetFileUri?fileName=" + id,
      method: "get",
      headers: authHeader(),
    };
    try {
      const response = await axios.request(settings);

      return response;
    } catch (error) {
      throw error;
    }
  },
};

export default Service;
