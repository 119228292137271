import { call,select, put, takeEvery } from 'redux-saga/effects';

import type from '../types';
import Api from './api_config';
import loaderActions from '../actions/loader';

import ProfileActions from '../actions/proFileActons';
import AlertService from '../../Services/Alert';

let usrdetail=localStorage.getItem('login_data');
usrdetail=JSON.parse(usrdetail);

function* fetchprofileDetail(action) {
   try {
      const detail = yield call(Api.profile.getUerProfiledetail ,usrdetail.id);
      const {data}=detail;
      localStorage.setItem("user_detail", JSON.stringify(data));
      yield put(ProfileActions.setUserProfileDetail(data));
     
   } catch (e) {
    yield put(loaderActions.toggleLoader(false));
   }  
}


function* fetchprofileInterests(action) {
   try {
      const prfInterests = yield call(Api.profile.getProfileInterests);
      const {data}=prfInterests;
      
      yield put(ProfileActions.setProfileInterests(data))
     // yield put(actions.setUsers(data));
   } catch (e) {
    yield put(loaderActions.toggleLoader(false));
   }  
}

function* fetchprofileTrendingInterests(action) {
   try {
      const prfInterests = yield call(Api.profile.getProfiletrendingInterests);
      const {data}=prfInterests;
      data.map((el)=>{
        return el.status=false;
      })
      yield put(ProfileActions.setProfileTrendingInterests(data))

   } catch (e) {
    yield put(loaderActions.toggleLoader(false));
   }
}
function* fetchprofileExperties(action) {
   try {
      const prfInterests = yield call(Api.profile.getProfileExperties);
      const {data}=prfInterests;
      yield put(ProfileActions.setProfileExpertise(data))

   } catch (e) {
    yield put(loaderActions.toggleLoader(false));
   }
}

function* fetchprofileTrendingExperties(action) {
   try {
      const prfInterests = yield call(Api.profile.getProfiletrendingExperties);
      const {data}=prfInterests;
      
      yield put(ProfileActions.setProfileTrendingExpertise(data))

   } catch (e) {
    yield put(loaderActions.toggleLoader(false));
   }
}




function* fetchprofileTrendingValues(action) {
    try {
        const prfValues = yield call(Api.profile.getProfiletrendingValues);
        const { data } = prfValues;

        yield put(ProfileActions.setProfileTrendingValues(data))

    } catch (e) {
        yield put(loaderActions.toggleLoader(false));
    }
}
function* fetchprofileValues(action) {
   try {
      const prfInterests = yield call(Api.profile.getProfileValues);
      const {data}=prfInterests;
      
      yield put(ProfileActions.setProfileValues(data)) 

   } catch (e) {
      
    yield put(loaderActions.toggleLoader(false));
   }
}

export const userProfile_detail=(state=>state.proFileReducers.userProfile_detail);

function* updateUserProfile(req) {
   

   const _detail=yield select(userProfile_detail);
   try {
      const profile = yield call(Api.profile.updateUserProfile,_detail,usrdetail.id);
      const {data}=profile;
     // alert(data.message)
      AlertService._success(data.message).then((response) => {
         if(response.isConfirmed){
         }
      })

      // if(){
      //    dispatch(ProfileActions.createPymentAccount(userDetail))
      // }
      // setTimeout(() => { alert('This will run after 1 second!') }, 10000);

   } catch (e) {
      
    yield put(loaderActions.toggleLoader(false));
   }
}


function* fetchSelfdiscoverServey(req) {
   try {
      const prfInterests = yield call(Api.profile.getSelfDiscoverySurvey,usrdetail.id,usrdetail.id);
      const {data}=prfInterests;
      console.log(data)
      yield put(ProfileActions.setSelfDiscoverySurvey(data));
   } catch (e) {
    yield put(loaderActions.toggleLoader(false));
   }
}

function* createAccountLink(req) {
   try {
      const prfInterests = yield call(Api.profile.createAccountLink,req);
      const {data}=prfInterests;
      const URL=decodeURIComponent(data.url);
      window.open(URL);
      yield put(loaderActions.toggleLoader(false))
      
   } catch (e) {
    yield put(loaderActions.toggleLoader(false));
   }
}


function* updateSelfDiscoveryDetail(req) {
    const _detail = yield select(userProfile_detail);
    try {
        // _detail = yield select(req.payload);
        const prfInterests = yield call(Api.profile.updateSelfDiscoverySurvey, req.payload, _detail);
        const { data } = prfInterests;
        console.log(data)
       // yield put(ProfileActions.setSelfDiscoverySurvey(data));
    } catch (e) {
        yield put(loaderActions.toggleLoader(false));
    }
}

function* fetchPaymentMenthods(req) {
   try {
      const methodList = yield call(Api.profile.getPaymentMenthodList,req);
      const {data}=methodList;
      yield put(ProfileActions.SetPaymentMethodList(data));
      yield put(loaderActions.toggleLoader(false));
   } catch (e) {
    yield put(loaderActions.toggleLoader(false));
   }
}

function* addPaymentMenthods(req) {
   try {
      const methods = yield call(Api.profile.addPaymentMenthod,req);
      const {data}=methods;
      debugger
   } catch (e) {
    yield put(loaderActions.toggleLoader(false));
   }
}


function* getProfileInterest() {
   yield takeEvery('GET_USER_PROFILE_DETAIL', fetchprofileDetail);
   yield takeEvery(type.GET_PROFILEINTEREST, fetchprofileInterests);
   yield takeEvery(type.GET_PROFILETRENDINGINTEREST, fetchprofileTrendingInterests);
   yield takeEvery(type.GET_PROFILEXPERTIES, fetchprofileExperties);
   yield takeEvery(type.GET_PROFILETRENDINGEXPERTIES, fetchprofileTrendingExperties);
   yield takeEvery(type.GET_PROFILEVALUES, fetchprofileValues);
    yield takeEvery(type.UPDATE_USER_PROFILE_DETAIL, updateUserProfile);
    yield takeEvery(type.GET_PROFILETRENDINGVALUES, fetchprofileTrendingValues);
   yield takeEvery(type.GET_SELFDISCOVER_SURVEY, fetchSelfdiscoverServey);
   yield takeEvery(type.UPDATE_SELFDISCOVER_SURVEY, updateSelfDiscoveryDetail);
   yield takeEvery(type.CREATE_ACCOUNT_LINK, createAccountLink);
   yield takeEvery(type.GET_PAYMENT_TYPE, fetchPaymentMenthods);
   yield takeEvery(type.ADD_PAYMENT_METHOD, addPaymentMenthods);
}

export default getProfileInterest;