import homeactions from "../actions/homeActions";

const initialState = {
    mentors_detail:[],
    usermentors_detail:[],
    hasmore_usermentors:Boolean,
    pageNumber:1,
    Comment_list:[]
  }

const homeReducers = (state = initialState, action) => {
    let IMAGE_URL='https://mentorzstorageaccount1.blob.core.windows.net/mentorz/';
    const Convertdays=(postCreateDate)=>{
        var offset = new Date().getTimezoneOffset();
        var today = new Date();
        var dateToMilliseconds = today.getTime();
        var addedHours = dateToMilliseconds + (offset*60*1000);
        //This will add 5 hours to our time.
        var newDate = new Date(addedHours);

        var CreateDate = new Date(postCreateDate);
        var diffMs = ( newDate-CreateDate ); // milliseconds between now & Christmas
        var diffDays = Math.floor(diffMs / 86400000); // days
        var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
        var diffSec=Math.round(diffMs/ 1000); //Secods
       
       var result="";
       
       if(diffDays>0){
           result= diffDays + " days ago";
        }
        if(diffDays===0 && diffHrs>0 ){
            result= diffHrs + " hr ago";
        }
        if(diffDays===0 && diffHrs===0 && diffMins>0){
            result= diffMins + " min ago";
        }
        if(diffDays===0 && diffHrs===0 && diffMins===0 && diffSec>0){
            result=  " just now";
        }
       return result;
    }
    const SetImage=(imageId)=>{
        let image;
        if(imageId!=="" && imageId!==undefined && imageId!=="string" )
        {
          image=IMAGE_URL+ imageId;
        }
        else{
         image='assets/images/User.png';
        }
        return image
    }
    const media_Type=(data)=>{
      let _type='';
         if (data.postVideoId !=="" && data.postVideoId !==undefined && data.postVideoId !=="string"){ 
             data.postVideoId=IMAGE_URL+ data.postVideoId;
             _type='Video';
         }
         if(data.postImageId!=="" && data.postImageId!==undefined && data.postImageId!=="string") { 
             _type='Imgae';
         }
        
         if(data.postAudioId!=="" && data.postAudioId!==undefined && data.postAudioId!=="string") {console.log(data.postAudioId)
             _type='Audio'
         }
       return _type;
     }
    
    switch (action.type) {
        case  'SET_MENTORDETAIL':
          action.payload.photoId = SetImage(action.payload.photoId ); 
            return {
            ...state,
            mentors_detail: action.payload,
        }
        case  'SET_MENTORDETAIL_PHOTO':
          const photoId = SetImage(action.payload); 
            return {
            ...state,
            mentors_detail: {...state.mentors_detail,photoId:photoId},
        }
        case  'SET_USERMENTORDETAIL':
            const data=action.payload;
            const PostsList = [...data.mentorPosts||[], ...data.menteePosts||[],...data.otherPosts||[], ...data.followers||[]];
            PostsList.map((el)=>{ 
              el.postCreateDate=Convertdays(el.postCreateDate);
              el.mediaType= media_Type(el);
              el.posterPhotoId = SetImage(el.posterPhotoId);
              el.postImageId =  SetImage(el.postImageId);
              return el;
             })
            const hasmore_usermentors=PostsList.length===0?false:true;
            return {
              ...state,
            usermentors_detail: 
             [
                ...state.usermentors_detail,
                ...PostsList 
              ],
            pageNumber:action.payload.pageNumber,
            hasmore_usermentors:hasmore_usermentors
        }
        case  'UPDATE_USERMENTORDETAIL':
            return {
              ...state,
            usermentors_detail: action.payload
            
        }
        case  'SET_COMMENTE_LIST':
            action.payload.map((el)=>{ 
              el.commenterPhotoId = SetImage(el.commenterPhotoId);
              el.commentDate=Convertdays(el.commentDate);
              return el;
             })
            return {
              ...state,
              Comment_list: action.payload
        }
    
      default:
      return state
    }



}
export default homeReducers;