import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'; 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default function AvailabilityLoader() {
  return (
    <div>
           {
             [1,2,3,4,5,6].map((exp,index)=>{
                return(
                  <div className="row" key={index} >
                    <div className='col-8'><p style={{color:'black'}}><Skeleton/></p><p style={{fontSize:'15px'}} ><Skeleton/></p></div>
                    <div className='col-3'>
                    <Skeleton height={40} borderRadius={50}/>
                    </div>
                  </div>
                  )
              }) 
           }
    </div>
  )
}
