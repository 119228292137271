import React, { Component } from 'react';

export class LinkedIn extends Component {

    constructor(props) {
        super(props);
        this.state = {
            FirstName: '',
            LastName: ''
        }
    }

    render() {
        return (
            <>
                <div className='linkedin_background'>
                    {/* <div className='linkedin_header'>
                        <img src="img/Mentorz_logo_white.png" alt="" className="linkedin_img" />
                        <img src="img/Mentorz_word_white.png" alt="" className="linkedin_header_word" />
                    </div> */}

                    <label id="lblinvalid" style={{ marginLeft: "40px" }} className="text-danger textright" ></label>

                    <div className="linkedIn" style={{ width: "350px", textAlign: "center", padding: "2px", margin: "10px auto" }} >
                        <div className='row'>
                        <span style={{ fontSize: "24px", fontWeight: "bold" ,color:'black'}}>Expand your Horizons</span>
                        </div>
                        <div className='row mt-4'>
                            <span style={{ fontSize: "18px", marginLeft: "30px"}}>Match with mentors, learn and grow!</span>
                        </div>

{/* 
                        <p >
                            <span style={{ fontSize: "24px", fontWeight: "bold" ,color:'black'}}>Expand your Horizons</span>
                        </p>

                        <p  className='mt-4'> 
                            <span>Match with mentors, learn and grow!</span>
                        </p> */}
                    </div>
                </div>
            </>
        )
    }

}