import type from "../types";

let loaderActions = {
    toggleLoader:function(status) {
      return { 
        type: type.TOGGLE_LOADER,
        Payload:status
      }
    },
    toggleLoaderAvailability:function(status) {
      return { 
        type: type.AVILABILITY_TOGGLE,
        Payload:status
      }
    },
  }
  
  
  export default loaderActions;