import { all } from 'redux-saga/effects'
import AuthSaga from './authSaga';
import ProfileSags from './proFileSaga';
import MatchSagas from './matchSaga';
import HomeSagas from './homeSaga';
import JournalSaga from './journalSaga';
import ScheduleSaga from './scheduleSaga'


export default function* rootSaga() {
  yield all([
    AuthSaga(),
    ProfileSags(),
    MatchSagas(),
    HomeSagas(),
    JournalSaga(),
    ScheduleSaga()
 
  ])
}