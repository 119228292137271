
import Swal from "sweetalert2";  
const AlertService = {
    
  _success:function(msg){
    const result = Swal.fire(
      {
        title: msg,
        icon: 'success',
    }
    ); 
    return result;
  },
    _confirm: async function () {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You wan't be Log Out!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        });
        return result;
    },

    warning:async function(){  
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            return result;
           })
           return result;
     
    }
    ,
    getUser: function () {
     return Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      })
        .then(response => response)
        .catch((error) => {throw error})
        },
           
}

export default AlertService;