import type from "../types";

const initialState = {
    loading: Boolean,
    availability_loader:Boolean,
  } 

const loader = (state =initialState, action) => {
    switch (action.type) {
      case type.TOGGLE_LOADER:
        return {
          ...state,
          loading: action.Payload,
        }
        case type.AVILABILITY_TOGGLE:
          
          return {
            ...state,
            availability_loader: action.Payload,
          }

      default:
        return state
    }
}
export default loader;