
const Constants = {
    MentteProfilejourneyList:
        [
          {id:1,type:'profile' ,name:'Complete your profile', status:'active'},
          {id:2,type:'interests',name:'Pick your interests', status:'inactive'},
          {id:3,type:'values',name:'Select your values', status:'inactive'},
          {id:4,type:'selfdiscovery',name:'Describe your personality', status:'inactive'},
         /* {id:5,type:'payment', name: 'Setup payment', status: 'inactive' },*/
          {id:5,type:'review', name: 'Review & finish', status: 'inactive' },
        ],
    MentorProfilejourneyList:
        [
            { id: 1, type: 'profile', name: 'Complete your profile', status: 'active' },
            { id: 2, type: 'expertise', name: 'Share your expertise', status: 'inactive' },
            { id: 3, type: 'values', name: 'Select your values', status: 'inactive' },
            { id: 4, type: 'selfdiscovery', name: 'Describe your personality', status: 'inactive' },
            //{ id: 5, type: 'availability', name: 'Setup your availability', status: 'inactive' },
            //{ id: 6, type: 'mentorshipsrate', name: 'Setup mentorship rate', status: 'inactive' },
            { id: 5, type: 'review', name: 'Review & finish', status: 'inactive' },
        ],
    ProfilejourneyList:
        [
            { id: 1, type: 'profile', name: 'Complete your profile', status: 'active' },
            { id: 2, type: 'interests', name: 'Pick your interests', status: 'inactive' },
            { id: 3, type: 'expertise', name: 'Share your expertise', status: 'inactive' },
            { id: 4, type: 'values', name: 'Select your values', status: 'inactive' },
            { id: 5, type: 'selfdiscovery', name: 'Describe your personality', status: 'inactive' },
            //{ id: 6, type: 'availability', name: 'Setup your availability', status: 'inactive' },
            //{ id: 7, type: 'payment', name: 'Setup payment', status: 'inactive' },
            //{ id: 8, type: 'mentorshipsrate', name: 'Setup mentorship rate', status: 'inactive' },
            { id: 6, type: 'review', name: 'Review & finish', status: 'inactive' },
        ],
    paymentstages:
        [
            {
         id:0,
         title:'Personal Details',
         stage:'Personal_Details'
            },
            {
                id: 1,
                title: 'Add Your Bank',
                stage: 'Add_Your_Bank'
            },
            {
          id:2,
          title:'Add Credit/Debit Card',
          stage:'Add_Credit/Debit_Card',
            },
            {
          id:3,
          title:'Review & Finish',
          stage:'Review_Finish',
            },
        ],
    Self_discovery: [
        {
            'id': 1,
            'range': 1,
            'header': 'Where do you get your energy from?',
            'title1': 'Extravert(E)',
            'decriptipn1': 'I am energized by spending time with people in busy, active surroundings.',
            'align1': 'start',
            'title2': 'Introvert (I)',
            'description2': 'I am energized by spending quiet time alone or with a small group. ',
            'short': 'EI',
            'align2': 'right',
        },
        {
            'id': 2,
            'range': 2,
            'header': 'How do you take in information?',
            'title1': 'Intuition (N)',
            'decriptipn1': 'I am an abstract thinker focused on theories, patterns, and  explanations. I am often described as creative.',
            'align1': 'start',
            'title2': 'Sensing (S)',
            'description2': 'I focus on information I can directly see, hear, feel, taste or touch. I am often described as practical.',
            'short': 'SN',
            'align2': 'right',
        },
        {
            'id': 3,
            'range': 3,
            'header': 'How do you make decisions?',
            'title1': 'Feeling (F) ',
            'decriptipn1': 'I tend to make decisions with my heart, thinking about my values and how it affects others.',
            'align1': 'start',
            'title2': 'Thinking (T)',
            'description2': 'I tend to make decisions with my head, thinking about the most logical, reasonable choice.',
            'short': 'TF',
            'align2': 'right',
        },

        {
            'id': 4,
            'range': 4,
            'header': 'How do you deal with the world?',
            'title1': 'Judgeging (J) ',
            'decriptipn1': 'I appreciate structure and order, often liking things to be planned out. I don’t like last minute changes. ',
            'align1': 'start',
            'title2': 'Perceiving (P)',
            'description2': 'I appreciate flexibility and spontaneity, often leaving things open so I can change my mind.',
            'short': 'PJ',
            'align2': 'right',
        }
    ],
    BankList: [
        {
        id:0,
        imag:'assets/images/Banks/wells.png',
        title:''
        },
        {
        id:1,
        imag:'assets/images/Banks/wells.png',
        title:''
        },
        {
            id: 2,
            imag: 'assets/images/Banks/desktop.png',
            title: ''
        },
        {
            id: 3,
            imag: 'assets/images/Banks/usbank.png',
            title: ''
        },
        {
            id: 4,
            imag: 'assets/images/Banks/tdbank.png',
            title: ''
        },
        {
            id: 5,
            imag: 'assets/images/Banks/capitalone.png',
            title: ''
        },
        {
            id: 6,
            imag: 'assets/images/Banks/usaabank.png',
            title: ''
        },
        {
            id: 7,
            imag: 'assets/images/Banks/fifth.png',
            title: ''
        },
        {
            id: 8,
            imag: 'assets/images/Banks/huntington.png',
            title: ''
        },
        {
            id: 9,
            imag: 'assets/images/Banks/mtbank.png',
            title: ''
        },
        {
            id: 10,
            imag: 'assets/images/Banks/citizens.png',
            title: ''
        },
        {
            id: 11,
            imag: 'assets/images/Banks/svp.png',
            title: ''
        },
    ],
    RecommendedMentorz: [
        {
            id: 1,
            image: 'img/Ellipse 76.png',
            name: 'Jason Roy',
            title: '5 yrs exp',
            description: 'Lorem ipsum is simply dummy text of the printing.'
        },
        {
            id: 2,
            /* image:'img/Ellipse 76.png', */
            name: 'Jason Roy',
            title: '5 yrs exp',
            description: 'Lorem ipsum is simply dummy text of the printing.'
        },
        {
            id: 3,
            image: 'img/Ellipse 76.png',
            name: 'Jason Roy',
            title: '5 yrs exp',
            description: 'Lorem ipsum is simply dummy text of the printing. '
        },
        {
            id: 4,
            image: 'img/Ellipse 1.png',
            name: 'Jason Roy',
            title: '5 yrs exp',
            description: 'Lorem ipsum is simply dummy text of the printing.'
        },
        {
            id: 5,
            image: 'img/Ellipse 76.png',
            name: 'Jason Roy',
            title: '5 yrs exp',
            description: 'Lorem ipsum is simply dummy text of the printing.'
        },
        {
            id: 6,
            image: 'img/Ellipse 1.png',
            name: 'Jason Roy',
            title: '5 yrs exp',
            description: 'Lorem ipsum is simply dummy text of the printing.'
        },
        {
            id: 7,
            image: 'img/Ellipse 76.png',
            name: 'Jason Roy',
            title: '5 yrs exp',
            description: 'Lorem ipsum is simply dummy text of the printing.'
        },
        {
            id: 8,
            image: 'img/Ellipse 1.png',
            name: 'Jason Roy',
            title: '5 yrs exp',
            description: 'Lorem ipsum is simply dummy text of the printing.'
        },

    ],
    paymentCard: [
        {
            id: '1',
            icons: 'assets/images/card1.png',
            cardBrand: 'Credit/Debit card',
            "type": "card",
            "cardLast4": "",
            "currency": null,
            status: false,
        },
        // {
        //     id:'2',
        //     icons:'assets/images/Apple Pay.png',
        //     cardBrand:'Apple Pay',
        //     "type": "card",
        //     "cardLast4": "",
        //     "currency": null,
        //     status:false,
        // },

        // {
        //     id:'3',
        //     icons:'assets/images/GPay.png',
        //     cardBrand:'Google Pay',
        //     "type": "card",
        //     "cardLast4": "",
        //     "currency": null,
        //     status:false,
        // },
    ],
    Times: [
        {
            id: '0',
            hr: '12',
            min: '00'
        },
        {
            id: '2',
            hr: '12',
            min: '15'
        },
        {
            id: '3',
            hr: '12',
            min: '30'
        },
        {
            id: '4',
            hr: '12',
            min: '45'
        },
        {
            id: '5',
            hr: '01',
            min: '00'
        },
        {
            id: '',
            hr: '',
            min: ''
        },
        {
            id: '',
            hr: '',
            min: ''
        },
        {
            id: '',
            hr: '',
            min: ''
        },
        {
            id: '',
            hr: '',
            min: ''
        },
    ],
    country: [
        { id: 1, value: 'India', label: 'India', name: 'India', code: 'IN' },
        //{id:1, value: 'Afghanistan',label: 'Afghanistan',  name: 'Afghanistan', code: 'AF'}, 
        // {id:2, value: 'Afghanistan',label: 'Åland Islands', name: 'Åland Islands', code: 'AX'}, 
        // {id:3, value: 'Afghanistan',label: 'Albania', name: 'Albania', code: 'AL'}, 
        // {id:4, value: 'Afghanistan',label: 'Algeria', name: 'Algeria', code: 'DZ'}, 
        // {id:5, value: 'Afghanistan',label: 'American Samoa', name: 'American Samoa', code: 'AS'}, 
        // {id:6, value: 'Afghanistan',label: 'AndorrA', name: 'AndorrA', code: 'AD'}, 
        // {id:7, value: 'Afghanistan',label: 'Angola', name: 'Angola', code: 'AO'}, 
        // {id:0, name: 'Anguilla', code: 'AI'}, 
        // {id:0, name: 'Antarctica', code: 'AQ'}, 
        // {id:0, name: 'Antigua and Barbuda', code: 'AG'}, 
        // {id:0, name: 'Argentina', code: 'AR'}, 
        // {id:0, name: 'Armenia', code: 'AM'}, 
        // {id:0, name: 'Aruba', code: 'AW'}, 
        // {id:0, name: 'Australia', code: 'AU'}, 
        // {id:0, name: 'Austria', code: 'AT'}, 
        // {id:0, name: 'Azerbaijan', code: 'AZ'}, 
        // {id:0, name: 'Bahamas', code: 'BS'}, 
        // {id:0, name: 'Bahrain', code: 'BH'}, 
        // {id:0, name: 'Bangladesh', code: 'BD'}, 
        // {id:0, name: 'Barbados', code: 'BB'}, 
        // {id:0, name: 'Belarus', code: 'BY'}, 
        // {id:0, name: 'Belgium', code: 'BE'}, 
        // {id:0, name: 'Belize', code: 'BZ'}, 
        // {id:0, name: 'Benin', code: 'BJ'}, 
        // {id:0, name: 'Bermuda', code: 'BM'}, 
        // {id:0, name: 'Bhutan', code: 'BT'}, 
        // {id:0, name: 'Bolivia', code: 'BO'}, 
        // {id:0, name: 'Bosnia and Herzegovina', code: 'BA'}, 
        // {id:0, name: 'Botswana', code: 'BW'}, 
        // {id:0, name: 'Bouvet Island', code: 'BV'}, 
        // {id:0, name: 'Brazil', code: 'BR'}, 
        // {id:0, name: 'British Indian Ocean Territory', code: 'IO'}, 
        // {id:0, name: 'Brunei Darussalam', code: 'BN'}, 
        // {id:0, name: 'Bulgaria', code: 'BG'}, 
        // {id:0, name: 'Burkina Faso', code: 'BF'}, 
        // {id:0, name: 'Burundi', code: 'BI'}, 
        // {id:0, name: 'Cambodia', code: 'KH'}, 
        // {id:0, name: 'Cameroon', code: 'CM'}, 
        // {id:0, name: 'Canada', code: 'CA'}, 
        // {id:0, name: 'Cape Verde', code: 'CV'}, 
        // {id:0, name: 'Cayman Islands', code: 'KY'}, 
        // {id:0, name: 'Central African Republic', code: 'CF'}, 
        // {id:0, name: 'Chad', code: 'TD'}, 
        // {id:0, name: 'Chile', code: 'CL'}, 
        // {id:0, name: 'China', code: 'CN'}, 
        // {id:0, name: 'Christmas Island', code: 'CX'}, 
        // {id:0, name: 'Cocos (Keeling) Islands', code: 'CC'}, 
        // {id:0, name: 'Colombia', code: 'CO'}, 
        // {id:0, name: 'Comoros', code: 'KM'}, 
        // {id:0, name: 'Congo', code: 'CG'}, 
        // {id:0, name: 'Congo, The Democratic Republic of the', code: 'CD'}, 
        // {id:0, name: 'Cook Islands', code: 'CK'}, 
        // {id:0, name: 'Costa Rica', code: 'CR'}, 
        // {id:0, name: 'Cote D\'Ivoire', code: 'CI'}, 
        // {id:0, name: 'Croatia', code: 'HR'}, 
        // {id:0, name: 'Cuba', code: 'CU'}, 
        // {id:0, name: 'Cyprus', code: 'CY'}, 
        // {id:0, name: 'Czech Republic', code: 'CZ'}, 
        // {id:0, name: 'Denmark', code: 'DK'}, 
        // {id:0, name: 'Djibouti', code: 'DJ'}, 
        // {id:0, name: 'Dominica', code: 'DM'}, 
        // {id:0, name: 'Dominican Republic', code: 'DO'}, 
        // {id:0, name: 'Ecuador', code: 'EC'}, 
        // {id:0, name: 'Egypt', code: 'EG'}, 
        // {id:0, name: 'El Salvador', code: 'SV'}, 
        // {id:0, name: 'Equatorial Guinea', code: 'GQ'}, 
        // {id:0, name: 'Eritrea', code: 'ER'}, 
        // {id:0, name: 'Estonia', code: 'EE'}, 
        // {id:0, name: 'Ethiopia', code: 'ET'}, 
        // {id:0, name: 'Falkland Islands (Malvinas)', code: 'FK'}, 
        // {id:0, name: 'Faroe Islands', code: 'FO'}, 
        // {id:0, name: 'Fiji', code: 'FJ'}, 
        // {id:0, name: 'Finland', code: 'FI'}, 
        // {id:0, name: 'France', code: 'FR'}, 
        // {id:0, name: 'French Guiana', code: 'GF'}, 
        // {id:0, name: 'French Polynesia', code: 'PF'}, 
        // {id:0, name: 'French Southern Territories', code: 'TF'}, 
        // {id:0, name: 'Gabon', code: 'GA'}, 
        // {id:0, name: 'Gambia', code: 'GM'}, 
        // {id:0, name: 'Georgia', code: 'GE'}, 
        // {id:0, name: 'Germany', code: 'DE'}, 
        // {id:0, name: 'Ghana', code: 'GH'}, 
        // {name: 'Gibraltar', code: 'GI'}, 
        // {name: 'Greece', code: 'GR'}, 
        // {name: 'Greenland', code: 'GL'}, 
        // {name: 'Grenada', code: 'GD'}, 
        // {name: 'Guadeloupe', code: 'GP'}, 
        // {name: 'Guam', code: 'GU'}, 
        // {name: 'Guatemala', code: 'GT'}, 
        // {name: 'Guernsey', code: 'GG'}, 
        // {name: 'Guinea', code: 'GN'}, 
        // {name: 'Guinea-Bissau', code: 'GW'}, 
        // {name: 'Guyana', code: 'GY'}, 
        // {name: 'Haiti', code: 'HT'}, 
        // {name: 'Heard Island and Mcdonald Islands', code: 'HM'}, 
        // {name: 'Holy See (Vatican City State)', code: 'VA'}, 
        // {name: 'Honduras', code: 'HN'}, 
        // {name: 'Hong Kong', code: 'HK'}, 
        // {name: 'Hungary', code: 'HU'}, 
        // {name: 'Iceland', code: 'IS'}, 
        // {name: 'India', code: 'IN'}, 
        // {name: 'Indonesia', code: 'ID'}, 
        // {name: 'Iran, Islamic Republic Of', code: 'IR'}, 
        // {name: 'Iraq', code: 'IQ'}, 
        // {name: 'Ireland', code: 'IE'}, 
        // {name: 'Isle of Man', code: 'IM'}, 
        // {name: 'Israel', code: 'IL'}, 
        // {name: 'Italy', code: 'IT'}, 
        // {name: 'Jamaica', code: 'JM'}, 
        // {name: 'Japan', code: 'JP'}, 
        // {name: 'Jersey', code: 'JE'}, 
        // {name: 'Jordan', code: 'JO'}, 
        // {name: 'Kazakhstan', code: 'KZ'}, 
        // {name: 'Kenya', code: 'KE'}, 
        // {name: 'Kiribati', code: 'KI'}, 
        // {name: 'Korea, Democratic People\'S Republic of', code: 'KP'}, 
        // {name: 'Korea, Republic of', code: 'KR'}, 
        // {name: 'Kuwait', code: 'KW'}, 
        // {name: 'Kyrgyzstan', code: 'KG'}, 
        // {name: 'Lao People\'S Democratic Republic', code: 'LA'}, 
        // {name: 'Latvia', code: 'LV'}, 
        // {name: 'Lebanon', code: 'LB'}, 
        // {name: 'Lesotho', code: 'LS'}, 
        // {name: 'Liberia', code: 'LR'}, 
        // {name: 'Libyan Arab Jamahiriya', code: 'LY'}, 
        // {name: 'Liechtenstein', code: 'LI'}, 
        // {name: 'Lithuania', code: 'LT'}, 
        // {name: 'Luxembourg', code: 'LU'}, 
        // {name: 'Macao', code: 'MO'}, 
        // {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'}, 
        // {name: 'Madagascar', code: 'MG'}, 
        // {name: 'Malawi', code: 'MW'}, 
        // {name: 'Malaysia', code: 'MY'}, 
        // {name: 'Maldives', code: 'MV'}, 
        // {name: 'Mali', code: 'ML'}, 
        // {name: 'Malta', code: 'MT'}, 
        // {name: 'Marshall Islands', code: 'MH'}, 
        // {name: 'Martinique', code: 'MQ'}, 
        // {name: 'Mauritania', code: 'MR'}, 
        // {name: 'Mauritius', code: 'MU'}, 
        // {name: 'Mayotte', code: 'YT'}, 
        // {name: 'Mexico', code: 'MX'}, 
        // {name: 'Micronesia, Federated States of', code: 'FM'}, 
        // {name: 'Moldova, Republic of', code: 'MD'}, 
        // {name: 'Monaco', code: 'MC'}, 
        // {name: 'Mongolia', code: 'MN'}, 
        // {name: 'Montserrat', code: 'MS'}, 
        // {name: 'Morocco', code: 'MA'}, 
        // {name: 'Mozambique', code: 'MZ'}, 
        // {name: 'Myanmar', code: 'MM'}, 
        // {name: 'Namibia', code: 'NA'}, 
        // {name: 'Nauru', code: 'NR'}, 
        // {name: 'Nepal', code: 'NP'}, 
        // {name: 'Netherlands', code: 'NL'}, 
        // {name: 'Netherlands Antilles', code: 'AN'}, 
        // {name: 'New Caledonia', code: 'NC'}, 
        // {name: 'New Zealand', code: 'NZ'}, 
        // {name: 'Nicaragua', code: 'NI'}, 
        // {name: 'Niger', code: 'NE'}, 
        // {name: 'Nigeria', code: 'NG'}, 
        // {name: 'Niue', code: 'NU'}, 
        // {name: 'Norfolk Island', code: 'NF'}, 
        // {name: 'Northern Mariana Islands', code: 'MP'}, 
        // {name: 'Norway', code: 'NO'}, 
        // {name: 'Oman', code: 'OM'}, 
        // {name: 'Pakistan', code: 'PK'}, 
        // {name: 'Palau', code: 'PW'}, 
        // {name: 'Palestinian Territory, Occupied', code: 'PS'}, 
        // {name: 'Panama', code: 'PA'}, 
        // {name: 'Papua New Guinea', code: 'PG'}, 
        // {name: 'Paraguay', code: 'PY'}, 
        // {name: 'Peru', code: 'PE'}, 
        // {name: 'Philippines', code: 'PH'}, 
        // {name: 'Pitcairn', code: 'PN'}, 
        // {name: 'Poland', code: 'PL'}, 
        // {name: 'Portugal', code: 'PT'}, 
        // {name: 'Puerto Rico', code: 'PR'}, 
        // {name: 'Qatar', code: 'QA'}, 
        // {name: 'Reunion', code: 'RE'}, 
        // {name: 'Romania', code: 'RO'}, 
        // {name: 'Russian Federation', code: 'RU'}, 
        // {name: 'RWANDA', code: 'RW'}, 
        // {name: 'Saint Helena', code: 'SH'}, 
        // {name: 'Saint Kitts and Nevis', code: 'KN'}, 
        // {name: 'Saint Lucia', code: 'LC'}, 
        // {name: 'Saint Pierre and Miquelon', code: 'PM'}, 
        // {name: 'Saint Vincent and the Grenadines', code: 'VC'}, 
        // {name: 'Samoa', code: 'WS'}, 
        // {name: 'San Marino', code: 'SM'}, 
        // {name: 'Sao Tome and Principe', code: 'ST'}, 
        // {name: 'Saudi Arabia', code: 'SA'}, 
        // {name: 'Senegal', code: 'SN'}, 
        // {name: 'Serbia and Montenegro', code: 'CS'}, 
        // {name: 'Seychelles', code: 'SC'}, 
        // {name: 'Sierra Leone', code: 'SL'}, 
        // {name: 'Singapore', code: 'SG'}, 
        // {name: 'Slovakia', code: 'SK'}, 
        // {name: 'Slovenia', code: 'SI'}, 
        // {name: 'Solomon Islands', code: 'SB'}, 
        // {name: 'Somalia', code: 'SO'}, 
        // {name: 'South Africa', code: 'ZA'}, 
        // {name: 'South Georgia and the South Sandwich Islands', code: 'GS'}, 
        // {name: 'Spain', code: 'ES'}, 
        // {name: 'Sri Lanka', code: 'LK'}, 
        // {name: 'Sudan', code: 'SD'}, 
        // {name: 'Suriname', code: 'SR'}, 
        // {name: 'Svalbard and Jan Mayen', code: 'SJ'}, 
        // {name: 'Swaziland', code: 'SZ'}, 
        // {name: 'Sweden', code: 'SE'}, 
        // {name: 'Switzerland', code: 'CH'}, 
        // {name: 'Syrian Arab Republic', code: 'SY'}, 
        // {name: 'Taiwan, Province of China', code: 'TW'}, 
        // {name: 'Tajikistan', code: 'TJ'}, 
        // {name: 'Tanzania, United Republic of', code: 'TZ'}, 
        // {name: 'Thailand', code: 'TH'}, 
        // {name: 'Timor-Leste', code: 'TL'}, 
        // {name: 'Togo', code: 'TG'}, 
        // {name: 'Tokelau', code: 'TK'}, 
        // {name: 'Tonga', code: 'TO'}, 
        // {name: 'Trinidad and Tobago', code: 'TT'}, 
        // {name: 'Tunisia', code: 'TN'}, 
        // {name: 'Turkey', code: 'TR'}, 
        // {name: 'Turkmenistan', code: 'TM'}, 
        // {name: 'Turks and Caicos Islands', code: 'TC'}, 
        // {name: 'Tuvalu', code: 'TV'}, 
        // {name: 'Uganda', code: 'UG'}, 
        // {name: 'Ukraine', code: 'UA'}, 
        // {name: 'United Arab Emirates', code: 'AE'}, 
        // {name: 'United Kingdom', code: 'GB'}, 
        // {name: 'United States', code: 'US'}, 
        // {name: 'United States Minor Outlying Islands', code: 'UM'}, 
        // {name: 'Uruguay', code: 'UY'}, 
        // {name: 'Uzbekistan', code: 'UZ'}, 
        // {name: 'Vanuatu', code: 'VU'}, 
        // {name: 'Venezuela', code: 'VE'}, 
        // {name: 'Viet Nam', code: 'VN'}, 
        // {name: 'Virgin Islands, British', code: 'VG'}, 
        // {name: 'Virgin Islands, U.S.', code: 'VI'}, 
        // {name: 'Wallis and Futuna', code: 'WF'}, 
        // {name: 'Western Sahara', code: 'EH'}, 
        // {name: 'Yemen', code: 'YE'}, 
        // {name: 'Zambia', code: 'ZM'}, 
        // {name: 'Zimbabwe', code: 'ZW'} 
    ],
    stastes:
        [{ "code": "AN", "name": "Andaman and Nicobar Islands" },
        { "code": "AP", "name": "Andhra Pradesh" },
        { "code": "AR", "name": "Arunachal Pradesh" },
        { "code": "AS", "name": "Assam" },
        { "code": "BR", "name": "Bihar" },
        { "code": "CG", "name": "Chandigarh" },
        { "code": "CH", "name": "Chhattisgarh" },
        { "code": "DH", "name": "Dadra and Nagar Haveli" },
        { "code": "DD", "name": "Daman and Diu" },
        { "code": "DL", "name": "Delhi" },
        { "code": "GA", "name": "Goa" },
        { "code": "GJ", "name": "Gujarat" },
        { "code": "HR", "name": "Haryana" },
        { "code": "HP", "name": "Himachal Pradesh" },
        { "code": "JK", "name": "Jammu and Kashmir" },
        { "code": "JH", "name": "Jharkhand" },
        { "code": "KA", "name": "Karnataka" },
        { "code": "KL", "name": "Kerala" },
        { "code": "LD", "name": "Lakshadweep" },
        { "code": "MP", "name": "Madhya Pradesh" },
        { "code": "MH", "name": "Maharashtra" },
        { "code": "MN", "name": "Manipur" },
        { "code": "ML", "name": "Meghalaya" },
        { "code": "MZ", "name": "Mizoram" },
        { "code": "NL", "name": "Nagaland" },
        { "code": "OR", "name": "Odisha" },
        { "code": "PY", "name": "Puducherry" },
        { "code": "PB", "name": "Punjab" },
        { "code": "RJ", "name": "Rajasthan" },
        { "code": "SK", "name": "Sikkim" },
        { "code": "TN", "name": "Tamil Nadu" },
        { "code": "TS", "name": "Telangana" },
        { "code": "TR", "name": "Tripura" },
        { "code": "UK", "name": "Uttarakhand" },
        { "code": "UP", "name": "Uttar Pradesh" },
        { "code": "WB", "name": "West Bengal" }],

    SessionType: [{
        GetToKnowEachOther: 1,
        MentorshipSession1: 2,
        MentorshipSession2: 3,
        MentorshipSession3: 4,
        MentorshipSession4: 5,
        MentorshipSession5: 6,
        MentorshipSession6: 7,
        MentorshipSession7: 8,
        MentorshipSession8: 9,
        MentorshipSession9: 10,
        MentorshipSession10: 11,
        MentorshipSession11: 12,
        MentorshipSession12: 13,
        MentorshipSession13: 14,
        MentorshipSession14: 15,
        MentorshipSession15: 16,
        MentorshipSession16: 17,
        MentorshipSession17: 18,
        MentorshipSession18: 19,
        MentorshipSession19: 20,
        MentorshipSession20: 21,
        MentorshipSession21: 22,
        MentorshipSession22: 23,
        MentorshipSession23: 24,
        MentorshipSession24: 25,
        MentorshipSession25: 26,
        MentorshipSession26: 27,
        MentorshipSession27: 28,
        MentorshipSession28: 29,
        MentorshipSession29: 30,
        MentorshipSession30: 31
    }],

    SessionStatus: {
        Scheduled: 1,
        Accepted: 2,
        Rejected: 3,
        Completed: 4,
        Canceled: 5,
        Rescheduled: 6
    },
    DocumentIons:
        [
            {
                id: 0,
                icons: "bi bi-filetype-pdf bi--2xl",
                exptn: 'pdf'
            },
            {
                id: 1,
                icons: "bi bi-filetype-txt bi--2xl",
                exptn: 'txt'
            },
            {
                id: 3,
                icons: "bi bi-filetype-csv bi--5xl",
                exptn: 'csv'
            },
        ]

};
export default Constants;
