import React from 'react';
import { useSelector, useDispatch ,connect} from 'react-redux';
import authActions from '../../redux/actions/authActions';


export default function BusinesSignupDetails() {
    const dispatch = useDispatch();


    const submitForm=()=>{
        toggle('review_signup')
    }
    const toggle=(stage)=>{
        dispatch(authActions.setSignupStage(stage));
    }

return (
    <div className="sign-sbuscrp">
        <form onSubmit={submitForm }>
          <div className="offset-md-2 ">
            <p style={{float:'left'}} onClick={()=>toggle('sign_up_form')}>
                 <img src="assets/images/svgimges/back_button.svg"  alt="3-dots" />
            </p>
            <div className="sign-title-1 mt-4 mb-5 offset-2">Sign Up - Business Details</div>
            <div className='row col-md-12 col-12'>
            <div className='col col-6'>
                <div className='mb-2'>
                    <input type="text"
                    className="form-control form-control-lg" 
                    name="FirstName"
                    placeholder="Business Name" />
                </div>

                <div className='mb-2'>
                    <input type="text" 
                    className="form-control form-control-lg"
                    name="LastName" 
                    placeholder="Business Size" />
                </div>

                <div className='mb-2'>
                    <input type="email" 
                    className="form-control form-control-lg"
                    name="Email" 
                    placeholder="Business Type" />
                </div>
            </div>

            <div className='col col-6'>
                <img src="assets/images/svgimges/man and woman at work.svg" style={{width:'150px'}} className="vector-images"  alt="" /> 
            </div>
            </div>

          </div>
        <div className="button-container button-customcss"> 
           <input type="submit" value="Next" style={{height:"50px",width:'150px', borderRadius:"50px"}} /> 
        </div>
        </form>

        <div className="row mb-4">
               <div className="offset-md-2 col-md-12 ">
               <div className="forgot-password mt-4 ">
                  <p> <span className="text_color">By using  Mentorz you agree with the </span> <span className="textcolor"> terms of service and privacy policy</span></p>
                </div> 
              </div>             
        </div>
        <br/>
    </div>
  )
}
