
import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import authActions from "../../redux/actions/authActions";
import Loader from "../loader";
import loaderActions from "../../redux/actions/loader"
import {  toast } from 'react-toastify';
class CustomerLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            fields: {},
            errors: {},
            show:false,
            passwordType:"password"
        }
        this.handleChange = this.handleChange.bind(this);
        this.submitloginForm = this.submitloginForm.bind(this);
    }

    componentDidMount(){
        this.props.Loader(false);
    }
    handleChange(e) {
        this.setState({ errors: '' });
        this.props.setStatus('');
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields });
    }

    submitloginForm(e) {
        e.preventDefault();
        if (this.validateForm()) {
            let fields = this.state.fields;
            this.setState({ fields: fields });
            console.log(this.state);
            this.props.getCustemerLoginUser(fields);
        }
    }

    validateForm() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;


        if (!fields["Email"]) {
            formIsValid = false;
            errors["e_Email"] = "*Please enter your email-ID.";
        }

        if (typeof fields["Email"] !== "undefined") {
            //regular expression for email validation
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["Email"])) {
                formIsValid = false;
                errors["Email"] = "*Please enter valid email-ID.";
            }
        }

        if (!fields["Password"]) {
            formIsValid = false;
            errors["e_Password"] = "*Please enter your password.";
        }

        this.setState({
            errors: errors
        });
        return formIsValid;
    }
    togloader(bol){
        this.props.Loader(bol)
    }
    toggl_eye(){
      this.setState({show:!this.state.show});
    }


render() {
    const {Email,Password} =this.state.fields;
    const {e_Email,e_Password} =this.state.errors;
    return (
      <>
     
      <div className="cust-backgorund">
      <div className="loginbtn ">
              <div className="custsignStyle mb-5"><NavLink tag={Link} to="/sign-up" style={{color: "#000000"}}>Sign Up</NavLink></div>
              <div className="actcss mt-3"> You don’t have an account?</div>
            </div>
        <div className="Auth-form-container mt-5">
        <form className="Auth-form" onSubmit={this.submitloginForm}>
        <div className="row ">
        
        </div>
            <div className="customLogin-Auth-form-content">
                <p className="Auth-form-titleCust logintittle" >Login</p>
                <div className="col-12">
              <ul className="nav nav-tabs justify-content-center tabs-custom" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation" style={{ marginRight: "15px" }} >
                                        <button className="nav-link active border-nav " id="home-tab" data-bs-toggle="tab" data-bs-target="#individual-tab"
                                            type="button" role="tab" aria-controls="individual-tab" aria-selected="true" style={{ fontWeight: "500", paddingRight: "15px" }}>Individuals</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link " id="profile-tab" data-bs-toggle="tab" data-bs-target="#business-tab" 
                  type="button" role="tab" aria-controls="business-tab" aria-selected="false"style={{fontWeight:"500"}}>Businesses</button>
                </li>
              </ul>

              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="individual-tab" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                  
                <div className="input-group mb-2 mt-3" style={{width:"562px"}}>
                  <input type="email"
                   className="form-control form-control-lg" 
                   name="Email" 
                   value={Email || ''} 
                   onChange={this.handleChange} 
                   placeholder="Email"/>
                </div>
                <div className="errorMsg">{e_Email}</div>

              <div className="input-group  mb-2" style={{width:"562px"}}>
              <input type={this.state.show  ? "text" : "password"}
                      className="form-control form-control-lg" 
                      name="Password" 
                      value={Password || ''} 
                      onChange={this.handleChange} 
                      placeholder="Password"/>
                <div className="input-group-prepend  signeyeimg ">
                <span className="input-group-text input_icon " style={{backgroundColor:"rgb(232, 240, 254)"}} onClick={()=>this.toggl_eye()}> 
                {this.state.show ?<i className="fa fa-eye  fa-1x"  aria-hidden="true"></i>:<i className="fa fa-eye-slash  " style={{color:"#101230"}} aria-hidden="true"></i>}
                </span>
              </div>
              </div>
              
            <div className="errorMsg">{this.props.login_Status}</div>
                
                                        <p className="forgot-password text-right mt-2">
                                            Forgot password? <a className='forget_link' href="/ForgotPassword">Click Here</a>
                                        </p>
              <div className="button-container mb-3 " style={{marginTop:"65px"}} >   <input type="submit" value="Login" className="lgnbtn" style={{width:"165",height:"60px",borderRadius:"50px"}}/> </div>
                <br/>
                <div className="col-12">
                  <div className="sign-alternate sign-in-alt"></div>
               </div>
               
              <br/><br/><br/><br/>
                <div className="row mb-3">
                <div className="col-4">
                    <button type="button" className="btn btn-block btn-social btn-linkdein" style={{fontSize:"21px",fontWeight:"500",height:"48px"}}> <img src="assets/images/svgimges/linkedin.svg"   className="vector-images" alt="" /> <span className="span_button">Linkedin</span>  </button> 
                    </div>
                  <div className="col-4">
                    <button type="button"  className="btn btn-block btn-social btn-facebook" style={{fontSize:"21px",fontWeight:"500",height:"48px",color:"#1877F2"}}> 
                    <img src="assets/images/svgimges/facebook.svg"  className="vector-images"  alt="" /> <span className="span_button">Facebook</span>  </button>
                  </div>
    
                  <div className="col-4">
                    <button type="button" className="btn btn-block btn-social btn-apple" style={{fontSize:"21px",fontWeight:"500",height:"48px"}}> <img src="assets/images/svgimges/Vector.png"   className="vector-images" alt="" /> <span className="span_button">Apple</span>  </button> 
                    </div>
                    

                <div className=" col-12 forgot-password mt-4">
                  <p> 
                    <span className="text_color">By using  Mentorz you agree with the <span className="textcolor">   terms of service and privacy policy</span>  </span> 
                                  
                    </p>
                </div>  
                </div>
                </div>

                <div className="tab-pane fade" id="business-tab" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
                <div className="input-group mb-2 mt-3" style={{width:"562px"}}>
                  <input type="email"
                   className="form-control form-control-lg" 
                   name="Email" 
                   value={Email || ''} 
                   onChange={this.handleChange} 
                   placeholder="Email"/>
                </div>
                <div className="errorMsg">{e_Email}</div>

                <div className="input-group  mb-2" style={{width:"562px"}}>
              <input type={this.state.show  ? "text" : "password"}
                      className="form-control form-control-lg" 
                      name="Password" 
                      value={Password || ''} 
                      onChange={this.handleChange} 
                      placeholder="Password"/>
                <div className="input-group-prepend  signeyeimg ">
                <span className="input-group-text input_icon " style={{backgroundColor:"rgb(232, 240, 254)"}} onClick={()=>this.toggl_eye()}> 
                {this.state.show ?<i className="fa fa-eye  fa-1x"  aria-hidden="true"></i>:<i className="fa fa-eye-slash  " style={{color:"#101230"}} aria-hidden="true"></i>}
                </span>
              </div>
              </div>
            <div className="errorMsg">{this.props.login_Status}</div>
            
            <div className="text-right mb-4" style={{position:"absolute",marginLeft:"393px"}}> <p className="forgot_pass">Forgot your Password?</p> </div>
            <div className="d-grid gap-2">
            <div className="button-container mb-3 " style={{marginTop:"39px"}} >   <input type="submit" value="Login" className="lgnbtn" style={{width:"165px",height:"60px",borderRadius:"50px"}}/> </div>           </div>

           <div className="col-12 mb-4 mt-2">
                <div className="sign-alternate sign-in-alt"></div>
            </div>
           
            <div className="col-6 offset-md-3 mt-5 text-center" >
            <div className="row mb-3">
                <div className="col-6">
                    <button type="button" className="btn btn-block btn-social btn-linkdein" style={{fontSize:"21px",fontWeight:"500",height:"48px",width:"187px",marginLeft:"-84px"}}> <img src="assets/images/svgimges/linkedin.svg"   className="vector-images" alt="" /> <span className="span_button">Linkedin</span>  </button> 
                    </div>
                  <div className="col-6">
                    <button type="button"  className="btn btn-block btn-social btn-facebook" style={{fontSize:"21px",fontWeight:"500",height:"48px",color:"#1877F2",width:"187px"}}> 
                    <img src="assets/images/svgimges/facebook.svg"  className="vector-images"  alt="" /> <span className="span_button">Facebook</span>  </button>
                  </div>
    
                
                    </div>
                    <div className="row mb-3 mt-4">
                <div className="col-6">  
                <button type="button" className="btn btn-block btn-social btn-apple" style={{fontSize:"21px",fontWeight:"500",height:"48px",width:"187px",marginLeft:"-84px"}}> <img src="assets/images/svgimges/Vector.png"   className="vector-images" alt="" /> <span className="span_button">Apple</span>  </button> 

                </div>
                <div className="col-6">
                    <button type="button" className="btn btn-block btn-social btn-SSO" style={{width:"187px"}}> 
                    <img src="assets/images/svgimges/key.svg"   className="vector-image" alt="" /> 
                    <span className="span_button">SSO</span> 
                </button> 
                    </div>
                    </div>
            </div>

            <div className="mb-5 mt-4" >
              <p className="forgot-password  mb-4 ">
              By using  Mentorz you agree with <span className="textcolor">the terms of service and privacy policy</span> </p>
            </div>
               </div>
          </div>
       
          </div> 
          </div>

            </form>
            
        </div>
        </div>
        </>
    );
  }
}


  function _props(state) {
   return{
    loading:state.loader.loading,
    login_Status:state.authUser.login_status,
  }
}
const _dispatch = {
    getCustemerLoginUser:authActions.getCustemerLoginUser,
    getSign:authActions.getSigninUser,
    Loader:loaderActions.toggleLoader,
    setStatus:authActions.setloginStatus
  }

export default connect(_props,_dispatch)(CustomerLogin)

