import React from 'react'
import { useSelector, useDispatch ,connect} from 'react-redux';
import authActions from '../../redux/actions/authActions';
import CustomeModal from '../../Modals/CustomeModal';
import modalActions from '../../redux/actions/modal';

export default function SignupCompleted({formData}) {
    const dispatch = useDispatch();


    const userauthentication=(navigate)=>{
      dispatch(authActions.getCustemerLoginUser(formData,navigate));
    }

    const toggleModal=()=>{
      dispatch(modalActions.toggleModal('verify_emailadress-modal'));
      
    }

  return (

      <div className="center_div widthsize">
          <div className="" style={{textAlign:'center'}}>
            <div className="sign-title-1 mt-4 mb-4  ">Congratulations!</div>
              <div className=" mb-4" style={{ color: 'rgba(64, 66, 83, 1)', fontSize: '20px' }} >You’re signed up.Now check your email inbox to verify your account.</div>
            <img   src="assets/images/svgimges/signup_complete.svg"  alt="3-dots" />
          </div>
        <br/>
        <div className="row mb-2">
              <p className=" mb-4" style={{ textAlign: 'center' }} > <span className="logintext_color" style={{ color: 'rgba(64, 66, 83, 1)', fontSize: '20px' }}>After verification, go to login</span> </p>     
        </div>
        <div className="button-container" onClick={()=>userauthentication('Onboarding')}> 
           <input type="submit" value="Login" style={{height:"50px",width:'150px', borderRadius:"50px"}} /> 
        </div>
        {/*<div className="button-container button-customcss" onClick={()=>userauthentication('Home')}> */}
        {/*  <p>Skip for now</p>*/}
        {/*</div>*/}
        <br/>
    </div>
    
  )
}
