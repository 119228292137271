

const initialState = {
    match_tab: 'Recommended_mentors',//Initial component
    recommended_mentors:[],
    all_mentors:[],
    trending_mentors:[],
    send_requests:[],
    recieved_requests:[],
    manage_requests:[],
    mentor_detail:[],
    mentor_request:[],
    mentor_request_status:Boolean,
    mentor_request_updatestatus:Boolean,
    hasmore_mentor:Boolean,
    allmentor_pageNumber:1,
  }

const mathcReducers = (state = initialState, action) => {

  const Convertdays=(postCreateDate)=>{
    var offset = new Date().getTimezoneOffset();
    var today = new Date();
    var dateToMilliseconds = today.getTime();
    var addedHours = dateToMilliseconds + (offset*60*1000);
    //This will add 5 hours to our time.
    var newDate = new Date(addedHours);
    var CreateDate = new Date(postCreateDate);
    var diffMs = ( newDate-CreateDate ); // milliseconds between now & Christmas
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    var diffSec=Math.round(diffMs/ 1000); //Secods
   
   var result="";
   if(diffDays>0){
       result= diffDays + " days ago";
    }
    if(diffDays===0 && diffHrs>0 ){
        result= diffHrs + " hr ago";
    }
    if(diffDays===0 && diffHrs===0 && diffMins>0){
        result= diffMins + " min ago";
    }
    if(diffDays===0 && diffHrs===0 && diffMins===0 && diffSec>0){
        result=  " just now";
    }
   return result;
   }


    switch (action.type) {
      case 'SET_MATCH_TAB':
        return {
          ...state,
          match_tab: action.tab,
        }
      case 'SET_RECOMMENDED_MENTORS':
        return {
          ...state,
          recommended_mentors: action.mentors.recommendedMentors,
          //all_mentors:action.mentors.allMentors,
          trending_mentors:action.mentors.trendingMentors,
        }
      case 'SET_ALL_MENTORS':
        const hasmore=action.payload.length===0?false:true;
        return {
          ...state,
          all_mentors: 
          [
             ...state.all_mentors,
             ...action.payload 
           ],
           hasmore_mentor:hasmore,
           allmentor_pageNumber:action.payload.pageNumber

        }
      case 'SET_MANAGE_REQUEST':
        action.payload.map((el)=>{return el.requestCreateDate=Convertdays(el.requestCreateDate); })
       const Updated_sendrequests= action.payload.filter((el)=> el.requestType===1); 
       const Updated_recievedrequests= action.payload.filter((el)=>el.requestType===2);
        return {
          ...state,
          send_requests:Updated_sendrequests,
          recieved_requests:Updated_recievedrequests,
          manage_requests:action.payload,
        }
      case 'SET_MENTOD_DETAIL':  
      debugger
      return {
        ...state,
        mentor_detail:action.payload
      }
      case 'SET_MENTOR_REQUEST_STATUS':  
      return {
        ...state,
        mentor_request_status:action.payload
      }
      case 'SET_UPDATE_MENTOR_REQUEST':  
      return {
        ...state,
        mentor_request_updatestatus:action.payload
      }
      default:
      return state
    }



}
export default mathcReducers;