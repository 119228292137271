import React, { useEffect, useState} from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoutes from './Router/Protected-Route'; 
import PublicRoutes from './Router/Publict-Route';
import { Provider } from 'react-redux';
import store from './redux/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Footer } from './components/Footer';
import Offlinepage from './components/Offlinepage';
import './custom.css';
import './week.css';
export default function App() {
  // Online state
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener('online', handleStatusChange);

    // Listen to the offline status
    window.addEventListener('offline', handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [isOnline]);

    const isAuth = localStorage.getItem("LogInStatus");
    return (
      <>
      {isOnline ? 
        <Provider store={store}>
          <Routes>
           {
            (isAuth)
              ? <Route path="/*" element={<ProtectedRoutes />} />
              : <Route path="/*" element={<PublicRoutes />} />
            }
          </Routes> 
          {/* <Footer/> */}
          <ToastContainer position="top-center" />
        </Provider>
      : 
      <Offlinepage/>
      }
    </>
    );

}
