import  type from '../types';

const initialState = {
    schedule_list:[],
    scheduleaTab:'calenderView',
    groupschedule:'',
    availability_result:''
  }

const scheduleReducer = (state = initialState, action) => {
  const convertdate=(dd)=>{
    let date = new Date(dd);
    let year = date.getFullYear();
    let month = date.getMonth()+1;
    let dt = date.getDate();
    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }
    
    let sentDate = new Date(dd).toLocaleString('default', { weekday:'long', month: 'long', day:'numeric' });
    //let sentDatea = new Date(dd).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
   
   
     return sentDate;
  }    

  
    switch (action.type) {
        case 'SET_SCHEDULE_LIST':
            // action.schedulelist.map((el)=>
            // {
            //   el.title="First call w/ " + el.menteeFirstName +' '+ el.menteeLastName; 
            //   el.start=el.startDateTime;
            //   el.dategroup=convertdate(el.startDateTime);//'2023-3-25';
            //   el.end=el.endDateTime;
            //   el.backgroundColor= '#' + Math.floor(Math.random() * (256 * 256 * 256)).toString(16).padStart(6, '0');
            //   el.textColor= '#ffffff';
            //   el.startTime= new Date(el.startDateTime).toLocaleString('en-US', { hour: '2-digit', hour12: true });
            //   el.endTime=  new Date(el.endDateTime).toLocaleString('en-US', { hour: '2-digit', hour12: true });
            //   el.imageurl='https://mentorzstorageaccount1.blob.core.windows.net/mentorz/0000fed4-6de4-41ef-b2f0-c27fcff94ba7.png';
            //   return el;    
            // })

            action.schedulelist.map((el)=>
            {
              el.summary="First call w/ " + el.menteeFirstName +' '+ el.menteeLastName; 
              el.startAt=el.startDateTime;
              el.dategroup=convertdate(el.startDateTime);//'2023-3-25';
              el.endAt=el.endDateTime;
              el.color= '#' + Math.floor(Math.random() * (256 * 256 * 256)).toString(16).padStart(6, '0');
              el.textColor= '#ffffff';
              el.startTime= new Date(el.startDateTime).toLocaleString('en-US', { hour: '2-digit', hour12: true });
              el.endTime=  new Date(el.endDateTime).toLocaleString('en-US', { hour: '2-digit', hour12: true });
              return el;    
            })
           
            const groupBy = action.schedulelist.reduce((acc, currentValue) => {
              debugger
                let groupKey = currentValue.dategroup;
                if (!acc[groupKey]) {
                  acc[groupKey] = [];
                }
                acc[groupKey].push(currentValue);
                return acc;
              }, {});

            return {
              ...state,
              schedule_list: action.schedulelist,
              groupschedule:groupBy
            }
        case 'SET_SCHEDULE_TAB':
          return{
              ...state,
              scheduleaTab:action.tab
            }   
        case 'UPDATE_AVAILABILITY_RESULT':
          debugger
          return{
              ...state,
              availability_result:action.params
            }   
            
      default:
        return state
    }

}
export default scheduleReducer;