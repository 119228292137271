import Constants from "../constants"
const journeyList=Constants.ProfilejourneyList;
const discoveryData=Constants.Self_discovery;
const paymentTypes=Constants.paymentCard;
const initialState = {
    loading: false,
    userProfile_detail:[],
    userjournylist:[],//journeyList,
    profile_Journy: 'user_role',//Initial component
    profile_Payment: 'Setup_payment',//Initial component
    profile_Mentorsrate: 'Setup_payment',//Initial component
    profile_detail:'',
    profile_interests:[],
    profile_trendinginterests:[],
    profile_experties:[],
    profile_trendingexperties: [],
    profile_trendingvalues: [],
    profile_values:[],
    updated_user_detail:'',
    selfdiscoverysurvey_data:'',
    payment_types:[]

  }


const proFileReducers = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_USER_PROFILE_JOURNY_LIST':
        return {
          ...state,
          userjournylist: journeyList,
            }
        case 'SET_USER_PROFILE_JOURNY_LIST':
            return {
                ...state,
                userjournylist: action.payload,
            }
        case 'UPDATE_USER_PROFILE_JOURNY_LIST':
          // const updated=state.userjournylist.map((el)=>{
          //      if(el.id===action.payload.id){
          //       el.status='active';//el.status==='active'?'completed':'active';
          //      }
          //   return el
          // })

          const newList = state.userjournylist.map((item) => {
            if (item.id === action.payload.id) {
              const updatedItem = {
                ...item,
                status:item.status==='active'?'completed':'active'
              };
              return updatedItem;
            }
            debugger
            return item;
          });

          return {
            ...state,
            userjournylist: newList,
          }
  
      case 'TOGGLE_PROFILEJOURNEY_STEP':
        return {
          ...state,
          profile_Journy: action.profile_Journy,
        }
        case 'SET_PROFILEPMENTOR_RATE':
          return {
            ...state,
            profile_Mentorsrate: action.profile_Mentorsrate,
          }
        case 'TOGGLE_PROFILEPAYMENT_STEP':
          return {
            ...state,
            profile_Payment: action.profile_Payment,
          }
        case 'SETUP_PROFILEDETAIL':
          debugger
        return {
          ...state,
          userProfile_detail: {...state.userProfile_detail, ...action.payload}, 

            }
        case 'UPDATE_SELFDISCOVER_SURVEY':
            discoveryData.map((el) => {
                if (el.short === 'EI') { el.range = action.payload.SelfDiscovertDetail.eI_Value; el.Value = "eI_Value" }
                if (el.short === 'SN') { el.range = action.payload.SelfDiscovertDetail.sN_Value; el.Value = "sN_Value" }
                if (el.short === 'TF') { el.range = action.payload.SelfDiscovertDetail.tF_Value; el.Value = "tF_Value" }
                if (el.short === 'PJ') { el.range = action.payload.SelfDiscovertDetail.pJ_Value; el.Value = "pJ_Value" }
                return el
            })
            return {
                ...state,
                selfdiscoverysurvey_data: [action.payload, discoveryData],
            }
        case 'GET_PROFILEINTEREST':
        return {
          ...state,
        }
        case 'SET_PROFILEINTEREST':
        return {
            ...state,
            profile_interests: action.profile_interests,
        }
        case 'SET_PROFILETRENDINGINTEREST':  
          return {
              ...state,
              profile_trendinginterests: action.profile_trendinginterests,
          }
        case 'SET_PROFILEXPERTIES':
          return {
              ...state,
              profile_experties: action.profile_experties,
          }
        case 'SET_PROFILETRENDINGEXPERTIES':
        return {
            ...state, 
            profile_trendingexperties: action.profile_trendingexperties,
        }
        case 'SET_PROFILEVALUES':
        return {
            ...state, 
            profile_values: action.profile_values,
            }
        case 'SET_PROFILETRENDINGVALUES':
            return {
                ...state,
                profile_trendingvalues: action.profile_trendingvalues,
            }
        case 'SET_USER_PROFILE_DETAIL':
        return {
            ...state, 
            userProfile_detail: action.payload,
        }
        case 'UPDATE_USER_PROFILE_DETAIL':
          return {
              ...state, 
              updated_user_detail: state.profile_detail,
          }
        case 'SET_SELFDISCOVER_SURVEY':
        discoveryData.map((el)=>{
            if(el.short==='EI'){el.range=action.payload.result.eI_Value;el.Value="eI_Value"}
            if(el.short==='SN'){el.range=action.payload.result.sN_Value;el.Value="sN_Value"}
            if(el.short==='TF'){el.range=action.payload.result.tF_Value;el.Value="tF_Value"}
            if(el.short==='PJ'){el.range=action.payload.result.pJ_Value;el.Value="pJ_Value"}
          return el
        })
        return {
            ...state, 
            selfdiscoverysurvey_data: [action.payload, discoveryData],
        }
        case 'SET_PAYMENT_TYPE':
          var list=[];
          if(action.payload.length>0){
            action.payload.map((el)=>{
              el.icons='assets/images/card1.png';
              el.status=false;
              return el;
            })
            list=[...paymentTypes,...action.payload];
          }
          else{
            list=paymentTypes;
           
          }
          console.log(list)
              return {
                  ...state, 
                  payment_types:list,
              }
      default:
        return state
    }



}
export default proFileReducers;