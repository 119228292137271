import React, { Component } from 'react';
import { Link } from 'react-router-dom';
export class Footer extends Component {



    render() {
        return (
            <div className="section-footer">
                <div className="container" style={{ maxWidth:"92%"}}>
                    <div className="row">
                        <div className="col-6">
                            <div className="row g-0">
                                <div className="col-5">
                                    <a href="#!" className="footer-logo">
                                        <img className="img-fluid" src="assets/images/footerimages/Logo_white.png" alt="logo" />
                                    </a>
                                    <div className="footer-text">© Mentorz Inc 2024 <br />  All rights reserved</div>
                                </div>
                                <div className="col-7 textleft">
                                    <ul className="list-unstyled footer-links">
                                        <li>
                                            {/*<a href="/about">About</a>*/}
                                            <Link to="/about" className='linkbtn'>About</Link>
                                        </li>
                                        <li>
                                            {/*<a href="/contact-us">Contact us</a>*/}
                                            <Link to="/contact-us" className='linkbtn'>Contact us</Link>
                                        </li>
                                        <li>
                                            {/*<a href="/privacy-policy">Privacy policy</a>*/}
                                            <Link to="/privacy-policy" className='linkbtn'>Privacy policy</Link>
                                        </li>
                                        {/*<li>*/}
                                        {/*    <a href="#!">Blog</a>*/}
                                        {/*</li>*/}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 textright" style={{ paddingTop:"1.5%" }}>
                            <div className="row g-0">
                                <div className="col-8" style={{ verticalAlign:"middle" }}>
                                    <a href="https://apps.apple.com/in/app/mentorz-expand-your-horizons/id1234159570"><img src="assets/images/footerimages/app_store_small.png" className="img-fluid" style={{ paddingRight:'15px' }} alt="app store" /></a>
                                    <a href="https://play.google.com/store/apps/details?id=com.mentorz"><img src="assets/images/footerimages/google_play_small.png" className="img-fluid" alt="google play" /></a>
                                </div>
                                <div className="col-4">
                                    <a href="https://twitter.com/mymentorz" target="_blank" ><img src="assets/images/footerimages/twitter.png" className="img-fluid" style={{ paddingRight: '15px' }} alt="twitter" /></a>
                                    <a href="https://www.facebook.com/MyMentorz/" target="_blank" ><img src="assets/images/footerimages/facebook.png" className="img-fluid" style={{ paddingRight: '15px' }} alt="facebook" /></a>
                                    <a href="https://www.instagram.com/mymentorz/" target="_blank"><img src="assets/images/footerimages/instagramm.png" className="img-fluid" style={{ paddingRight: '15px' }} alt="instagram" /></a>
                                    <a href="https://www.linkedin.com/company/mymentorz" target="_blank"><img src="assets/images/footerimages/linkedin.png" className="img-fluid" alt="linkedin" /></a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                </div>
           

        );
    }
}
