import React, { Component, useMemo } from "react";
import { connect } from "react-redux";
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import authActions from "../../redux/actions/authActions";
import ToastMesage from "../../Services/Toast";
import Select from "react-select";
import countryCodes from "country-codes-list";
/*const myCountryCodesObject = countryCodes.customList(
  "countryCode",
  "[{countryCode}] {countryNameEn}: +{countryCallingCode}"
);*/
const myCountryCodesObject = countryCodes.customList(
  "countryCode",
  "+{countryCallingCode}"
);
class BusinessSignupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: { countryCode: "+1" },
      errors: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);

    console.log("mycountry codes", myCountryCodesObject);
  }
  componentDidMount() {
    console.log("default", this.state.fields["countryCode"]);
    if (this.props.formData !== "") {
      let fields = this.props.formData;
      //const options = useMemo(() => countryList().getData(), []);

      this.setState({
        fields: {
          FirstName: fields.FirstName,
          LastName: fields.LastName,
          Email: fields.Email,
          PhoneNumber: fields.PhoneNumber,
          Password: fields.Password,
          ConfirmPassword: fields.ConfirmPassword,
          countryCode: fields.countryCode,
        },
      });
    }
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    console.log("e.target.name", e.target.name);
    console.log("e.target.value", e.target.value);
    this.setState({ fields });
  }
  generateUUID() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }

  submitForm(e) {
    //this.toggle('business_signup_detail');
    console.log(this.validateForm());
    e.preventDefault();
    if (this.validateForm()) {
      let fields = this.state.fields;
      fields["UUID"] = this.generateUUID();
      this.setState({ fields: fields });
      console.log("fields details", this.state);
      //this.props.validateEmail(fields);
      this.props.eventcl(fields);
      this.toggle("review_signup");
    }
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["FirstName"]) {
      formIsValid = false;
      errors["e_FirstName"] = "*Please enter your username.";
    }

    if (typeof fields["FirstName"] !== "undefined") {
      if (!fields["FirstName"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["e_FirstName"] = "*Please enter alphabet characters only.";
      }
    }

    if (!fields["Email"]) {
      formIsValid = false;
      errors["e_Email"] = "*Please enter your email-ID.";
    }

    if (typeof fields["Email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["Email"])) {
        formIsValid = false;
        errors["e_Email"] = "*Please enter valid email-ID.";
      }
    }

    if (!fields["Password"]) {
      formIsValid = false;
      errors["e_Password"] = "*Please enter your password.";
    }

    //if (typeof fields["Password"] !== "undefined") {
    //    if (!fields["Password"].match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
    //        formIsValid = false;
    //        errors["Password"] = "*Please enter secure and strong password.";
    //    }
    //}
    if (
      typeof fields["Password"] !== "undefined" &&
      typeof fields["ConfirmPassword"] !== "undefined"
    ) {
      if (fields["Password"] !== fields["ConfirmPassword"]) {
        formIsValid = false;
        errors["e_ConfirmPassword"] = "Confirm password is Not Matched";
      }
    }

    this.setState({
      errors: errors,
    });

    return formIsValid;
  }

  toggle = (stage) => {
    this.props.setSignupStage(stage);
  };

  render() {
    const {
      FirstName,
      LastName,
      Email,
      PhoneNumber,
      Password,
      ConfirmPassword,
      countryCode,
    } = this.state.fields;
    const {
      e_FirstName,
      e_LastName,
      e_Email,
      e_PhoneNumber,
      e_Password,
      e_ConfirmPassword,
      e_countryCode,
    } = this.state.errors;
    return (
        <div className="center_div widthsize" >
        <div className="mt-4">
          <p
            style={{ float: "left" }}
            onClick={() => this.toggle("choose_subscription")}
          >
            <img src="assets/images/svgimges/back_button.svg" alt="3-dots" />
          </p>
          <div className="sign-title-1  mb-5 text-center">
            Sign Up- Mentorz Essentials
          </div>
        </div>
        <form onSubmit={this.submitForm}>
          <div className="offset-1 col-md-10 col-12">
            <div>
              <input
                type="text"
                className="form-control form-control-lg"
                name="FirstName"
                value={FirstName || ""}
                onChange={this.handleChange}
                placeholder="First Name"
              />
            </div>
            <div className="errorMsg">{e_FirstName}</div>

            <div>
              <input
                type="text"
                className="form-control form-control-lg"
                name="LastName"
                value={LastName || ""}
                onChange={this.handleChange}
                placeholder="Last Name"
              />
            </div>
            <div className="errorMsg">{e_LastName}</div>

            <div>
              <input
                type="email"
                className={`form-control form-control-lg ${
                  this.props.validate_email_respo !== "" ? "email_error" : ""
                }`}
                name="Email"
                value={Email || ""}
                onChange={this.handleChange}
                placeholder="Email"
              />
            </div>
            <div className="errorMsg">{e_Email}</div>

            <div className="input-group  mb-2" style={{ width: "562px" }}>
              <div className="input-group-prepend  signeyeimg ">
                <span className="input-group-text select-country ">
                  <select
                    id="countrycode"
                    name="countryCode"
                    value={countryCode || "+1"}
                    onChange={this.handleChange}
                  >
                    {Object.entries(myCountryCodesObject).map(
                      ([key, value]) => (
                        <option key={key} value={value}>
                          {value}
                        </option>
                      )
                    )}
                  </select>
                </span>
              </div>
              <input
                type="number"
                className="form-control form-control-lg"
                name="PhoneNumber"
                value={PhoneNumber || ""}
                onChange={this.handleChange}
                placeholder="Phone Number"
              />
            </div>

            <div>
              <input
                type="password"
                className="form-control form-control-lg"
                name="Password"
                value={Password || ""}
                onChange={this.handleChange}
                placeholder="Password"
              />
            </div>
            <div className="errorMsg">{e_Password}</div>

            <div>
              <input
                type="password"
                className="form-control form-control-lg"
                name="ConfirmPassword"
                value={ConfirmPassword || ""}
                onChange={this.handleChange}
                placeholder="Confirm Password"
              />
            </div>
            <div className="errorMsg">{e_ConfirmPassword}</div>
          </div>
          <div className="button-container button-customcss">
            <input
              type="submit"
              value="Next"
              style={{ height: "50px", width: "150px", borderRadius: "50px" }}
            />
          </div>
        </form>
        {/*<div className="col-12 mb-3 mt-4">*/}
        {/*  {" "}*/}
        {/*  <div className="sign-alternate "></div>{" "}*/}
        {/*</div>*/}
        <br />
        {/*<div className="row mb-3 mt-4 ms-4">*/}
        {/*  <div className="col-3">*/}
        {/*    <button type="button" className="btn   socilabutton_css">*/}
        {/*      <img*/}
        {/*        src="assets/images/svgimges/LinkedInS.svg"*/}
        {/*        className="vector-images"*/}
        {/*        alt=""*/}
        {/*      />*/}
        {/*    </button>*/}
        {/*  </div>*/}
        {/*  <div className="col-3 ms-4">*/}
        {/*    <button type="button" className="btn socilabutton_css">*/}
        {/*      <img*/}
        {/*        src="assets/images/svgimges/Facebooks.svg"*/}
        {/*        className="vector-images"*/}
        {/*        alt=""*/}
        {/*      />*/}
        {/*    </button>*/}
        {/*  </div>*/}
        {/*  <div className="col-3 ms-4 ">*/}
        {/*    <button type="button" className="btn  socilabutton_css">*/}
        {/*      <img*/}
        {/*        src="assets/images/svgimges/Apples.svg"*/}
        {/*        className="vector-images"*/}
        {/*        alt=""*/}
        {/*      />*/}
        {/*    </button>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="row mb-4">
          <div className="offset-1 col-md-12 ">
            <div className="forgot-password mt-4 ml-4">
              <p>
                {" "}
                <span className="text_color">
                  By using Mentorz you agree with the{" "}
                </span>{" "}
                <span className="textcolor">
                  {" "}
                  terms of service and privacy policy
                </span>
              </p>
            </div>
          </div>
        </div>
        <br />
      </div>
    );
  }
}
function _props(state) {
  return {
    validate_email_respo: state.authUser.validate_email_respo,

    //users: state.users.users,
  };
}
const _dispatchs = (dispatch) => ({
  // getSigninUser: authActions.getSigninUser,
  getSigninUser: (payload) => dispatch(authActions.getSigninUser(payload)),
  setSignupStage: (payload) => dispatch(authActions.setSignupStage(payload)),
  getStripeCustomerId: (payload) =>
    dispatch(authActions.getStripeCustomerId(payload)),
  validateEmail: (payload) => dispatch(authActions.validateEmail(payload)),
});

export default connect(_props, _dispatchs)(BusinessSignupForm);
