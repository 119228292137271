import {  toast } from 'react-toastify';


const ToastMesage = {
    sucesses: async function(mesg){
        return  toast(mesg, {
            position: 'top-center',
            className: 'toast-success',
            autoClose: 2000,
            toastId: 1
        });
    },
    warnig: async function(mesg){
        return  toast(mesg, {
            position: 'top-center',
            className: 'toast-warnig',
            autoClose: 2000,
            toastId: 1
        });
    },
    sua:function(){
        return toast('🦄 Wow so easy!', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
    }
}

export default ToastMesage;