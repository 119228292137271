import { call, put,select, takeEvery } from 'redux-saga/effects';
import {  toast } from 'react-toastify';
import homeactions from '../actions/homeActions';
import Api from './api_config';
import loaderActions from '../actions/loader';



let IMAGE_URL='https://mentorzstorageaccount1.blob.core.windows.net/mentorz/';

let usrdetail=localStorage.getItem('login_data');
usrdetail=JSON.parse(usrdetail);
function* fetchMentorDetails(){
    try{
       const home_mentdetail=yield call(Api.homeApi.getMentorDetails,usrdetail.id);
       const {data}=home_mentdetail;
       yield put(homeactions.setMentorsDetail(data))
       yield put(loaderActions.toggleLoader(false))
       
    }
    catch{
    }
    finally{
        yield put(loaderActions.toggleLoader(false))
    }
}



function* fetchUser_Mentor(param){
    try{
       const home_usermentor=yield call(Api.homeApi.getUser_Mentor,usrdetail.id,param);
       home_usermentor.data.pageNumber=param.pageNumber;
       const {data}=home_usermentor;
       yield put(homeactions.setuserMentor(data))
    }
    catch(e){

    }
    finally{

    }
}


function* uploadFile(req){
    try{
      const image = yield call(Api.fileUpload.uploadFile,req.payload.selectedFile);
      const valida_image = yield call(Api.fileUpload.fileValidate,req.payload.selectedFile);
      if(valida_image.data){
        const post = yield call(Api.homeApi.addPost,usrdetail.id,req);
        const {data}=post; 
        toast.success('File Uploaded');
      }
    }
    catch(e){
        return e;
    }
}
export const homeData = (state) => state.homeReducer
function* likePost(req ,action){
    debugger
    const {usermentors_detail}= yield select(homeData);
    let Update_usermentors_detail=[];
    if(req.payload.isLikedByLoggedInUser){
        const post=yield call(Api.homeApi.UndoLikePost,usrdetail.id,req);
         Update_usermentors_detail=usermentors_detail.filter((i)=>{
        if(i.postId === req.payload.postId){
         i.isLikedByLoggedInUser=false;
         i.likes=i.likes -1 ;
        }
        return i;
     });
    }
    else{
        const post=yield call(Api.homeApi.likePost,usrdetail.id,req);
         Update_usermentors_detail=usermentors_detail.filter((i)=>{
        if(i.postId === req.payload.postId){
         i.isLikedByLoggedInUser=true;
         i.likes=i.likes+1
        }
        return i;
     });
    }
   yield put(homeactions.updateUserPosts(Update_usermentors_detail));
   
 console.log(Update_usermentors_detail)
  
}

function* addComments(req){
    try{
       const response=yield call(Api.homeApi.addCommnet,req);
       const {data}=response;
       if(data){
        yield put(loaderActions.toggleLoader(false))
        var param={
            "postId":  req.payload.postId,
            "pageNumber": 0,
            "pageSize": 10
          }
          yield put(homeactions.getCommentsList(param))
       }
       
    }
    catch{
    }
    finally{
        yield put(loaderActions.toggleLoader(false))
    }
}

function* fetchCommentList(req){
    debugger
    try{
       const responses=yield call(Api.homeApi.getCommentList,req);
       const {data}=responses;
       debugger
       yield put(homeactions.setCommentsList(data.postComments));
    }
    catch{
    }
    finally{
        yield put(loaderActions.toggleLoader(false))
    }
}





function* getHomeDetail() {
    yield takeEvery('GET_MENTORDETAIL', fetchMentorDetails);
    yield takeEvery('GET_USERMENTORDETAIL', fetchUser_Mentor);
    yield takeEvery('UPLOAD_FILE', uploadFile);
    yield takeEvery('LIKE_POST', likePost);
    yield takeEvery('ADD_COMMENTES', addComments);
    yield takeEvery('GET_COMMENTE_LIST', fetchCommentList);
 }
 
 export default getHomeDetail;