
import React, { Component } from "react";
import { connect } from "react-redux";
import { Oval } from 'react-loader-spinner';

class Loader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {}
        }
    }

render() {
    return (
    <div className="Auth-form-container">
         <div >
         <Oval
                height={80}
                width={80}
                color="#FF4339"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#FF4339"
                strokeWidth={2}
                strokeWidthSecondary={2}
                />

         </div>
    </div>
    );
}
}

function _props(state) {
    return{
     loading:state.loader.loading,
   }
 }
const _dispatch = {
   
  }

export default connect(_props,_dispatch)(Loader)

