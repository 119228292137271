
import { call, put, select, takeEvery } from 'redux-saga/effects';

import Api from './api_config';
import loaderActions from '../actions/loader';
import journal from '../actions/journalActions';
import modalActions from '../actions/modal';
import _pubnubservice from '../../Services/PubNubService';
let IMAGE_URL='https://mentorzstorageaccount1.blob.core.windows.net/mentorz/';

let usrdetail=localStorage.getItem('login_data');
const user=JSON.parse(usrdetail);

const USERDETAIL=JSON.parse(localStorage.getItem("user_detail"));

export const homeData = (state) => state.homeReducer
export const mentor=(state=>state.journalReducer.mentor_detail);
export const subscribedChannelId=(state=>state.journalReducer.subscribed_ChannelId);
export const message_list=(state=>state.journalReducer.message_list);

function* fetchMenteeList(){
    try{
        const list=yield call(Api.journal.getMenteelist,user.id);
        const {data}=list;
        var newz={
            "userId": "8a967900-1158-416a-a507-fab77f7c451b",
            "mentorshipId": "db604cc5-1625-4618-b6e9-363c7b05e0c0",
            "firstName": "Nasim",
            "lastName": "k",
            "designation": "Sr Developer",
            "organization": "Facile Consulting",
            "bioData": "Senior .Net Professional",
            "photoId": "f40bdc49-717c-43ee-aeb1-e8d290551681.png",
            "selfDiscoverySurveyId": "02eb25bf-55c6-4ddf-b26e-dd1b8c8a7849",
            "selfDiscoveryText": "ENTP",
            "hasNewRequest": false
        }
        data.push(newz);
        for(const [i, option] of data.entries() || []){
            const photoId =  yield call (image ,option.photoId);
            option.photoId=photoId;
            if(i === (data.length-1)) { yield put(loaderActions.toggleLoader(false)) ; yield put(journal.setMenteeList(data)); }
        }   
    }
    catch{
 
    }
}

function* fetchMentorList(){
    try{
        const list=yield call(Api.journal.getMentorlist,user.id);
        const {data}=list; 
        for(let option of data || []){
            const photoId =  yield call (image ,option.photoId);
            option.photoId=photoId;
        }   
        yield put(journal.setMentorList(data));
        yield put(loaderActions.toggleLoader(false))
    }
    catch{

    }
}

function* fetchMentorDetail(req){
    try{
        const detail=yield call(Api.journal.getMentorDetail,req,user.id);
        const {data}=detail;
        const photoId =  yield call (image ,data.photoId);
        data.photoId=photoId;
        data.mentorshipId=req.payload.mentorshipId;
        yield put(journal.setMentorDetail(data));
        yield put(loaderActions.toggleLoader(false))
    }
    catch
    {

    }
}

function* fetchMenteeDetail(req){
    try{
        const detail=yield call(Api.journal.getMenteeDetail,req,user.id);
        const {data}=detail;
        const photoId =  yield call (image ,data.photoId);
        data.photoId=photoId;
        debugger
        data.mentorshipId=req.payload.mentorshipId;
        yield put(journal.setMenteeDetail(data));
        yield put(loaderActions.toggleLoader(false));
    }
    catch{
    }
}


function* updateMenteeGoal(req){
    try{
        const detail=yield call(Api.journal.updateMentorGoal,req.payload);
        const {data}=detail;
        const mentordetail=yield select(mentor);
        debugger
        if(data.exceptionData===null){
            var param={
                "mentorId": mentordetail.mentorId,
                "mentorshipId": mentordetail.mentorRequestId
               }
              yield put(journal.getMentorDetail(param))
        }
    }
    catch{
    }
}

function* getAvailability(req){
    
    try{
        const detail=yield call(Api.journal.getAvailability,req);
        const {data}=detail;
        data.mentorshipId=req.payload.mentorshipId;
       // data.map((el)=>{ return el.mentorshipId=req.payload.mentorshipId;})
        yield put(loaderActions.toggleLoaderAvailability(false));
      yield put(journal.setAvailability(data))
    }
    catch{

    }
}


function* bookMentorSession(req){
    try{
        const detail=yield call(Api.journal.bookMentorSession,req.payload,user.id);
        const {data}=detail;
        const mentordetail=yield select(mentor);
        const subscribed_ChannelId=yield select(subscribedChannelId);
        var pubnubMessage={
            "SessionId":data.response,
            "AvailabilityId":req.payload.availabilityId,
            "StartDateTime":req.payload.startDateTime,
            "EndDateTime":req.payload.endDateTime,
            "HasUserTakenAction":false //For booking time it will be false
        }
        debugger
        yield call (updatePubNubSessionstatus ,pubnubMessage,mentordetail,subscribed_ChannelId,1,req);
        yield put(loaderActions.toggleLoaderAvailability(false));
        yield put(journal.setbookMentorSessionresponce(data))
        yield put(modalActions.toggleModal('session-book-sucssess'));
    }
    catch{
    }
}

function* acceptMentorSession(req){
    debugger
 try {
    const res=yield call(Api.journal.acceptMentorSession,req.payload,user.id);
    const {data}=res;
    const mentordetail=yield select(mentor);
    const subscribed_ChannelId=yield select(subscribedChannelId);
    var pubnubMessage={
        "SessionId":data.response,
        "AvailabilityId":req.payload.AvailabilityId,
        "StartDateTime":req.payload.StartDateTime,
        "EndDateTime":req.payload.EndDateTime,
        "HasUserTakenAction":true //For taking action  time it will be true(Accept,Reject,Reschedule)
    }
    debugger
    yield call (updatePubNubSessionstatus ,pubnubMessage,mentordetail,subscribed_ChannelId,2,req);
 } catch (error) {
    
 }
}
function* rejectMentorSession(req){
 try {
    const res=yield call(Api.journal.rejectMentorSession,req.payload,user.id);
    const {data}=res;
 } catch (error) {
    
 }
}
function* rescheduleMentorSession(req){
try {
    const res=yield call(Api.journal.rescheduleMentorSession,req.payload,user.id);
    const {data}=res;
} catch (error) {
    
}
}

function* fetchSubscribChannelId(req){
    try{
        const detail=yield call(Api.journal.subscribChannelId,user.id,req.payload);
        const {data}=detail;
      //  yield put(loaderActions.toggleLoaderAvailability(false));
        yield put(journal.setSubscribeChannelId(data.response))
    }
    catch{
    }
}


function* image(imageId){
    let image='';
    try{
        if(imageId!=="" && imageId!==undefined && imageId!=="string" )
        {
          image=IMAGE_URL+ imageId;
        }
        else{
         image='assets/images/User.png';
        }
        return image
    }
    catch(e){
        return e;
    }
    finally{
    }
}

function* updatePubNubSessionstatus(req,mentordetail,subscribed_ChannelId ,status,messageData) {
     //PubNub Message
     if(USERDETAIL.paymentMethods.length>0){
        var title='';
         if(mentordetail?.mentorshipSessions?.length>0)
         {
           title='Mentorship Session '+ (mentordetail.mentorshipSessions.length+1) ;
         }
         else{
           title='Get to Know Each Other';
         }
         var SessionType=mentordetail?.mentorshipSessions?.length>0?(mentordetail.mentorshipSessions.length+1):1;
         var bookdata={
         "SessionId":req.SessionId,
         "AvailabilityId":req.AvailabilityId,
         "Title":title,
         "SessionType":SessionType, ///list of the session list   have a look on later its hardcoder
         "StartDateTime":req.StartDateTime,
         "EndDateTime":req.EndDateTime,
         "SessionStatus":status, //for 1st time booking its 1
         "SessionGoal":"",
         "MentorId":mentordetail.mentorId,
         "UpdatedBy":user.id,
         "HasUserTakenAction":req.HasUserTakenAction
       }
       var message=JSON.stringify(bookdata);
         _pubnubservice.publishMessage(subscribed_ChannelId, message)
         .then((d)=>{
         }).catch(()=>{
         });;
         yield call(updateHasUserTakenAction, messageData);
       }
       else{alert('Please add a card for transaction before booking.You add your card from payment menu')}
}

function* updateHasUserTakenAction(data){
    debugger
    const messagelist=yield select(message_list);
    const newList = messagelist.map((item) => { 
        if (item.timetoken === data.payload.timetoken) {
          const updatedItem = {
            ...item,
            message: {...item.message, HasUserTakenAction: true},
          };
          return updatedItem;
        }
        return item;
      });
      yield put(journal.messageList([]));
      yield put(journal.updateMessageList([]));
      yield put(journal.updateMessageList(newList));
}

function* journalSaga() {
    yield takeEvery('GET_MENTEE_LIST', fetchMenteeList );
    yield takeEvery('GET_MENTOR_LIST', fetchMentorList);
    yield takeEvery('GET_MENTEE_DETAIL', fetchMenteeDetail );
    yield takeEvery('GET_MENTOR_DETAIL', fetchMentorDetail);
    yield takeEvery('UPDATE_MENTORE_GOAL', updateMenteeGoal);
    yield takeEvery('GET_AVAILABILITY', getAvailability);
    yield takeEvery('BOOK_MENTOR_SESSION', bookMentorSession);
    yield takeEvery('ACCEPT_MENTOR_SESSION', acceptMentorSession);
    yield takeEvery('REJECT_MENTOR_SESSION', rejectMentorSession);
    yield takeEvery('RESCHEDULE_MENTOR_SESSION', rescheduleMentorSession);
    yield takeEvery('GET_SUBSCRIB_CHANNEL_ID', fetchSubscribChannelId);
    //yield takeEvery('UPDATE_PUBNUBMESSGAE_STATUS', updatePubNubSessionstatus);
    
    
}

export default journalSaga;