import React, { useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'; 


export default function CommentsLoader() {
  return (
    <div>
         <div className=' row mt-4'>
            {
              <div className=" mb-4 ">
              {
                [1, 2, 3, 4].map((el,index)=>{
                  return(
                  <div  key={index} className="list-group list-group-numbered d-flex justify-content-between " role="alert" >
                  <div className=" d-flex justify-content-between align-items-start  mt-1 ">
                    <div className='cornerimage  '> <Skeleton circle width={60} height={60} /> </div>
                    <div className="alert alert-secondary   commentbox " style={{marginRight:'30px'}}>
                    <div className=" mt-3 ">
                      <p className="img_align"><Skeleton width={150}/> </p>
                      <p><Skeleton width={300}/></p>
                    </div>
                    </div>
                </div>
                <p className='commentdate ' style={{marginLeft:'80px'}}><Skeleton width={100}/></p>
                </div>
              )
                })
              }
              </div> 
             
              }    
            </div>
    </div>
  )
}
