import type from '../types';

let ScheduleActions = {

  setScheduletab:function(params){
    return{
        type:type.SET_SCHEDULE_TAB,
        tab:params
    }
  },


  getScheduleList:function(params){
    return{
        type:type.GET_SCHEDULE_LIST,
        params

    }
 },

 setScheduleList:function(params) {
    return{
        type:type.SET_SCHEDULE_LIST,
        schedulelist:params
    }
 },

 upadateAvailability:function(params) {
  return{
      type:type.UPDATE_AVAILABILITY,
      params
  }
},
upadateAvailabilityResult:function(params) {
  return{
      type:type.UPDATE_AVAILABILITY_RESULT,
      params
  }
},

 

  
}


export default ScheduleActions;