import type from '../types';

let authActions = {
  getCustemerLoginUser:function(fields,navigate)
   {
    return { 
      type: type.GET_CUSTOMER_LOGINUSER,
      fields,
      navigate
    }
  },

  verifyExistEmail:function(payload) {
    return { 
      type: type.VERIFY_EMAIL,
      payload,
    }
  },
  validateEmail:function(payload) {
    return { 
      type: type.VALIDATE_EMAIL,
      payload,
    }
  },
  setValidateEmailResp:function(payload) {
    return { 
      type: type.SET_VALIDATE_EMAIL_RESPO,
      payload,
    }
  },

  setloginStatus:function(mesg) {
    return { 
      type: type.SET_LOGINUSER_SATUS,
      mesg
    }
  },
  getStripeCustomerId:function(payload) {
    return { 
      type: type.GET_STRIPECUSTOMER_ID,
      payload
    }
  },

  //SIGN UP
  getSigninUser:function(fields) {
    console.log('getSigninUser');
    return { 
      type: type.GET_SIGNINUSER,
      fields
    }
  },
  updateSigninUser:function(payload) {
    return { 
      type: type.UPDATE_SIGNINUSER,
      payload
    }
  },
   setSigninUser:function() {
    return { 
      type: type.GET_SIGNINUSER_SUCCESS,
    }
  },

  uploadUserImage:function(payload) {
    return { 
      type: type.UPLOAD_USER_IMAGE,
      payload
    }
  },
  
  setSignupStage:function(payload) {
    
    return { 
      type: type.SET_SIGNUP_STAGE,
      payload
    }
  },
  
}


export default authActions;