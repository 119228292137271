import type from '../types';

let ProfileActions = {

    getProfilejournyList: function (){
    return{
        type: type.GET_USER_PROFILE_JOURNY_LIST,
        
    }
    },
    setProfilejournyList: function (payload) {
        return {
            type: type.SET_USER_PROFILE_JOURNY_LIST,
            payload

        }
    },
  updateProfilejournyList:function(payload){
    return{
        type:type.UPDATE_USER_PROFILE_JOURNY_LIST,
        payload
    }
  },

  getUserProfileDetail:function(){
    return{
        type:type.GET_USER_PROFILE_DETAIL
    }
  },
  setUserProfileDetail:function(payload){
    return{
        type:type.SET_USER_PROFILE_DETAIL,
        payload
    }
  },

  //JorneyStep
  setProfileJorneyStep:function(status) {
    return { 
      type: type.TOGGLE_PROFILEJOURNEY_STEP,
      profile_Journy:status
    }
  },

  setProfileDetail:function(payload) {
    
    return { 
      type: type.SETUP_PROFILEDETAIL,
      payload
     // profile_detail:status.profileDetail,
     // profile_tab:status.profile_tab
    }
  },

  //INTEREST
  getProfileInterests:function() {
    return { 
      type: type.GET_PROFILEINTEREST,
    }
  },
  setProfileInterests:function(status) {
    return { 
      type: type.SET_PROFILEINTEREST,
      profile_interests:status
    }
  },

  //TRENDING INTEREST
  getProfileTrendingInterests:function() {
    return { 
      type: type.GET_PROFILETRENDINGINTEREST,
    }
  },
  setProfileTrendingInterests:function(status) {
    return { 
      type: type.SET_PROFILETRENDINGINTEREST,
      profile_trendinginterests:status
    }
  },


  //EXPERTIES
  getProfileExpertise:function() {
    return { 
      type: type.GET_PROFILEXPERTIES,
    }
  },
  setProfileExpertise:function(status) {
    return {  
      type: type.SET_PROFILEXPERTIES,
      profile_experties:status
    }
  },

  //TRENDING EXPERTIES
  getProfileTrendingExpertise:function() {
    return { 
      type: type.GET_PROFILETRENDINGEXPERTIES,
    }
  },
  setProfileTrendingExpertise:function(status) {
    return { 
      type: type.SET_PROFILETRENDINGEXPERTIES,
      profile_trendingexperties:status
    }
  },
  
  //VALUES
  getProfileValues:function() {
    return { 
      type: type.GET_PROFILEVALUES,
    }
  },

  setProfileValues:function(status) {
    return { 
      type: type.SET_PROFILEVALUES,
      profile_values:status
    }
    },

    setProfileTrendingValues: function (status) {
        return {
            type: type.SET_PROFILETRENDINGVALUES,
            profile_trendingvalues: status
        }
    },
    getProfileTrendingValues: function (status) {
        return {
            type: type.GET_PROFILETRENDINGVALUES,
            profile_trendingexperties: status
        }
    },


  //save user profiledetail
  updateUserProfile:function(payload) {
    return { 
      type: type.UPDATE_USER_PROFILE_DETAIL,
      user_profile_detail:payload
    }
  },

  //Self-Discovery Survey
  getSelfDiscoverySurvey:function(payload) {
    return { 
      type: type.GET_SELFDISCOVER_SURVEY,
    }
    },

    updateSelfDiscoveryDetail: function (payload) {
        return {
            type: type.UPDATE_SELFDISCOVER_SURVEY,
            payload
        }
    },

  setSelfDiscoverySurvey:function(payload) {
    return { 
      type: type.SET_SELFDISCOVER_SURVEY,
      payload
    }
  },
  
  //Payment stages
  setProfileMentorshiprate:function(stage) {
    return { 
      type: type.SET_PROFILEPMENTOR_RATE,
      profile_Mentorsrate:stage
    }
  },
  //Payment stages
  setProfilePaymentStep:function(stage) {
    return { 
      type: type.TOGGLE_PROFILEPAYMENT_STEP,
      profile_Payment:stage
    }
  },

  getPaymentMethodList:function(stage) {
    return { 
      type: type.GET_PAYMENT_TYPE,
    }
  },

  SetPaymentMethodList:function(payload) {
    return { 
      type: type.SET_PAYMENT_TYPE,
      payload
    }
  },
  addPaymentMethod:function(payload) {
    return { 
      type: type.ADD_PAYMENT_METHOD,
      payload
    }
  },

  ///Create payment Account
  createPymentAccount:function(payload) {
    return { 
      type: type.CREATE_ACCOUNT_LINK,
      payload
    }
  },
}


export default ProfileActions;