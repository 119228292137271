import  type from '../types';

const initialState = {
    loading: false,
    error: null,
    login_status:'',
    signup_stage:'membership_type',
    validate_email_respo:''
    
  }

const authUser = (state = initialState, action) => {
    switch (action.type) {
        //LOG IN REDUCERS
      case 'GET_CUSTOMER_LOGINUSER':
        return {
          ...state,
          loading: true,
        }
        case 'SET_LOGINUSER_SATUS':
        return {
          ...state,
          loading: true,
          login_status:action.mesg
        }

        //SIGN 
        case 'GET_SIGNINUSER':
            return {
              ...state,
              loading: true,
            }
        case 'SET_SIGNUP_STAGE':
          return {
            ...state,
            signup_stage: action.payload,
          }
        case 'SET_VALIDATE_EMAIL_RESPO':
          return {
            ...state,
            validate_email_respo: action.payload,
          }
        case 'GET_SIGNINUSER_SUCCESS':
        return {
            ...state,
            loading: false,
            signinUsers: action
        }

      default:
        return state
    }



}
export default authUser;