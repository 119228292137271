import React from 'react'
export default function Offlinepage() {
  const offline=()=>
  {
    alert("internet Connaction not found");
  } 
  
  return (
    <div className="container d-flex justify-content-center ">
    <div className="row  justify-content-center " style={{padding: '2% 10% 0px 10%'}}>
          <div className="card-header pb-0 bg-white border-0 text-center "><img src="assets/images/offline.webp" className="img-offline  " /> </div>
          <div className="card-body px-sm-4 mb-3">
              <ul className="list-unstyled text-muted"> 
              <li>Please re-connect to the internet to continue use Footsteps.</li> <li>If you encounter problems:</li><br/>
                  <ul className="mt-2 inner">
                      <li className='mt-2'>Try restarting wireless connection on this device.</li>
                      <li className='mt-2'>Move clouse to your wireless access point.</li>
                  </ul>    
              </ul>
              <div className="row justify-content-end offline_button" > <div className="col-auto"><button type="button" className="btn btn-app btn-md"><span >Try Again</span></button></div></div>
          </div>
       
    </div>
  </div>
  ) 
 
}
