import React, { useEffect, useState,useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PubNub from 'pubnub';
import { PubNubProvider, usePubNub } from 'pubnub-react';
//import Samplecard from '../container/Journal/samplecard';
let usrdetail=localStorage.getItem('login_data');

const user=JSON.parse(usrdetail);

const pubnub = new PubNub({
    publishKey:'pub-c-3784fe38-e277-44f4-90a6-8550bbfe3d56',
    subscribeKey:'sub-c-95425b74-e914-11ea-a728-4ec3aefbf636',
    secretKey: "sec-c-YjI4ZWFhOWQtZDU5NS00NWMxLWFmZDEtZDAyNDBkNjc1NGE2",
    uuid: "pn_0BFAB2E2-B014-4E28-9740-E016A7AAF76F",//'pn_76e81f63-1e5d-47dd-82e2-c329ae335629',
   // user?.pubNubUserId,//
  });

export default function TestingPubnub() {
    

  return (
    <PubNubProvider client={pubnub}>
        <Chat1 />
      </PubNubProvider>
  )
}

  function Chat1() {
    const mentor=useSelector(state=>state.journalReducer.mentor_detail);
    const subscribed_ChannelId=useSelector(state=>state.journalReducer.subscribed_ChannelId);
    const pubnub = usePubNub();
    const [channels] = useState(["485ffc24-5098-4632-b063-3cc112794dcb"]);;//useState(['948aba58-b7fb-4508-b792-46c0367a91e8']);
    const [messages, addMessage] = useState([]);
    const [text, setText] = useState('');
    const [messagesList, addMessageList] = useState([]);
    const [selectedFile, setSelectedFile] = useState('');
    const[bookdetail,setBookDetail]=useState();
    const listInnerRef = useRef();
  
    const handleMessageStatus = statusEvent => {
     // alert(statusEvent.category );
    
    };
    const handleMessagePresence = presenceEvent => {
      //alert(presenceEvent );
    };
  
    const handleMessage = event => {
      //alert('handleMessage')
      const message = event.message;
      debugger
    //   if (typeof message === 'string' || message.hasOwnProperty('text')) {
    //     const text = message.text || message;
    //     addMessage(messages => [...messages, text]);
    //   }
    };
  
    const sendMessage = message => {
       // alert('sendMessage')
      if (message) {
        pubnub
          .publish({ channel: channels, message })
          .then(() => setText(''));
      }
    };
  
  
    useEffect(() => {
     pubnub.addListener({message: handleMessage});
     pubnub.addListener({status: handleMessageStatus});
     pubnub.addListener({presence: handleMessagePresence});
      pubnub.subscribe({ channels });
      setBookDetail(mentor);
      async function fetchData() {
        const response =  await pubnub.fetchMessages({
          channels: channels,
          count: 2
       });
       re_arrange(response.channels[channels])
       console.log(response)
      }
      fetchData();
      return () => {
          pubnub.unsubscribe({ channels })
         // pubnub.removeListener(listenerParams)
      }
    }, [mentor,pubnub, channels]);

    const re_arrange=(list)=>{
        const images = ["jpg", "gif", "png"]
       const formated_list= list?.map((el)=>
        {
          if(el.messageType===4){
            const fileName =el.message.file.name;
            const fileExtension = fileName.substr((fileName.lastIndexOf('.') + 1));
            const result = pubnub.getFileUrl(
              { 
               channel: channels,
               id: el.message.file.id, 
               name: el.message.file.name 
              });
            el.messageType=images.includes(fileExtension)?'Image':'Video';
            el.message.file.URL=result;
          }
          if(el.messageType===null){
            el.messageType=isJSON(el.message)?'Card':'Text';
            if(el.messageType==='Card'){
              
              el.message= Number.isInteger(el.message)?el.message:JSON.parse(el.message);
               el.message.farmateDate=getFormattedDate(el.message.StartDateTime);
              el.message.startTime=new Date(el.message.StartDateTime).toLocaleString('en-US', { hour: '2-digit', hour12: true });
              el.message.endTime=new Date(el.message.EndDateTime).toLocaleString('en-US', { hour: '2-digit', hour12: true });
            }
          }
         
          return el
        })
        addMessageList(formated_list);
        //  addMessageList(previousState =>( [...previousState, formated_list] ));
        console.log(formated_list);
  }

  
  const  getFormattedDate=(data)=>
    {
      var today = new Date(data);
        var week =['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'] ;
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        var month= monthNames[today.getMonth()]
        var day  = week[today.getDay()];
        var dd   = today.getDate();
        var yyyy = today.getFullYear();
        return month+' ' + dd + ' , '+ yyyy + '('+day+') ' ;
    }

  // Function to test valid JSON
  function isJSON(str) {
    try {
        return JSON.parse(str) && !!str;
    } catch (e) {
        return false;
    }
   }

   const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if(scrollTop===0){
        console.log('Scrolled to Page Top');
        pubnub.fetchMessages(
          {
              channels: [subscribed_ChannelId],
              start: messagesList[0]?.timetoken,
              count: 2
          },
          (status, response) => {
            console.log(response.channels[subscribed_ChannelId]);
            if(response.channels[subscribed_ChannelId]!==undefined){
              const arr2 = [ ...response.channels[subscribed_ChannelId],...messagesList];
              re_arrange(arr2)
            }
          }
        );
      }
      if (scrollTop + clientHeight === scrollHeight) {
        console.log("reached bottom");
      }
    }
  };
  // const handleFileChange = async(e) => {
  //   alert(e.target.files[0]);
  //   setSelectedFile(e.target.files[0]);
  //   const file = e.target.files[0];
  //   const result = await pubnub.sendFile({
  //     channel: subscribed_ChannelId,
  //     file: file,
  //   });
    
  //   const resultURL = pubnub.getFileUrl(
  //     { 
  //      channel: subscribed_ChannelId,
  //      id: result.id, 
  //      name: result.name 
  //     });
  //     debugger
   
  // };

  const handleFileChange = async(e) => {
    setSelectedFile(e.target.files[0]);
    const file = e.target.files[0];
    const result = await pubnub.sendFile({
      channel: channels,
      file: file,
    });
    
  };

  
    return (
        <div className="row">
        <div className='horizontal_line mt-3 mb-3'></div>
        <div className="col-3 ">
        <div className="d-flex feed-header" >                  
          <div>
            <div className='' >
                <img className="pe-3" src="assets/images/small/VectorBackicon.png"/>Back to Profile
            </div>
            <img 
                src={bookdetail?.photoId}  className='rounded-circle ' alt='' style={{width:'120px'}} 
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src="assets/images/User.png";
                }}/>                 
          </div>
          </div> 
            <div className=" ml-5  flex-grow-1">
            <div className="feed-title  ">{bookdetail?.firstName} {bookdetail?.lastName}  </div>
            <div className="feed-sub mt-1 " style={{color:"#28963C"}}>$100 budget</div>
            <div className="feed-sub feed_mar ">{bookdetail?.designation} </div>
          </div>
        </div>
          <div className="vr verticle_line1 " ></div>
          <div className="col mt-4 " onScroll={onScroll}
            ref={listInnerRef}
            style={{ height: "200px", overflowY: "auto" }}>
            <div  className='card' >
              <div class="card-body msg_card_body">
                {messagesList?.length} <br/>
                {
                  messagesList?.map((el)=>{
                    return<div key={el.timetoken}>
                {
                  el.uuid===user.pubNubUserId?
                  <div class="d-flex justify-content-start mb-4">
                  <div class="img_cont_msg">
                  <img src={user.UserImage} alt="profile" class="rounded-circle user_img_msg" />
                  </div>
                  <div class="msg_cotainer">
                    {/* <Samplecard data={el}/> */}
                  </div>
                </div>
                :
                <div class="d-flex justify-content-end mb-4">
                  <div class="msg_cotainer_send">
                  {/* <Samplecard data={el}/> */}
                    <span class="msg_time_send">8:55 AM, Today</span>
                  </div>
                  <div class="img_cont_msg">
                  <img src={mentor.photoId} className="profile_size " alt=''  class="rounded-circle user_img_msg"/>          
                  </div>
                </div>
                }
                </div>
                  })
                }
              </div>
              <div class="card-footer">
                <div class="input-group">
                  <div class="input-group-append">
                    <label  for="file-input" className=' cursor-pointer'> 
                      <span class="input-group-text attach_btn"><i class="fa fa-paperclip"></i></span>
                      <input id="file-input" type="file" onChange={handleFileChange}/> 
                    </label>
                  </div>
                  <input value={text} 
                  onChange={e => setText(e.target.value)}
                   className="form-control form-control-border-btm inputboxborder"/>
                  <div class="input-group-append" onClick={()=>sendMessage(text)}>
                    <span class="input-group-text send_btn"><i class="fa fa-send-o"></i></span>
                  </div>
                </div>
                <br/>
              <div>Input value: {text}</div>
              </div>
            </div>
            {/* <button className="btn view_mymentee_notifications  position-relative" onClick={deleteMsgs}>Delete Msgs</button> */}
          </div>
        </div>
    );
  }
