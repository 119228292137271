import React from 'react';
import { Layer1 } from '../container/landingPages/Layer1';
import { Layer2 } from '../container/landingPages/Layer2';
import { Layer3 } from '../container/landingPages/Layer3';
import { Layer4 } from '../container/landingPages/Layer4';
import { Layer5 } from '../container/landingPages/Layer5';
import { Layer6 } from '../container/landingPages/Layer6';
import { Layer7 } from '../container/landingPages/Layer7';
import { Footer } from './Footer';

const LandingPage = () => {


    return (
      <div className=''>        
        <Layer1/>
        <br/>
            <Layer2 />
            <Layer4 />
        <Layer3/>
        <Layer5/>
        <Layer6/>
        <Layer7/>
        <Footer/>
      </div>
    );
  }

export default LandingPage;
