import React, { useEffect } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import authActions from "../../redux/actions/authActions";
import modalActions from "../../redux/actions/modal";
import CustomeModal from "../../Modals/CustomeModal";

export default function BusinesSignupReview({ formData }) {
  const dispatch = useDispatch();

  const validate_email_respo = useSelector(
    (state) => state.authUser.validate_email_respo
  );

  useEffect(() => {
    dispatch(authActions.setValidateEmailResp(""));
  }, []);

  const toggle = (stage) => {
    dispatch(authActions.setSignupStage(stage));
  };
  const completeSignUp = () => {
    console.log("form data", formData);
    dispatch(authActions.validateEmail(formData));
  };

  return (
    <div className="center_div" style={{ width: "600px" }}>
      <CustomeModal UserEmail={formData.Email} />
      <div className="mt-4">
        <p style={{ float: "left" }} onClick={() => toggle("sign_up_form")}>
          <img src="assets/images/svgimges/back_button.svg" alt="3-dots" />
        </p>
        <div className="sign-title-1 mt-4 mb-4 offset-3">Review & Finish</div>
        <div
          className="offset-1 mb-4"
          style={{ color: "rgba(64, 66, 83, 1)", fontSize: "20px" }}
        >
          Please confirm that the information below is correct.
        </div>
      </div>

      <div className="row offset-1 mt-5">
        <div className="col col-6">
          <p className="title_1 mb-5">
            {" "}
            Membership{" "}
            <img
              src="assets/images/svgimges/edit.svg"
              className="vector-images"
              alt=""
            />{" "}
          </p>
          <p className="title_2">Membership Type</p>
          <p>Individual</p>
          <p className="title_2 mt-4">Membership Subscription</p>
          <p>Basic - Free</p>
        </div>
        <div className="col col-6">
          <p className="title_1 mb-5" onClick={() => toggle("sign_up_form")}>
            Personal Details{" "}
            <img
              src="assets/images/svgimges/edit.svg"
              className="vector-images"
              alt=""
            />{" "}
          </p>
          <p className="title_2">Name</p>
          <p>
            {formData.FirstName} &nbsp; {formData.LastName}
          </p>
          <p className="title_2 mt-4">Email</p>
          {/* className={`nav-link-alt  ${pathname==="/Notifications" ? "active" : ""}`} */}
          <p className={`${validate_email_respo !== "" ? "email_error" : ""}`}>
            {formData.Email}
          </p>
          <p> {validate_email_respo}</p>
          <p className="title_2 mt-4">Phone Number</p>
          <p>{formData.PhoneNumber}</p>
        </div>
      </div>

      <div
        className="button-container button-customcss"
        onClick={() => completeSignUp()}
      >
        <input
          type="submit"
          value="Complete Signup"
          style={{ height: "50px", width: "250px", borderRadius: "50px" }}
        />
      </div>
      <br />
    </div>
  );
}
