import React, { useEffect, useState } from 'react';
import { Link ,useLocation} from 'react-router-dom';
import AlertService from '../Services/Alert';
import { useSelector } from 'react-redux';

  const ProtectedHeader = (props) => {
    const location = useLocation();
    const {pathname }=location;
    const USERDATA=JSON.parse(localStorage.getItem("login_data"));
    const mentor_detail=useSelector(state=>state.homeReducer.mentors_detail);
    
   const Logout=() =>{
    AlertService._confirm().then((response) => {
      if(response.isConfirmed){
        localStorage.clear(); 
        window.location.href = '/login';
      }
   })
   }

   useEffect(()=>{
    const mmm=USERDATA;
  },
   [mentor_detail])


    return (
        <div>
            <nav className="navbar navbar-expand-lg bg-body-tertiary" style={{
                width: "100%", justifyContent:"center"} }>
      
      <Link to="LandingPage" className="nav-link-alt ml-5">
         <img src="assets/images/Logo.png" alt="Logo" style={{height:"36px"}} />
      </Link>
      
        <form className="d-flex" role="search">
          <div className="nav-search">
            <input className="form-control me-2 nav-search-form ml-3" type="search" placeholder="Leadership" aria-label="Search" />
            <span className="nav-search-icon">
              <img src="assets/images/svgimges/search.svg" alt="search" />
            </span>
          </div>
        </form>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"><i className="bi bi-list"></i></span>
        </button>
        <div >
        <div className="collapse navbar-collapse justify-content-end " id="navbarSupportedContent">
          {/*<Link to="Home" className={`nav-link-alt  ${pathname==="/Home" ? "active" : ""}`}>*/}
          {/* <img src="assets/images/small/home.png" alt="home" />*/}
          {/* <p className="mt-2">Home</p>*/}
          {/*  </Link>*/}
          {/*  <Link to="Match" className={`nav-link-alt ${pathname==="/Match" ? "active" : "" }`}>*/}
          {/*    <img src="assets/images/small/match.png" alt="match" /><p className="mt-2">Match</p> */}
          {/*  </Link>*/}
          {/*  <Link to="Journal" className={`nav-link-alt ${pathname==="/Journal" ? "active" : "" }`} >*/}
          {/*    <img src="assets/images/small/journal.png" alt="journal" /><p className="mt-2">Journal</p> */}
          {/*  </Link>*/}
          {/*  */}{/* to="Chatwrapper"  */}
          {/*  <Link className={`nav-link-alt ${pathname==="/Rings" ? "active" : ""}`} >*/}
          {/*  <img src="assets/images/small/rings.png" alt="rings" /><p className="mt-2">Rings</p> */}
          {/*  </Link>*/}
          {/*  <Link to="Schedule" className={`nav-link-alt  ${pathname==="/Schedule" ? "active" : ""}`}>*/}
          {/*    <img src="assets/images/small/schedule.png" alt="schedule" /><p className="mt-2">Schedule</p> */}
          {/*  </Link>*/}
          {/*  */}{/* to="Notifications" */}
          {/*  <Link to="Notifications" className={`nav-link-alt  ${pathname==="/Notifications" ? "active" : ""}`}>*/}
          {/*  <img src="assets/images/small/notifications.png" alt="notifications" /><p className="mt-2">Notifications</p> */}
                        {/*  </Link>*/}
                        {/*to="Home"*/}
                        <Link className={`nav-link-alt  ${pathname === "/Home" ? "active" : ""}`}>
                            <img style={{ width: "75px", height:"53px" }} src="assets/images/small/HomeIcon.png" alt="home" />
                        </Link>
                        {/*to="Match"*/}
            <Link  lassName={`nav-link-alt ${pathname === "/Match" ? "active" : "" }`}>
                            <img style={{ width: "75px", height: "53px" }} src="assets/images/small/MatchIcon.png" alt="match" />
                        </Link>
                        {/* to="Journal"  */}    
            <Link  className={`nav-link-alt ${pathname==="/Journal" ? "active" : "" }`} >
                            <img style={{ width: "75px", height: "53px" }} src="assets/images/small/JournalIcon.png" alt="journal" />
            </Link>
            {/* to="Chatwrapper"  */}
            <Link className={`nav-link-alt ${pathname==="/Rings" ? "active" : ""}`} >
                            <img style={{ width: "75px", height: "53px" }} src="assets/images/small/RingsIcon.png" alt="rings" />
                        </Link>
              {/*to="Schedule"*/ }
            <Link className={`nav-link-alt  ${pathname==="/Schedule" ? "active" : ""}`}>
                            <img style={{ width: "75px", height: "53px" }} src="assets/images/small/Schedules.png" alt="schedule" />
            </Link>
            {/* to="Notifications" */}
            <Link  className={`nav-link-alt  ${pathname==="/Notifications" ? "active" : ""}`}>
                            <img style={{ width: "75px", height: "53px" }} src="assets/images/small/Notification.png" alt="notifications" />
            </Link>
                        <Link to="/ProFile" className={`nav-link-alt profile-icon  ${pathname === "/ProFile" ? "active" : ""}`}>
                            <div className="dropdown show">
                                <button className="btn" style={{ border: "transparent" }}
                                    id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">                            
                                        <img style={{ width: "75px", height: "67px", marginTop: "-10px" ,borderRadius:"100%"}} src={USERDATA.UserImage} alt="profile" />
                                </button>
                                <div className="dropdown-menu" style={{ width:"250px"}}>
                                    {/*<li class="container" style={{ display: "flex" }}>*/}
                                    {/*    <img src="assets/images/homepage/learning.png" alt="Learning" className="dropdown-item" style={{ width: "75px" }} />*/}
                                    {/*    <button style={{ background: "white", fontSize: "25px" }}  >Learning</button>*/}
                                    {/*</li>*/}
                                    {/*<li class="container" style={{ display: "flex" }}>*/}
                                    {/*    <img src="assets/images/homepage/payment.png" alt="payment" className="dropdown-item" style={{ width: "75px" }} />*/}
                                    {/*    <button style={{ background: "white", fontSize: "25px" }} >Payment</button>*/}
                                    {/*</li>*/}
                                    {/*<li class="container" style={{ display: "flex" }}>*/}
                                    {/*    <img src="assets/images/homepage/feedback.png" alt="Feedback" className="dropdown-item" style={{ width: "75px" }} />*/}
                                    {/*    <button style={{ background: "white", fontSize: "25px" }} >Feedback</button>*/}
                                    {/*</li>*/}
                                    <li class="container" style={{ display: "flex" }}>
                                        <img src="assets/images/homepage/privacy.png" alt="Privacy" className="dropdown-item" style={{ width: "75px", height: "53px", marginBottom: "10px" }} />
                                        <Link className="navlink-slide"to="/Privacypolicy" >Privacy</Link>
                                    </li>
                                    <li class="container" style={{ display: "flex" }}>
                                        <img src="assets/images/homepage/support.png" alt="Support" className="dropdown-item" style={{ width: "75px", height: "53px", marginBottom: "10px" }} />
                                        <Link className="navlink-slide" to="/Contact" >Support</Link>
                                    </li>
                                    <li class="container" style={{ display: "flex" }}>
                                        <img src="assets/images/homepage/about.png" alt="About" className="dropdown-item" style={{ width: "75px", height: "53px", marginBottom:"10px" }} />
                                        <Link className="navlink-slide" to="/about" >About</Link>
                                    </li>
                                    <li><hr class="dropdown-divider" /></li>
                                    <li class="container" style={{ display: "flex" }}>
                                        <img src="assets/images/homepage/return_24px.png" alt="Logout" className="dropdown-item" style={{ width: "75px", height: "53px", marginBottom: "10px" }} onClick={() => Logout()} />
                                        <button style={{ fontSize: "30px", background: "white", marginBottom:"20px" }} onClick={() => Logout()} >Logout</button>
                                    </li>
                                    </div>
                            </div>
            </Link>
            {/*<button className="btn" onClick={()=>Logout()}>*/}
            {/*  <i className="fa fa-sign-out fa-2x" aria-hidden="true"></i>*/}
            {/*</button>*/}

            {/* <Link to="/TestingPubnub" className={`nav-link-alt profile-icon }`}>
              TestingPubnub
            </Link> */}
        </div>
        </div>
     </nav>
     </div>
    );
}
export default ProtectedHeader;
