import React from 'react';

import { Footer } from './Footer';

const About = () => {


    return (
        <div className='signup_background'>
        <div className='about row'>
            <div className="offset-lg-3 col-lg-6 col-sm-12">
            </div>
            <section class="">

                        <h1 className="hero-title">About Mentorz</h1>
                    <p class="left">Mentorz is a smart platform that empowers people to learn from experts and thrive in their careers, and businesses to increase job satisfaction, engagement and growth in leadership at scale.</p><p class="left">It enables you to easily match with mentors, develop purposeful learning relationships, learn and grow. And business to empower their employees to develop skills and feel engaged.</p><p class="left">MATCH: Find mentors and get chosen as a mentor based on expertise, interests and values.</p><p class="left">LEARN: Connect and engage with top experts, teach, watch stories and discover yourself. Schedule live video calls based on mentor and mentee availability.</p><p class="left">GROW: Find networking opportunities and get sponsored.</p>< br /><h3>Download our app</h3><ul class="pages__BadgeContainer-sc-14tiei8-4 ifPKbj"><a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.mentorz" class="pages__Badge-sc-14tiei8-3 bfbzVP"><img alt="Get it on Google Play" src="assets/images/footerimages/google_play_small.png" /></a><a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/mentorz/id1234159570?mt=8" class="pages__Badge-sc-14tiei8-3 bfbzVP"><img alt="Download on the App Store" src="assets/images/footerimages/app_store_small.png" /></a></ul></section>
           
            </div>
            <Footer />
            </div>
    );
}

export default About;
