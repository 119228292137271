import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import authActions from "../../redux/actions/authActions";
import Loader from "../loader";
import loaderActions from "../../redux/actions/loader"
import {  toast } from 'react-toastify';




 class BusinessLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            fields: {},
            errors: {}
        }
        this.handleChange = this.handleChange.bind(this);
        this.submitloginForm = this.submitloginForm.bind(this);
    }
    
    componentDidMount(){
        this.props.Loader(false);
    }
    handleChange(e) {
        this.setState({ errors: '' });
        this.props.setStatus('');
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields });
    }
    
    submitloginForm(e) {
        e.preventDefault();
        if (this.validateForm()) {
            let fields = this.state.fields;
            this.setState({ fields: fields });
            console.log(this.state);
            this.props.getLoginUser(fields);
        }
    }
    
    validateForm() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
    
    
        if (!fields["Email"]) {
            formIsValid = false;
            errors["e_Email"] = "*Please enter your email-ID.";
        }
    
        if (typeof fields["Email"] !== "undefined") {
            //regular expression for email validation
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["Email"])) {
                formIsValid = false;
                errors["Email"] = "*Please enter valid email-ID.";
            }
        }
    
        if (!fields["Password"]) {
            formIsValid = false;
            errors["e_Password"] = "*Please enter your password.";
        }
    
        this.setState({
            errors: errors
        });
        return formIsValid;
    }
    togloader(bol){
        this.props.Loader(bol)
    }
    
    
    render() {
    const {Email,Password} =this.state.fields;
    const {e_Email,e_Password} =this.state.errors;
    return (
     <div className="cust-backgorund">
    <div className="Auth-form-container">
    <form className="Auth-form" onSubmit={this.submitloginForm}>
        <div className="row">
    <div className="loginbtn">
          <div className="custsignStyle"><NavLink tag={Link} to="/sign-up" style={{color: "#000000"}}>Sign Up</NavLink></div>
          <div className="actcss"> You don’t have an account?</div>
        </div>
       
        <div className="customLogin-Auth-form-content">
         
            <h1 className="Auth-form-titleCust" >Login</h1>
            <div className="form-group">
                <input type="email" 
                className="form-control" 
                name="Email" value={Email || ''} 
                onChange={this.handleChange} 
                placeholder="Email"  />
            </div>
            <div className="errorMsg">{e_Email}</div>
            <div className="form-group ">
                <input type="password" 
                className="form-control" 
                name="Password" 
                value={Password || ''} 
                onChange={this.handleChange} 
                placeholder="Password" />
            </div>
            <div className="errorMsg">{e_Password}</div>
            <div className="errorMsg">{this.props.login_Status}</div>
            
           <div className="text-right mb-3" > <p className="forgot_pass">Forgot your Password?</p> </div>
            <div className="d-grid gap-2">
                <div className="button-container" >   <input type="submit"  value="Login" style= {{width: "45%"}} /> </div>
           </div>

           <div className="col-12 mb-4 mt-2">
                <div className="sign-alternate sign-in-alt"></div>
            </div>
           
            <div className="col-6 offset-md-3 mt-5 text-center" >
                <button type="button" className="btn btn-block btn-social btn-SSO"> 
                    <img src="assets/images/key.png"   className="vector-image" alt="" /> 
                    <span className="span_button">SSO</span> 
                </button> 
            </div>

            <div className="mb-5 mt-4" >
              <p className="forgot-password  mb-4 ">
              By using  Mentorz you agree with <span className="textcolor">the terms of service and privacy policy</span> </p>
            </div>
        </div>
        </div>
        </form>
    </div>
    </div>
    );
  }
}




function _props(state) {
    return{
     loading:state.loader.loading,
     login_Status:state.authUser.login_status,
   }
 }
 const _dispatch = {
     getLoginUser:authActions.getLoginUser,
     Loader:loaderActions.toggleLoader,
     setStatus:authActions.setloginStatus
   }

   export default connect(_props,_dispatch)(BusinessLogin)


  
