import React from 'react';
import { useSelector, useDispatch ,connect} from 'react-redux';
import authActions from '../../redux/actions/authActions';

export default function ChooseSubscription() {
  const dispatch = useDispatch();

  const toggle = (stage) => {
     dispatch(authActions.setSignupStage(stage));
  };

  const Mentorz_Essentials=
  [{id:0,title:'1:1 mentorship sessions with free and paid mentors'},
  {id:0,title:'Create public Mentorship Programs (1:few)'},
  {id:0,title:'Customizable profile'},
  {id:0,title:'Schedule recurring mentorship sessions'},
  {id:0,title:'Track notes and tasks from mentorship sessions'}
  ]
  const Mentorz_Business=
  [{id:0,title:'Everything listed in Mentorz Essentials, plus:'},
  {id:0,title:'Create and manage private Mentorship Programs'},
  {id:0,title:'Mentorz Guru - powered by AI (coming soon)'},
  {id:0,title:'Integration with Microsoft Teams (coming soon)'},
  {id:0,title:'Mentorship journey templates (coming soon)'},
  {id:0,title:'Learning and Certification (coming soon)'},
  ]

  return (
  
      <div className="center_div widthsize">
        <div className='row mt-3' style={{margin:'0px 0px 0px 10px'}}>
        <div className='col  col-md-2'>
        <img src="assets/images/svgimges/back_button.svg"  alt="3-dots" onClick={()=>toggle('membership_type')}/>
        </div>
        <div className='col  col-md-10'>
          <p className='choz_meshp_title'>Choose your membership subscription</p>
        </div>
      </div>
      <div className='row mt-5 mb-5' style={{margin:'0px 0px 0px 30px'}}>
        <div className='col choz_meshp col-md-6' style={{marginRight:'10px'}}>
          <p style={{float:'right'}}>
           <img src="assets/images/svgimges/Info.svg"  alt="3-dots" />
          </p>
           <div className='row  justicecenter choz_meshp_title2 mt-3'  >Mentorz Essentials</div>
           <div className='row justicecenter mb-4 mt-4 '>Free</div>
           <div className="button-container "  >  
            <input onClick={()=>toggle('sign_up_form')}  type="submit" value="Sign Up" className="lgnbtn mb-3" style={{width:"150px",height:"50px",borderRadius:"50px"}}/> 
           </div> 
          <ul className='choz-vlist'>
          {Mentorz_Essentials.map(function(item) {
            return <li key={item.id} className='mt-2'>{item.title}</li>;
          })}
         </ul>  
        </div>
        <div className='col choz_meshp col-md-6'>
          <p style={{float:'right'}} >
           <img src="assets/images/svgimges/Info.svg"  alt="3-dots" />
          </p>
                  <div className='row justicecenter choz_meshp_title2 mt-3'>Mentorz Premium</div>
           <div className='row justicecenter mb-4 mt-4'>$9.99/month</div>
           <div className="button-container mb-3 "  >  
            <input type="submit" value="Coming Soon" className="lgnbtn mb-3" style={{width:"150px",height:"50px",borderRadius:"50px"}}/> 
           </div>          
           <ul className='choz-vlist'>
          {Mentorz_Business.map(function(item) {
            return <li key={item.id} className='mt-2'>{item.title}</li>;
          })}
        </ul> 
        </div>
      </div>
    </div>
      
  )
}
