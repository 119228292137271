import React from 'react';

import { Footer } from './Footer';

const PrivacyPolicy = () => {


    return (
        <div className='signup_background'>
        <div className='about row'>
            <div className="offset-lg-3 col-lg-6 col-sm-12">
            </div>
                <div className="grid__Container-sc-1sspd15-0 static__Container-sc-13pvcy2-0 duEkOZ">
                    <h1 style={{ fontSize: 60, color: "rgb(97, 97, 97)" }}>
                        Mentorz Privacy Policy
                    </h1>
                    <p
                        style={{
                            textAlign: "justify",
                            lineHeight: 2,
                            fontSize: 23,
                            color: "rgb(97, 97, 97)"
                        }}
                    >
                        This privacy policy has been compiled to better serve those who are
                        concerned with how their 'Personally Identifiable Information' (PII) is
                        being used online. PII, as described in US privacy law and information
                        security, is information that can be used on its own or with other
                        information to identify, contact, or locate a single person, or to identify
                        an individual in context. Please read our privacy policy carefully to get a
                        clear understanding of how we collect, use, protect or otherwise handle your
                        Personally Identifiable Information in accordance with our website and apps.
                    </p>
                    <h2 style={{ marginTop: 20, marginBottom: 20, color: "rgb(96, 96, 96)" }}>
                        What personal information do we collect from the people that register our
                        website or apps?
                    </h2>
                    <p
                        style={{
                            textAlign: "justify",
                            lineHeight: 2,
                            fontSize: 23,
                            color: "rgb(97, 97, 97)"
                        }}
                    >
                        When registering on our app, as appropriate, you may be asked to enter your
                        name, email address, interests, values, expertise, birthday or other details
                        to help you with your experience.
                    </p>
                    <h2>When do we collect information?</h2>
                    <p
                        style={{
                            textAlign: "justify",
                            lineHeight: 2,
                            fontSize: 23,
                            color: "rgb(97, 97, 97)"
                        }}
                    >
                        We collect information from you when you register, fill out forms or enter
                        information on our website or apps.
                    </p>
                    <h2>How do we use your information? </h2>
                    <p
                        style={{
                            textAlign: "justify",
                            lineHeight: 2,
                            fontSize: 23,
                            color: "rgb(97, 97, 97)"
                        }}
                    >
                        We may use the information we collect from you when you register, accept
                        mentorship requests, make mentorship requests, follow others, mark content
                        as favorite, sign up for our newsletter, respond to a survey or marketing
                        communication, or use certain other website or app features in the following
                        ways:
                    </p>
                    <ul>
                        <li style={{ padding: 10, fontSize: 20 }}>
                            To personalize your experience and to allow us to deliver the type of
                            content and product offerings in which you are most interested.
                        </li>
                        <li style={{ padding: 10, fontSize: 20 }}>
                            To improve our app in order to better serve you.
                        </li>
                        <li style={{ padding: 10, fontSize: 20 }}>
                            To allow us to better service you in responding to your customer service
                            requests.
                        </li>
                        <li style={{ padding: 10, fontSize: 20 }}>
                            To administer a contest, promotion, survey or other app feature.
                        </li>
                        <li style={{ padding: 10, fontSize: 20 }}>
                            To quickly process your transactions.
                        </li>
                        <li style={{ padding: 10, fontSize: 20 }}>
                            To ask for ratings and reviews of services or mentorship calls
                        </li>
                        <li style={{ padding: 10, fontSize: 20 }}>
                            To follow up with them after correspondence (live chat, email or phone
                            inquiries)
                        </li>
                    </ul>
                    <h2>How do we protect your information? </h2>
                    <p
                        style={{
                            textAlign: "justify",
                            lineHeight: 2,
                            fontSize: 23,
                            color: "rgb(97, 97, 97)"
                        }}
                    >
                        We do not use vulnerability scanning and/or scanning to PCI standards. An
                        external PCI compliant payment gateway handles all CC transactions. We use
                        regular Malware Scanning.
                    </p>
                    <p
                        style={{
                            textAlign: "justify",
                            lineHeight: 2,
                            fontSize: 23,
                            color: "rgb(97, 97, 97)"
                        }}
                    >
                        Your personal information is contained behind secured networks and is only
                        accessible by a limited number of persons who have special access rights to
                        such systems, and are required to keep the information confidential. In
                        addition, all sensitive/credit information you supply is encrypted via
                        Secure Socket Layer (SSL) technology. We implement a variety of security
                        measures when a user enters, submits, or accesses their information to
                        maintain the safety of your personal information.{" "}
                    </p>
                    <p
                        style={{
                            textAlign: "justify",
                            lineHeight: 2,
                            fontSize: 23,
                            color: "rgb(97, 97, 97)"
                        }}
                    >
                        All transactions are processed through a gateway provider and are not stored
                        or processed on our servers.
                    </p>
                    <h2>Do we use 'cookies'?</h2>
                    <p
                        style={{
                            textAlign: "justify",
                            lineHeight: 2,
                            fontSize: 23,
                            color: "rgb(97, 97, 97)"
                        }}
                    >
                        We do not use cookies for tracking purposes <br />
                        <br />
                        You can choose to have your computer warn you each time a cookie is being
                        sent, or you can choose to turn off all cookies. You do this through your
                        browser settings. Since browser is a little different, look at your
                        browser's Help Menu to learn the correct way to modify your cookies.
                    </p>
                    <p
                        style={{
                            textAlign: "justify",
                            lineHeight: 2,
                            fontSize: 23,
                            color: "rgb(97, 97, 97)"
                        }}
                    >
                        If you turn cookies off, some of the features that make your app experience
                        more efficient may not function properly. That make your app experience more
                        efficient and may not function properly.
                        <br />
                    </p>
                    <h2>Third-party disclosure</h2>
                    <p
                        style={{
                            textAlign: "justify",
                            lineHeight: 2,
                            fontSize: 23,
                            color: "rgb(97, 97, 97)"
                        }}
                    >
                        We do not sell, trade, or otherwise transfer to outside parties your
                        Personally Identifiable Information (PII) unless we provide users with
                        advance notice. This does not include app hosting partners and other parties
                        who assist us in operating our app, conducting our business, or serving our
                        users, so long as those parties agree to keep this information confidential.
                        We may also release information when it's release is appropriate to comply
                        with the law, enforce our app policies, or protect ours or others' rights,
                        property or safety <br />
                        <br />
                        However, non-personally identifiable visitor information may be provided to
                        other parties for marketing, advertising, or other uses.{" "}
                    </p>
                    <h2>Third-party links</h2>
                    <p
                        style={{
                            textAlign: "center",
                            lineHeight: 2,
                            fontSize: 23,
                            color: "rgb(97, 97, 97)"
                        }}
                    >
                        We do not include or offer third-party products or services on our app.
                    </p>
                    <h2>Google</h2>
                    <p
                        style={{
                            textAlign: "justify",
                            lineHeight: 2,
                            fontSize: 23,
                            color: "rgb(97, 97, 97)"
                        }}
                    >
                        Google's advertising requirements can be summed up by Google's Advertising
                        Principles. They are put in place to provide a positive experience for
                        users.{" "}
                        <a href="https://support.google.com/adwordspolicy/answer/1316548?hl=en">
                            https://support.google.com/adwordspolicy/answer/1316548?hl=en
                        </a>
                        <br />
                        We use Google AdSense Advertising on our app.
                        <br />
                        <br />
                        Google, as a third-party vendor, uses cookies to serve ads on our app.
                        Google's use of the DART cookie enables it to serve ads to our users based
                        on previous visits to our app and other apps on the Internet. Users may
                        opt-out of the use of the DART cookie by visiting the Google Ad and Content
                        Network privacy policy.
                        <br />
                        <br />
                    </p>
                    <h2>We have implemented the following:</h2>
                    <p
                        style={{
                            textAlign: "justify",
                            lineHeight: 2,
                            fontSize: 23,
                            color: "rgb(97, 97, 97)"
                        }}
                    >
                        We, along with third-party vendors such as Google use first-party cookies
                        (such as the Google Analytics cookies) and third-party cookies (such as the
                        DoubleClick cookie) or other third-party identifiers together to compile
                        data regarding user interactions with ad impressions and other ad service
                        functions as they relate to our app. <br />
                        <br />
                    </p>
                    <h2>Opting out:</h2>
                    <br />
                    <p
                        style={{
                            textAlign: "justify",
                            lineHeight: 2,
                            fontSize: 23,
                            color: "rgb(97, 97, 97)"
                        }}
                    >
                        Users can set preferences for how Google advertises to you using the Google
                        Ad Settings page. Alternatively, you can opt out by visiting the Network
                        Advertising Initiative Opt Out page or by using the Google Analytics Opt Out
                        Browser add on.
                    </p>
                    <h2>California Online Privacy Protection Act</h2>
                    <p
                        style={{
                            textAlign: "justify",
                            lineHeight: 2,
                            fontSize: 23,
                            color: "rgb(97, 97, 97)"
                        }}
                    >
                        CalOPPA is the first state law in the nation to require commercial apps and
                        online services to post a privacy policy. The law's reach stretches well
                        beyond California to require any person or company in the United States (and
                        conceivably the world) that operates apps collecting Personally Identifiable
                        Information from California consumers to post a conspicuous privacy policy
                        on its app stating exactly the information being collected and those
                        individuals or companies with whom it is being shared. - See more at:{" "}
                        <u>
                            <a href="http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf">
                                http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
                            </a>
                        </u>
                    </p>
                    <h2>According to CalOPPA, we agree to the following:</h2>
                    <p
                        style={{
                            textAlign: "justify",
                            lineHeight: 2,
                            fontSize: 23,
                            color: "rgb(97, 97, 97)"
                        }}
                    >
                        Users can visit our app anonymously.
                        <br />
                        Once this privacy policy is created, we will add a link to it on our home
                        page or as a minimum, on the first significant page after entering our
                        app.Our Privacy Policy link includes the word 'Privacy' and can easily be
                        found on the page specified above.
                    </p>
                    <p
                        style={{
                            textAlign: "justify",
                            lineHeight: 2,
                            fontSize: 23,
                            color: "rgb(97, 97, 97)"
                        }}
                    >
                        You will be notified of any Privacy Policy changes:
                        <br />
                    </p>
                    <ul>
                        <li style={{ padding: 10, fontSize: 20 }}>On our Privacy Policy Page</li>
                    </ul>
                    <p
                        style={{
                            textAlign: "justify",
                            lineHeight: 2,
                            fontSize: 23,
                            color: "rgb(97, 97, 97)"
                        }}
                    >
                        You can change your personal information:
                        <br />
                    </p>
                    <ul>
                        <li style={{ padding: 10, fontSize: 20 }}>By logging in to your account</li>
                        <li style={{ padding: 10, fontSize: 20 }}>Others</li>
                    </ul>
                    <h2>How does our app handle Do Not Track signals?</h2>
                    <p
                        style={{
                            textAlign: "justify",
                            lineHeight: 2,
                            fontSize: 23,
                            color: "rgb(97, 97, 97)"
                        }}
                    >
                        We honor Do Not Track signals and Do Not Track, plant cookies, or use
                        advertising when a Do Not Track (DNT) browser mechanism is in place.
                    </p>
                    <h2>Does our app allow third-party behavioral tracking?</h2>
                    <p
                        style={{
                            textAlign: "justify",
                            lineHeight: 2,
                            fontSize: 23,
                            color: "rgb(97, 97, 97)"
                        }}
                    >
                        It's also important to note that we do not allow third-party behavioral
                        tracking.
                    </p>
                    <h2>COPPA (Children Online Privacy Protection Act)</h2>
                    <p
                        style={{
                            textAlign: "justify",
                            lineHeight: 2,
                            fontSize: 23,
                            color: "rgb(97, 97, 97)"
                        }}
                    >
                        When it comes to the collection of personal information from children under
                        the age of 13 years old, the Children's Online Privacy Protection Act
                        (COPPA) puts parents in control. The Federal Trade Commission, United
                        States' consumer protection agency, enforces the COPPA Rule, which spells
                        out what operators of apps and online services must do to protect children's
                        privacy and safety online.
                    </p>
                    <p
                        style={{
                            textAlign: "justify",
                            lineHeight: 2,
                            fontSize: 23,
                            color: "rgb(97, 97, 97)"
                        }}
                    >
                        We do not specifically market to children under the age of 13 years old.
                    </p>
                    <h2>Fair Information Practices</h2>
                    <p
                        style={{
                            textAlign: "justify",
                            lineHeight: 2,
                            fontSize: 23,
                            color: "rgb(97, 97, 97)"
                        }}
                    >
                        The Fair Information Practices Principles form the backbone of privacy law
                        in the United States and the concepts they include have played a significant
                        role in the development of data protection laws around the globe.
                        Understanding the Fair Information Practice Principles and how they should
                        be implemented is critical to comply with the various privacy laws that
                        protect personal information.
                    </p>
                    <h2>
                        In order to be in line with Fair Information Practices we will take the
                        following responsive action, should a data breach occur:
                    </h2>
                    <p
                        style={{
                            textAlign: "center",
                            lineHeight: 2,
                            fontSize: 23,
                            color: "rgb(97, 97, 97)"
                        }}
                    >
                        We will notify you via email or through our website or apps within 7
                        business days.
                    </p>
                    <p
                        style={{
                            textAlign: "justify",
                            lineHeight: 2,
                            fontSize: 23,
                            color: "rgb(97, 97, 97)"
                        }}
                    >
                        We also agree to the Individual Redress Principle which requires that
                        individuals have the right to legally pursue enforceable rights against data
                        collectors and processors who fail to adhere to the law. This principle
                        requires not only that individuals have enforceable rights against data
                        users, but also that individuals have recourse to courts or government
                        agencies to investigate and/or prosecute non-compliance by data processors.
                    </p>
                    <h2>CAN SPAM Act</h2>
                    <p
                        style={{
                            textAlign: "justify",
                            lineHeight: 2,
                            fontSize: 23,
                            color: "rgb(97, 97, 97)"
                        }}
                    >
                        The CAN-SPAM Act is a law that sets the rules for commercial email,
                        establishes requirements for commercial messages, gives recipients the right
                        to have emails stopped from being sent to them, and spells out tough
                        penalties for violations.
                    </p>
                    <h2>We collect your email address in order to:</h2>
                    <ul>
                        <li style={{ padding: 10, fontSize: 20 }}>
                            Send information, respond to inquiries, and/or other requests or questions
                        </li>
                    </ul>
                    <h2>To be in accordance with CANSPAM, we agree to the following:</h2>
                    <ul>
                        <li style={{ padding: 10, fontSize: 20 }}>
                            Not use false or misleading subjects or email addresses.
                        </li>
                        <li style={{ padding: 10, fontSize: 20 }}>
                            Identify the message as an advertisement in some reasonable way.
                        </li>
                        <li style={{ padding: 10, fontSize: 20 }}>
                            Include the physical address of our business or app headquarters.
                        </li>
                        <li style={{ padding: 10, fontSize: 20 }}>
                            Monitor third-party email marketing services for compliance, if one is
                            used.
                        </li>
                        <li style={{ padding: 10, fontSize: 20 }}>
                            Honor opt-out/unsubscribe requests quickly.
                        </li>
                        <li style={{ padding: 10, fontSize: 20 }}>
                            Allow users to unsubscribe by using the link at the bottom of each email.
                        </li>
                    </ul>
                    <h2>
                        If at any time you would like to unsubscribe from receiving future emails,
                        you can email us at
                    </h2>
                    <p>
                        support@mentorz.com and we will promptly remove you from{" "}
                        <strong>ALL</strong> correspondence.
                    </p>
                    <h2>Contacting Us</h2>
                    <p
                        style={{
                            textAlign: "justify",
                            lineHeight: 2,
                            fontSize: 20,
                            color: "rgb(97, 97, 97)"
                        }}
                    >
                        If there are any questions regarding this privacy policy, you may contact us
                        using the information below.
                        <br />
                        Mentorz Inc
                        <br />
                        P.O. Box 2451
                        <br />
                        Redmond, WA 98073
                        <br />
                        USA
                        <br />
                        support@mentorz.com
                        <br />
                        Last Edited on March 3rd 2018.
                    </p>
                </div>

            </div>
            <Footer />
            </div>
    );
}

export default PrivacyPolicy;
