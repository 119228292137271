
import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom'
import { BusinessSignIn } from "../container/signin/business_signin";
import CustomerSignIn from "../container/signin/customer_signin";
import ChooseSubscription from "../container/signin/choose_subscription";
import PrivacyPolicy from "../components/privacypolicy";
import BusinessSignupForm from "../container/signin/business_signup_form";
import BusinesSignupDetails from "../container/signin/business_signup_details";
import BusinesSignupReview from "../container/signin/business_signup_review";
import authActions from "../redux/actions/authActions";
import SignupCompleted from "../container/signin/signup_completed";
import BuisinesSignupQR from "../container/signin/buisiness_signup_QR";
class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
           signup_data:''
        }
    }
    componentDidMount(){
        this.props.setSignupStage('membership_type');
    }
    
    
    toggle = () => {
        this.props.setSignupStage('choose_subscription');  
    };

    togglepolicy = () => {
        this.props.setSignupStage('privacy_Policy');
    };

      eventcl= (data) => {
        this.setState({signup_data:data})
      };


      renderSwitch(param) {
        switch(param) {
        case 'choose_subscription':
            ///return <BuisinesSignupQR/>;   /// remove when QR code removed
            return <ChooseSubscription/>;
        case 'sign_up_form':
            return <BusinessSignupForm eventcl={this.eventcl} formData={this.state.signup_data}/>;
            case 'business_signup_detail':
                return <BusinesSignupDetails />;
            case 'privacy_Policy':
                return <PrivacyPolicy />;
        case 'review_signup':
                return <BusinesSignupReview formData={this.state.signup_data} />;
        case 'signup_completed':
           return <SignupCompleted formData={this.state.signup_data}/>
          default:
            return '';
        }
      }


 
render() {
return (
    <>
        <div className="section-sign">
        <>
        {
            this.props.signup_stage!=='review_signup' && 
            this.props.signup_stage!=='signup_completed' &&
            this.props.signup_stage!=='choose_subscription' &&

            <div className="loginbtn">
                            <div className="custsignStyle">
                                <NavLink className="normaltag" tag={Link} to="/login" style={{color: "#000000"}}>Login</NavLink>
                </div>
                <div className="actcss"> Already have an account?</div>
           </div>

        }
       
        <div className=" mt-5">
            
            {
            this.props.signup_stage==='membership_type' &&
                   <div className="center_div" style={{ width: '658px' }} >
           <div className="row shadw" >
            <div className="col-12 mt-3">
            </div>
              <div className="col-12">
                <div className="sign-title mt-4">I am a:</div>
                <div className="sign-subtitle mt-4 mb-4">Select your membership type.</div>
              </div>
              <div className="row pt-3 justicecenter" >
                                <div className="col-md-5 col-6 half" onClick={this.toggle}>
                            <div className="sign-tile" >
                                <img src="assets/images/individual.png" alt="individual"/>
                                <div>Individual</div>
                            </div>
                    </div>
                                        <div className="col-md-5 col-6 half" >
                                            {/* to="https://mentorzadmin.azurewebsites.net/" target={"_blank"}*/}
                                          {/*  <Link> */}
                            <div className="sign-tile">
                                <img src="assets/images/business.png" alt="business"/>
                                <div>Business</div>
                            </div>
                       {/* </Link>*/}
                    </div>
                <div className=" col-12 forgot-password mt-4 mb-5 text-center" >
                  <p> 
                                                <span className="text_color" style={{ letterSpacing: "1.8px" }}>By using  Mentorz you agree with the

                                                    <span className="textcolor" style={{ cursor: "pointer" }} onClick={this.togglepolicy}>   terms of service and privacy policy</span>  </span> 
                    </p>
                </div>  
                </div>
             </div>
            </div>
            }


            {this.renderSwitch(this.props.signup_stage)}
            
            {/* {
            this.props.signup_stage==='choose_subscription' && 
            <ChooseSubscription/>
            }
            {
                this.props.signup_stage==='sign_up_form' && 
                <BusinessSignupForm eventcl={this.eventcl} formData={this.state.signup_data}/>
            }
            {
                this.props.signup_stage==='business_signup_detail' && 
                <BusinesSignupDetails/>
            }
           {
                this.props.signup_stage==='review_signup' && 
                <BusinesSignupReview formData={this.state.signup_data}/>
            }
            {
                this.props.signup_stage==='signup_completed' && 
                <SignupCompleted formData={this.state.signup_data}/>
            } */}
        </div>
        </>
        </div>
    </>
);
    }
}

function _props(state) {
    return{
        signup_stage:state.authUser.signup_stage,
        loading:state.loader.loading,
   }
 }
const _dispatch = (dispatch) => ({
setSignupStage: (payload) => dispatch(authActions.setSignupStage(payload)),
})
export default connect(_props,_dispatch)(SignUp)