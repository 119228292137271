import React from 'react';
import { Link,useLocation } from 'react-router-dom';


  const PublicHeader = () => {
    const location = useLocation();
    const {pathname }=location;
    const authUser=JSON.parse(localStorage.getItem("LogInStatus"));
    const hello=()=>
    {
      localStorage.clear(); window.location.href = '/login';
    }

    
    return (
      <div>
            <nav className={` ${pathname === '/LandingPage' ? 'landingnavbar ' : 'navbar bg-body-tertiary'} navbar-expand-lg `}>
                <Link to="LandingPage" className="nav-link-alt" style={{ width: "100%", textAlign :"start"}}>
        <div>
                        {pathname === '/LandingPage' && (
                            <><img style={{ marginLeft: "20px",  }} src="assets/images/Mentorz_logo_red.png" alt="Logo" /><img src="assets/images/Mentorz_word_red.png" alt="Logo" /></>
                           
          )}
       </div>
        <div>
            {pathname != '/LandingPage' && (
              <img src="assets/images/logo.png" className="navlogo" alt="Logo" />
            )}
         </div>
        </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          
          {
            pathname==="/LandingPage"  &&
            <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
            <form className="d-flex mr-5 links" role="search">
             <Link to= "/about" className='linkbtn'>About</Link>
             <Link to= "/contact-us" className='linkbtn'>Contact</Link>
           {/* <Link to = "" className='linkbtn'>Blog</Link>  */}          
                                <Link to="/sign-up" className={`btn btn-lg nav-btn mr-1 freebtn ${pathname === "/sign-up" ? "cccccc" : ""} `} >   Try for free </Link>
                                <Link to="/login" className={`linkbtn   ${pathname ==="/login" ? "cccccc" : ""}`} > Login </Link>
            </form>
          </div>
        
          }
         
      </nav>
      
     </div>
    );
}
export default PublicHeader;
