import { combineReducers } from 'redux';

import loader from './loader';
import modal from './modal';

import authUser from './authReducer';
import proFileReducers from './proFileReducer';
import matchReducer from './matchReducer';
import homeReducer from './homeReducer';
import journalReducer from './journalReducer';
import scheduleReducer from './scheduleReducer';
const rootReducer = combineReducers({
    loader:loader,
    modal:modal,
    authUser:authUser,
    proFileReducers:proFileReducers,
    matchReducer:matchReducer,
    homeReducer:homeReducer,
    journalReducer:journalReducer,
    scheduleReducer:scheduleReducer
});

export default rootReducer;