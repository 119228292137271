import React from 'react'
import { Link } from "react-router-dom";

const PagenotFound=()=> {
  return (
    <article className='text-alignecss'>
            <img src="img/PagenotFound.jpg" alt="play store" />
        </article>
  )
}
export default PagenotFound;
