import React, { Component } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export class Layer3 extends Component {
    constructor(props) {        
        super(props);
        this.state={  
            currentSlide: 0,          
            TMentorz:[
                {
                    id:1,                   
                    mentorheading:'Mentorship: The Secret Ingredient to Business Success',  
                    mentordesc:'If you ask any successful business person, they will always have had a great mentor at some point along the road',
                    mentorimg:'assets/images/imagementor.png',
                    heading1:'Richard Branson',
                    headingdesc:'Founder of the Virgin Group',
                    image:'assets/images/image 40.png',                    
                },
                {
                    id:2,
                    mentorheading:'Unlocking employee potential',  
                    mentordesc:'Forward-thinking organizations need to create environments that embrace and unlock the potential of the whole employee.',
                    mentorimg:'assets/images/image 30.png',
                    heading1:'Linda Jingfang Cai',
                    headingdesc:'Global Head of Learning and Talent Development, Linkedin',
                    image:'assets/images/image 34.png',
                },
                {
                    id:3,
                    mentorheading:'The Transformative Power of Mentoring',  
                    mentordesc:`If I hadn't had mentors, I wouldn't be here today. I'm a product of great mentoring, greatcoaching... Coaches or mentors are very important. They could be anyone - your husband, other family members, or your boss.`,
                    mentorimg:'assets/images/indra.png',
                    heading1:'Indra Nooyi',
                    headingdesc:'Former CEO of pepsiCo',
                    image:'assets/images/pepsico.png',
                },
                {
                    id:4,
                    mentorheading:'Resilience through learning and agility',  
                    mentordesc:'Learning is key to your resilience ad a business.If you invest in curiosity,learning and agility for your workforce, you will be able to bounce back from setback, adapt to change, and be more ready for whatever comes next.',
                    mentorimg:'assets/images/image 31.png',
                    heading1:'Cat Ward',
                    headingdesc:'Vice President, Jobs for the Future',
                    image:'assets/images/image 36.png',
                },
                {
                    id:5,
                    mentorheading:`Don't move backwards,`,  
                    mentordesc:`If employees aren't learning, they aren't refining their skills or innovating.They and the organization are effectively moving backwards.`,
                    mentorimg:'assets/images/image 32.png',
                    heading1:'Andrew Saidy',
                    headingdesc:'Vice President of Global Talent, Ubisoft',
                    image:'assets/images/image 37.png',
                },
                {
                    id:6,
                    mentorheading:'"Mentorship: The Secret Ingredient to Business Success',  
                    mentordesc:'If you ask any successful business person, they will always have had a great mentor at some point along the road',
                    mentorimg:'assets/images/imagementor.png',
                    heading1:'Richard Branson',
                    headingdesc:'Founder of the Virgin Group',
                    image:'assets/images/image 40.png',
                },
                {
                    id:7,
                    mentorheading:'"Mentorship: The Secret Ingredient to Business Success',  
                    mentordesc:'If you ask any successful business person, they will always have had a great mentor at some point along the road',
                    mentorimg:'assets/images/imagementor.png',
                    heading1:'Richard Branson',
                    headingdesc:'Founder of the Virgin Group',
                    image:'assets/images/image 40.png',
                },
                //{
                //    id:8,
                //    mentorheading:'"Mentorship: The Secret Ingredient to Business Success',  
                //    mentordesc:'If you ask any successful business person, they will always have had a great mentor at some point along the road',
                //    mentorimg:'assets/images/imagementor.png',
                //    heading1:'Richard Branson',
                //    headingdesc:'Founder of the Virgin Group',
                //    image:'assets/images/image 40.png',
                //},
                // {
                //     id:5,
                //     image:'img/match.png',
                //     name:'Jason Roy',
                //     title:'5 yrs exp',
                //     description:'Lorem ipsum is simply  dummy text of the printing.'
                // },
                // {
                //     id:6,
                //     image:'img/match.png',
                //     name:'Jason Roy',
                //     title:'5 yrs exp',
                //     description:'Lorem ipsum is simply  dummy text of the printing.'
                // },
                // {
                //     id:7,
                //     image:'img/match.png',
                //     name:'Jason Roy',
                //     title:'5 yrs exp',
                //     description:'Lorem ipsum is simply  dummy text of the printing.'
                // },
                // {
                //     id:8,
                //     image:'img/match.png',
                //     name:'Jason Roy',
                //     title:'5 yrs exp',
                //     description:'Lorem ipsum is simply  dummy text of the printing.'
                // },
            ],
            
             settings:{
                dots: true,
                 infinite: false,
                speed: 500,                              
                className:"innerslide",
                centerMode: true,
                centerPadding: '0',
                focusOnSelect: true,
                beforeChange: this.beforeSlideChange,
                prevArrow: <></>


              },
              windowWidth: window.innerWidth

        }       
        
    }
    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({
            windowWidth: window.innerWidth
        });
    }
    beforeSlideChange = (current, next) => {
        this.setState({ currentSlide: next });
      };
    render() {
        const { TMentorz, settings } = this.state;

        // Adjust slidesToShow based on screen size
        const responsiveSettings = {
          ...settings,
            slidesToShow: window.innerWidth < 768 ? 1 : 3,
            
        };
        return (
         
          <div className="container layer2bg">
            <div className="row">
              
                <div className="title">Embracing Success :<span className='mentorzcolor'> The Power of Mentorship</span> </div>
                <section id="demos" >
                  <div className="row">
                  <Slider {...responsiveSettings} beforeChange={this.beforeSlideChange}>
                                {TMentorz.map((result, index) => (
                                    <div className="item" key={result.id}>
                                        <div className="mentorsbox">
                            <div className='quotes'><svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.7586 0C11.8975 0.052835 10.0651 0.472162 8.36628 1.23398C6.66745 1.9958 5.13552 3.08516 3.85818 4.43971C2.58084 5.79426 1.58316 7.38741 0.922243 9.12797C0.261321 10.8685 -0.0498666 12.7224 0.00648877 14.5833V16.3917C0.00648877 17.8165 0.429006 19.2094 1.22061 20.3941C2.01221 21.5788 3.13735 22.5022 4.45374 23.0475C5.77013 23.5927 7.21865 23.7354 8.61612 23.4574C10.0136 23.1794 11.2973 22.4933 12.3048 21.4858C13.3123 20.4783 13.9984 19.1946 14.2764 17.7971C14.5544 16.3997 14.4117 14.9511 13.8664 13.6348C13.3212 12.3184 12.3978 11.1932 11.2131 10.4016C10.0284 9.61002 8.63551 9.1875 7.21066 9.1875C6.66468 9.18796 6.1209 9.25654 5.59191 9.39167C5.52287 9.41131 5.44947 9.40925 5.38165 9.38577C5.31383 9.3623 5.25486 9.31853 5.21274 9.26042C5.17378 9.19945 5.15308 9.12861 5.15308 9.05625C5.15308 8.9839 5.17378 8.91306 5.21274 8.85209C6.05123 7.30749 7.28348 6.01225 8.7844 5.09787C10.2853 4.18349 12.0015 3.68252 13.7586 3.64583C14.242 3.64583 14.7057 3.45378 15.0476 3.11191C15.3894 2.77005 15.5815 2.30638 15.5815 1.82292C15.5815 1.33945 15.3894 0.875783 15.0476 0.53392C14.7057 0.192056 14.242 0 13.7586 0Z" fill="#F3CD47"/>
                            <path d="M31.7254 3.64591C32.2088 3.64591 32.6725 3.45385 33.0144 3.11199C33.3562 2.77013 33.5483 2.30646 33.5483 1.82299C33.5483 1.33953 33.3562 0.875859 33.0144 0.533996C32.6725 0.192133 32.2088 7.62939e-05 31.7254 7.62939e-05C29.8643 0.0529113 28.0319 0.472238 26.3331 1.23406C24.6342 1.99588 23.1023 3.08523 21.825 4.43978C20.5476 5.79433 19.55 7.38748 18.889 9.12805C18.2281 10.8686 17.9169 12.7224 17.9733 14.5834V16.3917C17.9704 17.8207 18.3917 19.2184 19.1838 20.4077C19.9759 21.5971 21.1031 22.5246 22.4228 23.0728C23.7424 23.621 25.1951 23.7651 26.5968 23.4871C27.9984 23.209 29.286 22.5212 30.2965 21.5107C31.3069 20.5003 31.9947 19.2127 32.2728 17.811C32.5509 16.4094 32.4067 14.9567 31.8585 13.6371C31.3103 12.3174 30.3828 11.1902 29.1935 10.3981C28.0041 9.60598 26.6064 9.18469 25.1775 9.18758C24.6315 9.18893 24.0879 9.2575 23.5587 9.39174C23.4897 9.41138 23.4163 9.40932 23.3484 9.38585C23.2806 9.36237 23.2217 9.31861 23.1795 9.26049C23.1406 9.19952 23.1199 9.12868 23.1199 9.05633C23.1199 8.98397 23.1406 8.91313 23.1795 8.85216C24.018 7.30756 25.2503 6.01233 26.7512 5.09794C28.2521 4.18356 29.9682 3.6826 31.7254 3.64591Z" fill="#F3CD47"/>
                            </svg>
                            </div>
                            <div className='mentorheading'>
                            {result.mentorheading}
                            </div>
                            <div className='mentordescrption m-btm-50'>{result.mentordesc}  
                            </div>
                            <div className='row '>
                                <div className='col-lg-8'>
                                    <div className='row'>
                                                        <div className='col-lg-4'>
                                                            <img style={{ borderRadius:"25px" }} className="" src={result.mentorimg} alt="mentor_1" />
                                        </div>
                                            <div className='col-lg-8'>
                                                            <p className='mentorname'>{result.heading1}</p>{/* {window.innerWidth}*/}
                                                <p className='mentordesc'>{result.headingdesc}</p>
                                            </div>                                           
                                        </div>
                                </div> 
                                <div className='col-lg-4'>
                                <img className="" src={result.image} alt="mentor_1"/>                                   
                                </div>
                            </div>                                                    
                          </div>
                          <div className='rectanglebox'><svg width="70" height="53" viewBox="0 0 70 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 0.630005H70V45.9916C70 54.9697 59.0858 59.3994 52.8286 52.9609L35.5 35.13L0 0.630005Z" fill="#FF4339" fill-opacity="0.15"/>
                            </svg></div>
                                    </div>
                                ))}
                            </Slider>
                    
                        {/* <div className="slider-counter">
                            {Array.from({ length: 3 }, (_, i) => (
                            <span key={i} className={i === this.currentSlide ? 'active' : ''}></span>
                            ))}
                         </div> */}
                    </div>
                </section>
              </div>
            </div>
         
       
            
        );
    }
}


