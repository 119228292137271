
 const type = {
   //Loader constants
    TOGGLE_LOADER:"TOGGLE_LOADER",
    TOGGLE_MODAL:"TOGGLE_MODAL",
    AVILABILITY_TOGGLE:"AVILABILITY_TOGGLE",

    SET_PROFILEJOURNY:'SET_PROFILEJOURNY',

    //IMAGE
    GET_IMAGEURL:'GET_IMAGEURL',
   
 
    // HOME 
    GET_MENTORDETAIL:'GET_MENTORDETAIL',
    SET_MENTORDETAIL:'SET_MENTORDETAIL',
    SET_MENTORDETAIL_PHOTO:'SET_MENTORDETAIL_PHOTO',
    

    GET_USERMENTORDETAIL:'GET_USERMENTORDETAIL',
    SET_USERMENTORDETAIL:'SET_USERMENTORDETAIL',
    UPDATE_USERMENTORDETAIL:'UPDATE_USERMENTORDETAIL',
    PUSBLISH_POST:'PUSBLISH_POST',
    LIKE_POST:'LIKE_POST',
    ADD_COMMENTES:'ADD_COMMENTES',
    GET_COMMENTE_LIST:'GET_COMMENTE_LIST',
    SET_COMMENTE_LIST:'SET_COMMENTE_LIST',

    ////////

    // PROFILE TYPES 
     GET_USER_PROFILE_JOURNY_LIST: 'GET_USER_PROFILE_JOURNY_LIST',
     SET_USER_PROFILE_JOURNY_LIST: 'SET_USER_PROFILE_JOURNY_LIST',
    UPDATE_USER_PROFILE_JOURNY_LIST:'UPDATE_USER_PROFILE_JOURNY_LIST',
    GET_USER_PROFILE_DETAIL:'GET_USER_PROFILE_DETAIL',
    SET_USER_PROFILE_DETAIL:'SET_USER_PROFILE_DETAIL',
    TOGGLE_PROFILEJOURNEY_STEP:"TOGGLE_PROFILEJOURNEY_STEP",
    TOGGLE_PROFILEPAYMENT_STEP:"TOGGLE_PROFILEPAYMENT_STEP",
    SET_PROFILEPMENTOR_RATE:"SET_PROFILEPMENTOR_RATE",
    SETUP_PROFILEDETAIL:'SETUP_PROFILEDETAIL',
    GET_PROFILEINTEREST:'GET_PROFILEINTEREST',
    SET_PROFILEINTEREST:'SET_PROFILEINTEREST',
    GET_PAYMENT_TYPE:'GET_PAYMENT_TYPE',
    SET_PAYMENT_TYPE:'SET_PAYMENT_TYPE',
    ADD_PAYMENT_METHOD:'ADD_PAYMENT_METHOD',
    
    GET_PROFILETRENDINGINTEREST:'GET_PROFILETRENDINGINTEREST',
    SET_PROFILETRENDINGINTEREST:'SET_PROFILETRENDINGINTEREST',

    GET_PROFILEXPERTIES:'GET_PROFILEXPERTIES',
    SET_PROFILEXPERTIES:'SET_PROFILEXPERTIES',

    GET_PROFILETRENDINGEXPERTIES:'GET_PROFILETRENDINGEXPERTIES',
     SET_PROFILETRENDINGEXPERTIES: 'SET_PROFILETRENDINGEXPERTIES',

     GET_PROFILETRENDINGVALUES:'GET_PROFILETRENDINGVALUES',
     SET_PROFILETRENDINGVALUES:'SET_PROFILETRENDINGVALUES',

    GET_PROFILEVALUES:'GET_PROFILEVALUES',
    SET_PROFILEVALUES:'SET_PROFILEVALUES',

    UPDATE_USER_PROFILE_DETAIL:'UPDATE_USER_PROFILE_DETAIL',

     GET_SELFDISCOVER_SURVEY:'GET_SELFDISCOVER_SURVEY',
     SET_SELFDISCOVER_SURVEY: 'SET_SELFDISCOVER_SURVEY',
     UPDATE_SELFDISCOVER_SURVEY: 'UPDATE_SELFDISCOVER_SURVEY',

    CREATE_ACCOUNT_LINK:'CREATE_ACCOUNT_LINK',


    //MATCH TYPES
    SET_MATCH_TAB:'SET_MATCH_TAB',
    GET_RECOMMENDED_MENTORS:'GET_RECOMMENDED_MENTORS',
    SET_RECOMMENDED_MENTORS:'SET_RECOMMENDED_MENTORS',
    GET_ALL_MENTORS:'GET_ALL_MENTORS',
    SET_ALL_MENTORS:'SET_ALL_MENTORS',
    GET_MANAGE_REQUEST:'GET_MANAGE_REQUEST',
    SET_MANAGE_REQUEST:'SET_MANAGE_REQUEST',
    GET_IMAGE_URL:'GET_IMAGE_URL',

    GET_MENTOD_DETAIL:'GET_MENTOD_DETAIL',
    SET_MENTOD_DETAIL:'SET_MENTOD_DETAIL',
    CREATE_MENTOR_REQUEST:'CREATE_MENTOR_REQUEST',
    SET_MENTOR_REQUEST_STATUS:'SET_MENTOR_REQUEST_STATUS',
    UPDATE_MENTOR_REQUEST:'UPDATE_MENTOR_REQUEST',
    SET_UPDATE_MENTOR_REQUEST:'SET_UPDATE_MENTOR_REQUEST',

    // JOURNAL
    SET_JOURNAL_TAB:'SET_JOURNAL_TAB',
    SET_DETAIL_PARAM:'SET_DETAIL_PARAM',

    GET_MENTOR_LIST:'GET_MENTOR_LIST',
    SET_MENTOR_LIST:'SET_MENTOR_LIST',
    GET_MENTOR_DETAIL:'GET_MENTOR_DETAIL',
    SET_MENTOR_DETAIL:'SET_MENTOR_DETAIL',


    GET_MENTEE_LIST:'GET_MENTEE_LIST',
    SET_MENTEE_LIST:'SET_MENTEE_LIST',
    GET_MENTEE_DETAIL:'GET_MENTEE_DETAIL',
    SET_MENTEE_DETAIL:'SET_MENTEE_DETAIL',
    GET_AVAILABILITY:'GET_AVAILABILITY',
    SET_AVAILABILITY:'SET_AVAILABILITY',
    UPDATE_MENTORE_GOAL:'UPDATE_MENTORE_GOAL',
    BOOK_MENTOR_SESSION:'BOOK_MENTOR_SESSION',
    ACCEPT_MENTOR_SESSION:'ACCEPT_MENTOR_SESSION',
    REJECT_MENTOR_SESSION:'REJECT_MENTOR_SESSION',
    RESCHEDULE_MENTOR_SESSION:'RESCHEDULE_MENTOR_SESSION',
    SET_BOOK_MENTOR_SESSION:'SET_BOOK_MENTOR_SESSION',
    GET_SUBSCRIB_CHANNEL_ID:'GET_SUBSCRIB_CHANNEL_ID',
    SET_SUBSCRIB_CHANNEL_ID:'SET_SUBSCRIB_CHANNEL_ID',
    MESSAGE_LIST:'MESSAGE_LIST',
    UPDATE_MESSAGE_LIST:'UPDATE_MESSAGE_LIST',


    //Schedule Types
    SET_SCHEDULE_TAB:'SET_SCHEDULE_TAB',
    GET_SCHEDULE_LIST:'GET_SCHEDULE_LIST',
    SET_SCHEDULE_LIST:'SET_SCHEDULE_LIST',
    UPDATE_AVAILABILITY:'UPDATE_AVAILABILITY',
    UPDATE_AVAILABILITY_RESULT:'UPDATE_AVAILABILITY_RESULT',



    //Alert Constants
    ALERT_SUCCESS: 'ALERT_SUCCESS',
    ALERT_ERROR: 'ALERT_ERROR',
    ALERT_CLEAR: 'ALERT_CLEAR',
    LOGOUT_CONFIRM:'LOGOUT_CONFIRM',

    GET_USERS_REQUESTED : 'GET_USERS_REQUESTED',
    GET_USERS_SUCCESS : 'GET_USERS_SUCCESS',
    GET_USERS_FAILED : 'GET_USERS_FAILED',
    
    GET_SINGLEUSERS_REQ : 'GET_SINGLEUSERS_REQ',
    GET_USER_SUCCESS : 'GET_USER_SUCCESS',

    ADD_TODO : 'ADD_TODO',
    DELETE_TODO : 'DELETE_TODO',
    RENAME_TODO : "RENAME_TODO",
    REMOVEALL_TODO : "REMOVEALL_TODO",


    ////USER 
    GET_SIGNINUSER:"GET_SIGNINUSER",
    UPDATE_SIGNINUSER:"UPDATE_SIGNINUSER",
    GET_SIGNINUSER_SUCCESS:"GET_SIGNINUSER_SUCCESS",
    SET_SIGNUP_STAGE:"SET_SIGNUP_STAGE",

    //Login Constants
    GET_CUSTOMER_LOGINUSER:"GET_CUSTOMER_LOGINUSER",
    VERIFY_EMAIL:'VERIFY_EMAIL',
    VALIDATE_EMAIL:'VALIDATE_EMAIL',
    SET_VALIDATE_EMAIL_RESPO:'SET_VALIDATE_EMAIL_RESPO',
    SET_LOGINUSER_SATUS:"SET_LOGINUSER_SATUS",
    GET_LOGIUSER_SUCCESS:"GET_LOGIUSER_SUCCESS",
    UPLOAD_USER_IMAGE:'UPLOAD_USER_IMAGE',
    GET_STRIPECUSTOMER_ID:'GET_STRIPECUSTOMER_ID',
    SET_STRIPECUSTOMER_ID:'SET_STRIPECUSTOMER_ID',
    
   /////File Upload
   UPLOAD_FILE:'UPLOAD_FILE',
   SET_UPLOAD_FILE:'SET_UPLOAD_FILE'
};
export default type;
